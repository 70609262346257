import { RangeValue } from "@ionic/core";
import {
	IonButton,
	IonButtons,
	IonCol,
	IonFab,
	IonFabButton,
	IonGrid,
	IonHeader,
	IonIcon,
	IonImg,
	IonInput,
	IonLabel,
	IonList,
	IonLoading,
	IonMenuToggle,
	IonModal,
	IonPage,
	IonPopover,
	IonRow,
	IonTextarea,
	IonTitle,
	IonToolbar,
	isPlatform,
	setupIonicReact,
} from "@ionic/react";
import { collection, deleteDoc, doc, getDoc, getDocs, query, setDoc, updateDoc, where } from "firebase/firestore";
import { deleteObject, getDownloadURL, ref as storageRef, uploadBytes } from "firebase/storage";
import { toPng } from "html-to-image";
import {
	addOutline,
	arrowBackOutline,
	arrowRedo,
	arrowUndo,
	chevronBack,
	closeOutline,
	cloudUploadOutline,
	createOutline,
	eyeOffOutline,
	eyeOutline,
	gitBranchOutline,
	helpCircleOutline,
	imageOutline,
	menuOutline,
	removeOutline,
	textOutline,
	trash,
} from "ionicons/icons";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { Trans } from "react-i18next";
import Joyride from "react-joyride";
import { Provider, connect } from "react-redux";
import ReactFlow, {
	Background,
	BackgroundVariant,
	Controls,
	Edge,
	EdgeProps,
	EdgeTypes,
	Node,
	Position,
	ReactFlowInstance,
	ReactFlowProvider,
	addEdge,
	applyNodeChanges,
	getConnectedEdges,
	updateEdge,
	useEdgesState,
	useNodesState,
} from "reactflow";
import "reactflow/dist/base.css";
import "reactflow/dist/style.css";
import TypeLink from "../../../assets/icons/TypeLink.svg";
import ZoomInMaps from "../../../assets/icons/ZoomInMaps.svg";
import brush from "../../../assets/icons/brush.svg";
import centerElement from "../../../assets/icons/centerElement.svg";
import pencilEdit from "../../../assets/icons/pencilEdit.svg";
import voiceOff from "../../../assets/icons/speechdictionary.svg";
import trashBin from "../../../assets/icons/trashBin.svg";
import { Step, handleJoyrideCallback, updateChoice } from "../../../common/utilsJoyride";
import { auth, firestore, storage } from "../../../firebaseConfig";
import i18n from "../../../i18n";
import { makeId } from "../../files/store/services";
import { imagesSearch } from "../../popover/common/imagesSearch";
import { fromBackendFontNameToCssFontFamily, settingsServices, UserSettings } from "../../settings/store/services";
import ButtonEdge from "../components/ButtonEdge.js";
import FloatingEdge from "../components/FloatingEdge";
import { MapFormatModal } from "../components/MapFormatModal";
import { MapSideButtons } from "../components/MapSideButtons";
import { mapsActions } from "../store/actions";
import { getLayoutedElements } from "../utils";
import { hasBorderColor, isBackgroundColorDark } from "../utils/MindMapUtils";

import { PersistGate } from "redux-persist/integration/react";
import { appVersion } from "../../../appVersion";
import defaultConnectionDark from "../../../assets/icons/defaultConnectionDark.svg";
import defaultConnectionLight from "../../../assets/icons/defaultConnectionLight.svg";
import drawingPencil from "../../../assets/icons/drawingpencil.svg";
import floatingConnectionDark from "../../../assets/icons/floatingConnectionDark.svg";
import floatingConnectionLight from "../../../assets/icons/floatingConnectionLight.svg";
import leftRightTreeDark from "../../../assets/icons/left_right_tree_dark.svg";
import leftRightTreeLight from "../../../assets/icons/left_right_tree_light.svg";
import mindMapIconDark from "../../../assets/icons/mind_map_icon_dark.svg";
import mindMapIconLight from "../../../assets/icons/mind_map_icon_light.svg";
import nodeFill from "../../../assets/icons/nodeFill.svg";
import smoothConnectionDark from "../../../assets/icons/smoothConnectionDark.svg";
import smoothConnectionLight from "../../../assets/icons/smoothConnectionLight.svg";
import topDownTreeDark from "../../../assets/icons/top_down_tree_dark.svg";
import topDownTreeLight from "../../../assets/icons/top_down_tree_light.svg";
import { sendEvent } from "../../../common/amplitudeUtils";
import { persistor, store } from "../../../common/store";
import { getTTS } from "../../popover/common/ttsServices";
import { ttsServices } from "../../tts/store/services";
import { getDarkMode } from "../components/utils";
import "./MindMap.css";
import { mapsServices } from "../store/services";
import { Session } from "../../sessions/store/reducers";
import { MapData } from "../store/reducers";
import { argumentServices } from "../../sessions/store/services";
import { useUndoRedo } from "./HistoryManager";

let newFontSettings: UserSettings;

let tutorialTxtZoomAll = "Riposiziona la mappa al centro dello schermo";
let tutorialTxtZoomNode = "Metti al centro il nodo selezionato";
let tutorialTxtZoom = "Diminuisci o aumenta la dimensione della mappa";
let tutorialTxtModify = "Modifica la mappa";
let tutorialTxtDownload = "Scarica la mappa";
let tutorialTxtDelete = "Elimina la mappa";
let tutorialTxtAddNode = "Aggiungi un nodo";
let tutorialNodeDrawImg = "Fai un disegno di quello che il nodo deve rappresentare";
let tutorialNodeColorChange = "Cambia colore del nodo";
let tutorialNodeChangeText = "Modifica testo all'interno del nodo";
let tutorialNodeDelete = "Elimina nodo";

setupIonicReact();

type Props = {
	history: any;
	fillNodeColors: boolean;
	isUppercase: boolean;
	showImagesCaptions: boolean;
	match: any;
	organizationName: string | null;
	toggleColorFill: () => void;
	toggleImagesCaptions: () => void;
	toggleUppercase: () => void;
};

type State = {
	nodeId: number;
	edgeId: number;
	userUuid: string;
	bearerToken: string;
	mapData: any;
	onlyMap: boolean;
	mapId:string;
	sessionId:string;

	selectedNodeId: string;
	showNameChangePopup: boolean;
	newNodeName: string;

	showEdgeLabelPopover: boolean;
	showEdgeCreatePopover: boolean;
	edgeLabel: string;
	tempEdgeParams: any;

	rfInstance: any;

	showContentPopover: boolean;
	popoverEvent: any;
	documentId: string;
	direction: "DOWN" | "RIGHT" | "RADIAL" | "NONE";

	initialNodes: any[];
	initialEdges: any[];
	fontSettings: UserSettings | null;
	hoveredId: string | null;
	initialFontSize: number | null;

	pickedColor: string | null;

	showImagesPopover: boolean;

	currentNodeText: string;
	imageSearchResponse: any | undefined;
	wordPicture: string;
	selectedNodeHasImage: boolean;
	showTextOnImages: boolean;

	isModalMenuOpen: boolean;
	isDropDownMenuMobileOpen: boolean;
	isLoadingImages: boolean;
	selectedEdgeId: string;
	connectingMode: boolean;
	isNodeSelected: boolean;

	edgeType: string;
	wordCustomImage: string | null;

	selectedNodesToConnect: Node[];
	docTitle: string;
	menuPage: "main" | "text" | "edgeType";
	modeEditing: "main" | "editing";
	modalMenuPage: "main" | "text" | "edgeType";
	disableExitButton: boolean;

	steps: Step[];
	stepsModifyNode: Step[];
	tutorial: boolean;
	stepIndex: number;
	modifyNodeTutorial: boolean;
	firstNode: boolean;
	audioToPlay: any;
	speechMarks: any;
	disableTtsButton: boolean;
	showEdgeTypePopover: boolean;
	showLayoutOptions: boolean;

	session: Session | null;
};
class MindMap extends React.Component<Props, State> {
	debounceTimer: ReturnType<typeof setTimeout> | null = null;
	constructor(props: any) {
		super(props);
		this.state = {
			nodeId: 0,
			edgeId: 0,
			userUuid: "",
			bearerToken: "",
			mapData: undefined,
			onlyMap: false,
			mapId:"",
			sessionId:"",

			selectedNodeId: "",
			showNameChangePopup: false,
			newNodeName: "",

			showEdgeLabelPopover: false,
			showEdgeCreatePopover: false,
			edgeLabel: "",
			tempEdgeParams: null,

			rfInstance: null,

			showContentPopover: false,
			popoverEvent: null,
			documentId: "",
			direction: "NONE",

			initialNodes: [],
			initialEdges: [],

			fontSettings: null,
			hoveredId: null,
			initialFontSize: null,

			pickedColor: null,

			showImagesPopover: false,

			currentNodeText: "",
			imageSearchResponse: undefined,
			wordPicture: "",

			selectedNodeHasImage: false,
			showTextOnImages: false,

			isModalMenuOpen: false,
			isDropDownMenuMobileOpen: false,
			isLoadingImages: false,
			selectedEdgeId: "",
			connectingMode: false,
			isNodeSelected: false,

			edgeType: "",

			wordCustomImage: null,

			selectedNodesToConnect: [],
			docTitle: "",
			menuPage: "main",
			modeEditing: "main",
			modalMenuPage: "main",

			disableExitButton: false,
			showEdgeTypePopover: false,
			showLayoutOptions: false,
			steps: !isMobile
				? [
						{
							target: "#zoom-all",
							content: (
								<div>
									<p>
										<Trans>{tutorialTxtZoomAll}</Trans>
									</p>
								</div>
							),
							disableBeacon: true,
							disableScrollParentFix: true,
							placement: "auto",
						},
						{
							target: "#zoom-node",
							content: (
								<div>
									<p>
										<Trans>{tutorialTxtZoomNode}</Trans>
									</p>
								</div>
							),
							disableBeacon: true,
							placement: "auto",
						},
						{
							target: "#zoom-out",
							content: (
								<div>
									<p>
										<Trans>{tutorialTxtZoom}</Trans>
									</p>
								</div>
							),
							disableBeacon: true,
							placement: "auto",
						},
						{
							target: "#edit-map",
							content: (
								<div>
									<p>
										<Trans>{tutorialTxtModify}</Trans>
									</p>
								</div>
							),
							disableBeacon: true,
							placement: "auto",
						},
						{
							target: "#download-map",
							content: (
								<div>
									<p>
										<Trans>{tutorialTxtDownload}</Trans>
									</p>
								</div>
							),
							disableBeacon: true,
							placement: "auto",
						},
						{
							target: "#delete-map",
							content: (
								<div>
									<p>
										<Trans>{tutorialTxtDelete}</Trans>
									</p>
								</div>
							),
							disableBeacon: true,
							placement: "auto",
						},
						{
							target: "#add-node",
							content: (
								<div>
									<p>
										<Trans>{tutorialTxtAddNode}</Trans>
									</p>
								</div>
							),
							disableBeacon: true,
							placement: "auto",
						},
				  ]
				: [
						{
							target: "#zoomInMobile",
							content: (
								<div>
									<p>
										<Trans>{tutorialTxtZoomAll}</Trans>
									</p>
									{/* <video className='tutorialVideo' autoPlay controls controlsList="nodownload">
                            <source
                                src="https://reasy-public-tutorials.s3.eu-south-1.amazonaws.com/v1.7.5/18+-+Come+caricare+un+appunto+scritto+a+mano.mp4"
                                type="video/mp4"
                            />
                            Your browser does not support the video tag.
                            </video> */}
								</div>
							),
							disableBeacon: true,
							disableScrollParentFix: true,
							placement: "auto",
						},
						{
							target: "#addNodeMobile",
							content: (
								<div>
									<p>
										<Trans>{tutorialTxtAddNode}</Trans>
									</p>
								</div>
							),
							disableBeacon: true,
							disableScrollParentFix: true,
							placement: "auto",
						},
						{
							target: "#zoomNodeMobile",
							content: (
								<div>
									<p>
										<Trans>{tutorialTxtZoomNode}</Trans>
									</p>
									{/* <video className='tutorialVideo' autoPlay controls controlsList="nodownload">
                            <source
                                src="https://reasy-public-tutorials.s3.eu-south-1.amazonaws.com/v1.7.5/18+-+Come+caricare+un+appunto+scritto+a+mano.mp4"
                                type="video/mp4"
                            />
                            Your browser does not support the video tag.
                            </video> */}
								</div>
							),
							disableBeacon: true,
							placement: "auto",
						},
						{
							target: "#modifyMapMobile",
							content: (
								<div>
									<p>
										<Trans>{tutorialTxtModify}</Trans>
									</p>
									{/* <video className='tutorialVideo' autoPlay controls controlsList="nodownload">
                            <source
                                src="https://reasy-public-tutorials.s3.eu-south-1.amazonaws.com/v1.7.5/18+-+Come+caricare+un+appunto+scritto+a+mano.mp4"
                                type="video/mp4"
                            />
                            Your browser does not support the video tag.
                            </video> */}
								</div>
							),
							disableBeacon: true,
							placement: "auto",
						},
						{
							target: "#deleteMapMobile",
							content: (
								<div>
									<p>
										<Trans>{tutorialTxtDelete}</Trans>
									</p>
								</div>
							),
							disableBeacon: true,
							placement: "auto",
						},
				  ],
			tutorial: false,
			stepIndex: 0,
			stepsModifyNode: [
				{
					target: "#modifyNode",
					content: (
						<div>
							<p>
								<Trans>{tutorialTxtAddNode}</Trans>
							</p>
							{/* <video className='tutorialVideo' autoPlay controls controlsList="nodownload">
                            <source
                                src="https://reasy-public-tutorials.s3.eu-south-1.amazonaws.com/v1.7.5/18+-+Come+caricare+un+appunto+scritto+a+mano.mp4"
                                type="video/mp4"
                            />
                            Your browser does not support the video tag.
                            </video> */}
						</div>
					),
					disableBeacon: true,
					disableScrollParentFix: true,
					placement: "auto",
				},
				{
					target: "#add-image",
					content: (
						<div>
							<p>
								<Trans>{tutorialNodeDrawImg}</Trans>
							</p>
						</div>
					),
					disableBeacon: true,
					disableScrollParentFix: true,
					placement: "auto",
				},
				{
					target: "#change-color",
					content: (
						<div>
							<p>
								<Trans>{tutorialNodeColorChange}</Trans>
							</p>
							{/* <video className='tutorialVideo' autoPlay controls controlsList="nodownload">
                            <source
                                src="https://reasy-public-tutorials.s3.eu-south-1.amazonaws.com/v1.7.5/18+-+Come+caricare+un+appunto+scritto+a+mano.mp4"
                                type="video/mp4"
                            />
                            Your browser does not support the video tag.
                            </video> */}
						</div>
					),
					disableBeacon: true,
					placement: "auto",
				},
				{
					target: "#change-text",
					content: (
						<div>
							<p>
								<Trans>{tutorialNodeChangeText}</Trans>
							</p>
							{/* <video className='tutorialVideo' autoPlay controls controlsList="nodownload">
                            <source
                                src="https://reasy-public-tutorials.s3.eu-south-1.amazonaws.com/v1.7.5/18+-+Come+caricare+un+appunto+scritto+a+mano.mp4"
                                type="video/mp4"
                            />
                            Your browser does not support the video tag.
                            </video> */}
						</div>
					),
					disableBeacon: true,
					placement: "auto",
				},
				{
					target: "#delete-node",
					content: (
						<div>
							<p>
								<Trans>{tutorialNodeDelete}</Trans>
							</p>
						</div>
					),
					disableBeacon: true,
					placement: "auto",
				},
			],
			modifyNodeTutorial: false,
			firstNode: false,
			audioToPlay: null,
			speechMarks: [],
			disableTtsButton: false,
			session: null,
		};
	}
	mapData: any;
	async getMapData() {
		try {
			const documentSnapshot = await getDoc(doc(firestore, `m/${this.props.match.params.fileId}/`));
			if (documentSnapshot.exists()) {
				return documentSnapshot.data() || this.props.history.location.state.documentData;
			} else {
				return null; // or a default value
			}
		} catch (error) {
			console.error("Error fetching document:", error);
			return null; // Handle the error and return a default value or null
		}
	}

	// componentDidMount() {
	// 	auth.onAuthStateChanged(async (userData) => {
	// 		if (userData) {
	// 			// console.log("USER DATA", userData);
	// 			this.setState({ userUuid: userData.uid });
	// 			// let viewingSettings = await settingsServices.getDocumentViewingSettings(userData.uid);
	// 			// console.log("GOT VIEWING SETTINGS", viewingSettings);

	// 			// // viewingSettings.fontSize = viewingSettings.fontSize >= 15 ? viewingSettings.fontSize - 5 : viewingSettings.fontSize;
	// 			// this.setState({
	// 			// 	fontSettings: {
	// 			// 		font: viewingSettings.font,
	// 			// 		fontSize: viewingSettings.fontSize,
	// 			// 		lineLeading: viewingSettings.lineLeading,
	// 			// 	},
	// 			// });
	// 			userData.getIdToken().then(token => {
	// 				this.setState({ bearerToken: token });
	// 				if (this.props.history.location.state.mapId) {
	// 					let mapId = this.props.history.location.state.mapId
	// 					mapsServices.getMapData(mapId, token)
	// 						.then(response => {
	// 							console.log("MAP response: ", response.content.nodes);
	// 						})
	// 						.catch(err => {
	// 							console.error("Error fetching map data from new backend: ", err);
	// 						})
	// 				}
	// 				if (this.props.history.location.state.session) {
	// 					let session = this.props.history.location.state.session
	// 					this.setState({ session: session })
	// 				}
	// 			})
	// 			// await this.getMapData().then(async (mapData) => {
	// 			// 	if (mapData) {
	// 			// 		let viewingSettings = await settingsServices.getDocumentViewingSettings(userData.uid);
	// 			// 		if (!mapData.s || !mapData.s.f) {
	// 			// 			this.setState({
	// 			// 				fontSettings: {
	// 			// 					font: viewingSettings.font,
	// 			// 					fontSize: viewingSettings.fontSize,
	// 			// 					lineLeading: viewingSettings.lineLeading,
	// 			// 				},
	// 			// 			});
	// 			// 		} else {
	// 			// 			viewingSettings.fontSize = mapData.s.f; // MAPS WILL HAVE A DIFFERENT FONT SIZE SETTING
	// 			// 			this.setState({
	// 			// 				fontSettings: {
	// 			// 					font: viewingSettings.font,
	// 			// 					fontSize: (viewingSettings.fontSize = mapData.s.f),
	// 			// 					lineLeading: viewingSettings.lineLeading,
	// 			// 				},
	// 			// 			});
	// 			// 		}
	// 			// 		if (mapData.d.d) {
	// 			// 			this.mapData = mapData;
	// 			// 			this.setState({ direction: mapData.d.d });
	// 			// 		}
	// 			// 		let argPresent = this.props.history.location.state ? this.props.history.location.state.argumentID.uuid : false
	// 			// 		if (mapData.f === null || argPresent) this.setState({ onlyMap: true });
	// 			// 		else this.setState({ onlyMap: false });
	// 			// 		let nodes = mapData.d.n;
	// 			// 		for (let i = 0; i < document.getElementsByClassName("react-flow__node").length; i++) {
	// 			// 			(document.getElementsByClassName("react-flow__node")[i] as HTMLElement).style.fontFamily = fromBackendFontNameToCssFontFamily(
	// 			// 				viewingSettings.font
	// 			// 			);
	// 			// 			(document.getElementsByClassName("react-flow__node")[i] as HTMLElement).style.fontSize = this.state.fontSettings
	// 			// 				? this.state.fontSettings.fontSize
	// 			// 				: viewingSettings.fontSize + "px";
	// 			// 			let currentNode = nodes.filter(
	// 			// 				(node: any) => node.id === (document.getElementsByClassName("react-flow__node")[i] as HTMLElement).getAttribute("data-id")
	// 			// 			)[0];
	// 			// 			if (currentNode.image) {
	// 			// 				if (this.props.showImagesCaptions) {
	// 			// 					(document.getElementsByClassName("react-flow__node")[i] as HTMLElement).style.fontSize = this.state.fontSettings
	// 			// 						? this.state.fontSettings.fontSize
	// 			// 						: viewingSettings.fontSize + "px";
	// 			// 				} else {
	// 			// 					(document.getElementsByClassName("react-flow__node")[i] as HTMLElement).style.fontSize = "0px";
	// 			// 				}
	// 			// 			}
	// 			// 		}
	// 			// 	}
	// 				// setTimeout(() => {
	// 				// 	for (let i = 0; i < document.getElementsByClassName("react-flow__node").length; i++) {
	// 				// 		if (viewingSettings)
	// 				// 			(document.getElementsByClassName("react-flow__node")[i] as HTMLElement).style.fontFamily = fromBackendFontNameToCssFontFamily(viewingSettings.font);
	// 				// 		if (mapData) {
	// 				// 			if (mapData.d.d) {
	// 				// 				this.mapData = mapData
	// 				// 				this.setState({ direction: mapData.d.d })
	// 				// 			}
	// 				// 			if (mapData.f === null) this.setState({ onlyMap: true });
	// 				// 			else this.setState({ onlyMap: false });
	// 				// 			let nodes = mapData.d.n;
	// 				// 			let currentNode = nodes.filter(
	// 				// 				(node: any) => node.id === (document.getElementsByClassName("react-flow__node")[i] as HTMLElement).getAttribute("data-id")
	// 				// 			)[0];
	// 				// 			if (currentNode.image) {
	// 				// 				if (this.props.showImagesCaptions) {
	// 				// 					(document.getElementsByClassName("react-flow__node")[i] as HTMLElement).style.fontSize = this.state.fontSettings.fontSize + 10 + 'px';
	// 				// 				}
	// 				// 				else {
	// 				// 					(document.getElementsByClassName("react-flow__node")[i] as HTMLElement).style.fontSize = '0px';
	// 				// 				}

	// 				// 			}
	// 				// 		} else {
	// 				// 			console.log("no map data");
	// 				// 		}
	// 				// 	}
	// 				// }, 1000)

	// 			// 	// let map = await this.getMapData();
	// 			// 	if (mapData?.d.n.length === 0) {
	// 			// 		if (document.getElementsByClassName("react-flow__node").length === 0) {
	// 			// 			console.log(document.getElementsByClassName("react-flow__node").length);
	// 			// 			if (isMobile) document.getElementById("addNodeMobile")?.click();
	// 			// 			else document.getElementById("add-node")?.click();
	// 			// 		}
	// 			// 	}
	// 			// 	// this.getUserChoiceOnTutorial(userData.uid, "tMM", "auto");
	// 			// 	// this.getUserChoiceOnTutorial(this.state.userUuid, "tMN")
	// 			// 	this.setState({ disableExitButton: false });
	// 			// 	if (!isMobile)
	// 			// 		if (document.getElementsByClassName("actionButtons")[0]) (document.getElementsByClassName("actionButtons")[0] as HTMLElement).style.zIndex = "1000";
	// 			// });
	// 		}
	// 	});
	// }
	debounce = (callback: () => void, delay: number = 2000) => {
		// Clear the previous timer
		if (this.debounceTimer) {
			clearTimeout(this.debounceTimer);
		}

		// Set a new timer for the specified delay (2 seconds by default)
		this.debounceTimer = setTimeout(() => {
			callback(); // Execute the callback once no more changes are detected
		}, delay);
	};
	getUserChoiceOnTutorial(uid: string, whichTutorial: string, trigger: "auto" | "manual") {
		settingsServices.getUserTutorialChoice(uid, whichTutorial).then((response) => {
			if (whichTutorial === "tMN") {
				if (response) {
					let oneNode = document.getElementsByClassName("react-flow__node")[0];
					let nodeId = oneNode.getAttribute("data-id");
					console.log(oneNode.getAttribute("data-id"));
					this.setState({ isNodeSelected: true, selectedNodeId: nodeId ? nodeId : "" }, () => {
						setTimeout(() => {
							this.setState({ modifyNodeTutorial: true, tutorial: true });
						}, 100);
					});
				} else this.setState({ modifyNodeTutorial: response, isNodeSelected: false, selectedNodeId: "" });
			} else {
				if (response) {
					setTimeout(() => {
						this.setState({ tutorial: response, modifyNodeTutorial: false });

						const element = document.getElementsByClassName("react-flow__node")[0] as HTMLDivElement;
						if (element) {
							let attribute = element.getAttribute("data-id") || "";
							this.setState({ selectedNodeId: attribute });
						}
					}, 500);
				} else this.setState({ tutorial: response });
			}
			if (response === true) {
				sendEvent({
					"user_id": this.state.userUuid,
					"event_type": "Tutorial " + (trigger === "auto" ? "showed" : "opened"),
					"event_properties": {
						"user_org": this.props.organizationName !== null ? this.props.organizationName : "Private User",
						"page": "Mind Map",
					},
					"language": i18n.language,
					"platform": isPlatform("ios") ? "ios" : isPlatform("android") ? "android" : "desktop",
					"app_version": appVersion,
					"time": Date.now(),
				});
			}
		});
	}

	fromGSUrltoUrl(gsUrl: string) {
		return new Promise<string>((resolve, reject) => {
			getDownloadURL(storageRef(storage, gsUrl))
				.then((url) => {
					this.setState({ wordPicture: url /* isLoadingImages: false, */ });
					resolve(url);
				})
				.catch((err) => {
					////console.log('[fromGSUrltoUrl] service error:', err);
					reject(err);
				});
		});
	}
	customImageFromGSUrltoUrl(gsUrl: string) {
		return new Promise<string>((resolve, reject) => {
			getDownloadURL(storageRef(storage, gsUrl))
				.then((url) => {
					this.setState({ wordCustomImage: url /* isLoadingImages: false, */ });
					resolve(url);
				})
				.catch((err) => {
					////console.log('[fromGSUrltoUrl] service error:', err);
					reject(err);
				});
		});
	}

	text_to_image(source: string) {
		const image = document.createElement("img");
		image.id = `image-${this.state.selectedNodeId}`;
		image.src = source;
		image.style.maxWidth = "150px";
		for (let i = 0; i < document.getElementsByClassName("react-flow__node").length; i++) {
			if ((document.getElementsByClassName("react-flow__node")[i] as HTMLElement).getAttribute("data-id") === this.state.selectedNodeId) {
				(document.getElementsByClassName("react-flow__node")[i] as HTMLElement).appendChild(image);
			}
		}
	}
	generateRandomString(length: number) {
		const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
		let result = "";

		for (let i = 0; i < length; i++) {
			const randomIndex = Math.floor(Math.random() * characters.length);
			result += characters.charAt(randomIndex);
		}

		return result;
	}
	getEdgeId = () => {
		//console.log("RETURNING EDGE ID ", `${this.state.edgeId + 1}`);
		console.log(this.state.edgeId);
		if (this.state.edgeId === -Infinity || Number.isNaN(this.state.edgeId)) {
			const randomString = this.generateRandomString(3); // Generates a random string with a length of 3 characters
			return randomString;
		} else {
			this.setState({ edgeId: this.state.edgeId + 1 });
			return `${this.state.edgeId + 1}`;
		}
	};
	generatePDF = () => {
		// this.state.rfInstance.fitView({ duration: 500, minZoom: 0.1 });
		let _this = this;
		setTimeout(() => {
			function downloadImage(dataUrl: string) {
				const a = document.createElement("a");

				a.setAttribute("download", _this.state.docTitle != null ? _this.state.docTitle : "Reasy_Map" + ".png");
				a.setAttribute("href", dataUrl);
				a.click();
				prova.classList.remove("bgPNG");
			}
			let prova = document.querySelector(".react-flow") as HTMLElement;
			prova.classList.add("bgPNG");
			toPng(prova, {
				pixelRatio: 10,
				filter: (node: any) => {
					if (node?.classList?.contains("react-flow__minimap") || node?.classList?.contains("react-flow__controls")) {
						return false;
					}

					return true;
				},
			}).then(downloadImage);
		}, 500);
	};

	generateNodeId() {
		let nodes = this.state.rfInstance.getNodes();
		let nodesIdsArr: any = [];
		nodes.forEach((node: any) => {
			nodesIdsArr.push(node.id);
		});
		console.log(nodesIdsArr);
		let guid = () => {
			let s4 = () => {
				return Math.floor((1 + Math.random()) * 0x10000)
					.toString(16)
					.substring(1);
			};
			//return id of format 'aaaaaaaa'-'aaaa'-'aaaa'-'aaaa'-'aaaaaaaaaaaa'
			return s4() + s4() + "-" + s4() + "-" + s4() + "-" + s4() + "-" + s4() + s4() + s4();
		};
		let generatedId = guid();
		if (nodesIdsArr.includes(generatedId)) this.generateNodeId();
		else return generatedId;
	}

	async deleteMap() {
		if (this.state.documentId) {
			if (
				window.confirm(
					i18n.language === "it"
						? "Vuoi eliminare la mappa?"
						: i18n.language === "es"
						? "¿Quieres borrar el mapa?"
						: i18n.language === "fr"
						? "Voulez-vous supprimer la carte?"
						: i18n.language === "de"
						? "Möchten Sie die Karte löschen?"
						: i18n.language === "ca"
						? "Vols esborrar el mapa?"
						: "Do you want to delete the map?"
				) === true
			) {
				if (!this.state.onlyMap) {
					await deleteDoc(doc(firestore, `m/${this.state.documentId}`)).then(() => {
						this.props.history.push(`/file/${this.state.documentId}`);
						window.location.reload();
					});
				} else {
					await deleteDoc(doc(firestore, `m/${this.state.documentId}`)).then((response) => {
						this.props.history.push(`/files/`);
						window.location.reload();
					});
				}
			}
		}
	}

	edgeTypes: EdgeTypes = {
		floating: ({ id, source, target, markerEnd, style, label }: EdgeProps) => {
			if (markerEnd) {
				// Create and return the floating edge component
				return <FloatingEdge id={id} source={source} target={target} markerEnd={markerEnd} style={style} label={label} />;
			} else {
				return null;
			}
		},
		buttonedge: ({ id, sourceX, sourceY, targetX, targetY, sourcePosition, targetPosition, style, markerEnd }: EdgeProps) => {
			return (
				<ButtonEdge
					id={id}
					sourceX={sourceX}
					sourceY={sourceY}
					targetX={targetX}
					targetY={targetY}
					sourcePosition={sourcePosition}
					targetPosition={targetPosition}
					style={style}
					markerEnd={markerEnd}
				/>
			);
		},
	};

	DiagramComponent = () => {
		//    const intervalSave= setInterval(()=>{saveAndExit(false)},12000)
		const reactFlowWrapper = useRef<any>(null);
		const [nodes, setNodes] = useNodesState([]);
		const [edges, setEdges] = useEdgesState([]);
		const maxHistoryStates = 50;
		const { saveState, undo, redo, canUndo, canRedo, currentIndex } = useUndoRedo(maxHistoryStates);

		const [reactFlowInstance, setReactFlowInstance] = useState<any>(null);
		const [nodeName, setNodeName] = useState(this.state.selectedNodeId);
		const [nodeBg] = useState("#ffffff");

		const useDebounce = (value: any, delay: any) => {
			const [debouncedValue, setDebouncedValue] = useState(value);

			useEffect(() => {
				const handler = setTimeout(() => {
					setDebouncedValue(value);
				}, delay);

				return () => clearTimeout(handler);
			}, [value, delay]);

			return debouncedValue;
		};

		// console.log(nodes)
		useEffect(() => {
			setNodes((nds: any) =>
				nds.map((node: any) => {
					if (node.id === this.state.selectedNodeId) {
						// it's important that you create a new object here
						// in order to notify react flow about the change
						node.data = {
							...node.data,
							label: nodeName,
						};
					}

					return node;
				})
			);
		}, [nodeName, setNodes]);
		useEffect(() => {
			setNodes((nds: any) =>
				nds.map((node: any) => {
					if (node.id === this.state.selectedNodeId) {
						// it's important that you create a new object here
						// in order to notify react flow about the change
						node.style = { ...node.style, backgroundColor: nodeBg };
					}

					return node;
				})
			);
		}, [nodeBg, setNodes]);

		const onNodesChange = useCallback(
			(changes: any) => {
				setNodes((nds: any) => {
					const newNodes = applyNodeChanges(changes, nds);
					// Save state after the change
					setTimeout(() => saveState(newNodes, edges), 0);
					return newNodes;
				});
				for (let i = 0; i < document.getElementsByClassName("react-flow__node").length; i++) {
					if (this.state.fontSettings) {
						(document.getElementsByClassName("react-flow__node")[i] as HTMLElement).style.fontFamily = fromBackendFontNameToCssFontFamily(
							this.state.fontSettings.font_name ? this.state.fontSettings.font_name : "arial"
						);
						// (document.getElementsByClassName('react-flow__node')[i] as HTMLElement).style.fontSize = this.state.fontSettings.fontSize
					}
				}
			},
			[setNodes, edges, saveState]
		);

		// const onLayout = useCallback(
		//     (direction) => {
		//         this.setState({ direction: direction })
		//         const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
		//             nodes,
		//             edges,
		//             direction
		//         );

		//         setNodes([...layoutedNodes]);
		//         setEdges([...layoutedEdges]);
		//     },
		//     [nodes, edges]
		// );

		const onConnect = useCallback(
			(params: any) => {
				setEdges((eds: any) => {
					const newEdges = addEdge(params, eds);
					setTimeout(() => saveState(nodes, newEdges), 0);
					return newEdges;
				});
			},
			[setEdges, nodes, saveState]
		);
		const onInit = async (_reactFlowInstance: ReactFlowInstance) => {
			(document.getElementsByClassName("react-flow__attribution")[0] as HTMLElement).remove();
			if (isMobile) {
				// (document.getElementsByClassName('react-flow__minimap')[0] as HTMLElement).remove();
				(document.getElementsByClassName("react-flow__controls")[0] as HTMLElement).remove();
			}

			if (this.state.mapData) {
				//THERE IS A MAP FOR THE DOCUMENT
				// if (mapData.o !== this.state.userUuid && mapData.f && mapData.f.trim() !== "") {
				// 	// Do something when mapData.o is not equal to userUuid and mapData.f is not empty
				// 	// console.log("Do something when mapData.o is not equal to userUuid and mapData.f is not empty");
				// } else if (mapData.o !== this.state.userUuid) {
				// 	// Redirect to '/files/' if mapData.o is not equal to userUuid
				// 	this.props.history.push("/files/");
				// } else {
				// 	// Handle other cases if needed
				// 	// console.log("Handle other cases");
				// }

				// if (mapData.f === null)
				// 	this.setState({ onlyMap: true }, () => {
				// 		// console.log("creee");
				// 	});
				// else this.setState({ onlyMap: false });

				let documentData = this.state.mapData;
				let documentTitle = this.state.mapData.title ? this.state.mapData.title : "Mappa concetuale";
				//console.log("DOC TITLE ", documentTitle);

				let nodesInit: any[] = [];
				let edgesInit: any[] = [];
				let docId = this.props.match.params.fileId;
				// let paragraphs = this.props.history.location.state.paragraphs;
				let alreadyExists = true;

				nodesInit = documentData.content.nodes;
				edgesInit = documentData.content.edges;
				console.log(nodesInit);
				//console.log("SNAPSHOT DATA", documentData);
				// if(nodes.length===0){
				//     if (document.getElementsByClassName("react-flow__node").length === 0) {
				//         console.log(document.getElementsByClassName("react-flow__node").length)
				//         if(isMobile)
				//             document.getElementById("ddNodeMobile")?.click()
				//         else
				//             document.getElementById("add-node")?.click()
				//     }
				// }
				this.setState({ documentId: docId, docTitle: documentTitle, initialNodes: documentData.content.nodes, initialEdges: documentData.content.edges });
				let maxNodeId = Math.max.apply(
					Math,
					nodesInit.map(function (o) {
						return o.id;
					})
				);
				let maxEdgeId = Math.max.apply(
					Math,
					edgesInit.map(function (o) {
						return o.id;
					})
				);
				this.setState({ nodeId: maxNodeId + 1, edgeId: maxEdgeId + 1 });

				this.setState({ rfInstance: _reactFlowInstance });
				// console.log("[onInit] prop uppercase: ", this.props.isUppercase);
				// let isHorizontal = mapData.d.d === "RIGHT";
				//console.log("DOC DATA", documentData);

				const newNodes: Node<any>[] = nodesInit.map((node) => ({
					id: node.id,
					data: {
						label: node.label,
					},
					style: {
						borderWidth: node.color === "#000000" ? "" : "7px",
						borderColor: node.color === "#000000" ? "#000000" : node.color?.slice(0, -2),
						backgroundColor: node.color === "#000000" ? node.color?.slice(0, -2) : node.color,
						textTransform: this.props.isUppercase ? "none" : "uppercase",
						borderRadius: "20px",
						color: node.txtColor ? node.txtColor : "#000",
						fontSize: node.fontSize ? node.fontSize : this.state.fontSettings !== null ? this.state.fontSettings.font_size : "60px",
					},
					type: "default",
					// sourcePosition: isHorizontal ? Position.Right : Position.Bottom,
					// targetPosition: isHorizontal ? Position.Left : Position.Top,
					position: node.position,
					image: node.image,
				}));
				_reactFlowInstance.addNodes(newNodes);

				let nodesIds = nodesInit.map((node) => node.id);
				console.log(edgesInit[0].type);
				if (edgesInit.length > 0) this.setState({ edgeType: edgesInit[0].type });

				edgesInit.forEach((edge) => {
					if (!nodesIds.includes(edge.source) || !nodesIds.includes(edge.target))
						//PREVENTS OLD EDGES TO BE RENDERED -->  NEED TO FIND A WAY TO REMOVE THEM FROM BEFORE UPDATING DB WHEN SAVING
						return;
					else {
						edge.zIndex = 0;
						setEdges((eds: any) => addEdge(edge, eds));
					}
					//_reactFlowInstance.addEdges(edge)
				});
				setReactFlowInstance(_reactFlowInstance);

				setTimeout(() => {
					for (let i = 0; i < document.getElementsByClassName("react-flow__node").length; i++) {
						const node = document.getElementsByClassName("react-flow__node")[i] as HTMLElement;
						if (this.props.fillNodeColors) {
							let nodeColor;
							if (_reactFlowInstance.getNodes()[i].style!.backgroundColor!) {
								if (_reactFlowInstance.getNodes()[i].style!.backgroundColor!.includes("undefined")) {
									nodeColor = "var(--neutral-color)";
									console.log(_reactFlowInstance.getNodes()[i].style!.backgroundColor!);
								} else nodeColor = _reactFlowInstance.getNodes()[i].style!.backgroundColor!;
							} else nodeColor = "var(--neutral-color)";
							node.style.backgroundColor = nodeColor;
						} else {
							node.style.backgroundColor = "var(--bg-content-color)";
						}
						if (this.props.isUppercase) {
							node.style.textTransform = "none";
						} else {
							node.style.textTransform = "uppercase";
						}
					}

					for (let i = 0; i < document.getElementsByClassName("react-flow__edge").length; i++) {
						const edge = document.getElementsByClassName("react-flow__edge")[i] as HTMLElement;
						if (this.props.isUppercase) {
							edge.style.textTransform = "none";
						} else {
							edge.style.textTransform = "uppercase";
						}
					}
					for (let i = 0; i < document.getElementsByClassName("react-flow__node").length; i++) {
						if (nodesInit[0].fontSize <= 35) (document.getElementsByClassName("react-flow__node")[i] as HTMLElement).style.maxWidth = "500px";
						else if (nodesInit[0].fontSize > 35 && nodesInit[0].fontSize <= 85)
							(document.getElementsByClassName("react-flow__node")[i] as HTMLElement).style.maxWidth = "650px";
						else if (nodesInit[0].fontSize > 85) (document.getElementsByClassName("react-flow__node")[i] as HTMLElement).style.maxWidth = "950px";
					}
					nodesInit.forEach((node) => {
						if (node.image) {
							const image = document.createElement("img");
							image.id = `image-${node.id}`;
							// console.log("SONO AL NODO: ", node);

							image.src = `${node.image.startsWith("https://") ? node.image : "data:image/png;base64," + node.image}`;
							// console.log("RISULTATO IMMAGINE ", image.src);
							for (let i = 0; i < document.getElementsByClassName("react-flow__node").length; i++) {
								if ((document.getElementsByClassName("react-flow__node")[i] as HTMLElement).getAttribute("data-id") === node.id) {
									if (!this.props.showImagesCaptions) {
										(document.getElementsByClassName("react-flow__node")[i] as HTMLElement).style.fontSize = "0px";
									}
									(document.getElementsByClassName("react-flow__node")[i] as HTMLElement).appendChild(image);
								}
							}
						}
					});

					// updateFontSize(this.state.fontSettings.fontSize);
				}, 500);
				/* COMMENT THIS TO AVOID REORDERING ON INIT -> THIS WOULD MAKE THE NODES POSITION USELESS */
				if (!alreadyExists) {
					// const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(nodes, edges, this.state.direction);
					// setNodes([...layoutedNodes]);
					// setEdges([...layoutedEdges]);
				}
				// console.log("NODES from db", nodes);
				//console.log('EDGES from db', edges);
				//console.log('NODES INIT', _reactFlowInstance.getNodes());
				//console.log('EDGES INIT', _reactFlowInstance.getEdges());
			}
		};
		const changeEdgeType = (edgeType: "smoothstep" | "default" | "floating") => {
			let tempEdges: any[] = [];
			let tempNodes: any[] = [];

			this.setState({ edgeType: edgeType });
			edges.forEach((edge: any) => {
				edge.type = edgeType;
				tempEdges.push(edge);
			});
			setEdges(tempEdges);
			if (this.state.direction === "DOWN") {
				nodes.forEach((node) => {
					node.sourcePosition = Position.Bottom;
					node.targetPosition = Position.Top;
					tempNodes.push(node);
				});
			} else if (this.state.direction === "RIGHT") {
				nodes.forEach((node) => {
					node.sourcePosition = Position.Right;
					node.targetPosition = Position.Left;
					tempNodes.push(node);
				});
			} else return;
			setNodes(tempNodes);
		};

		const autoLayout = (elk_algorithm: any, direction: "DOWN" | "RIGHT" | "RADIAL") => {
			this.setState({ direction: direction! });
			const elkOptions = {
				"elk.algorithm": elk_algorithm,
				"elk.layered.spacing.nodeNodeBetweenLayers": elk_algorithm === "radial" ? "10" : "100",
				"elk.spacing.nodeNode": elk_algorithm === "radial" ? "10" : "180",
			};
			if (elk_algorithm === "radial") {
				changeEdgeType("floating");
			}
			const opts = {
				"elk.direction": direction,
				...elkOptions,
			};
			// const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(nodes, edges, this.state.direction);
			getLayoutedElements(nodes, edges, opts).then((response: any) => {
				setNodes(response.nodes);
				setEdges(response.edges);
				// setNodes([...layoutedNodes]);
				// setEdges([...layoutedEdges]);
				this.state.rfInstance.fitView({ duration: 300, minzoom: 0.1 });
			});
		};

		const updateFontSize = (newFontSize: number) => {
			if (newFontSize < 10 || newFontSize > 110) return;
			let nodesWithImage = nodes.filter((node: any) => node.image != null);
			let nodesWithImageIds = nodesWithImage.map((node: any) => node.id);
			for (let i = 0; i < document.getElementsByClassName("react-flow__edge-text").length; i++) {
				(document.getElementsByClassName("react-flow__edge-text")[i] as HTMLElement).style.fontSize = newFontSize + "px";
			}
			for (let i = 0; i < document.getElementsByClassName("react-flow__node").length; i++) {
				if (nodes[i] && nodes[i].style) nodes[i]!.style!.fontSize = newFontSize;
				if (newFontSize <= 35) (document.getElementsByClassName("react-flow__node")[i] as HTMLElement).style.maxWidth = "500px";
				else if (newFontSize > 35 && newFontSize <= 85) (document.getElementsByClassName("react-flow__node")[i] as HTMLElement).style.maxWidth = "650px";
				else if (newFontSize > 85) (document.getElementsByClassName("react-flow__node")[i] as HTMLElement).style.maxWidth = "950px";

				if (
					!nodesWithImageIds.includes((document.getElementsByClassName("react-flow__node")[i] as HTMLElement).getAttribute("data-id")!) &&
					!this.props.showImagesCaptions
				) {
					(document.getElementsByClassName("react-flow__node")[i] as HTMLElement).style.fontSize = newFontSize + "px";
				} else if (this.props.showImagesCaptions) {
					(document.getElementsByClassName("react-flow__node")[i] as HTMLElement).style.fontSize = newFontSize + "px";
				}
			}
			newFontSettings = {
				font_size: newFontSize,
			};

			this.setState({ fontSettings: newFontSettings });
		};
		const saveAndExit = async (noClose: boolean) => {
			if (noClose) this.setState({ disableExitButton: true });
			let nodeTxt = document.getElementsByClassName("react-flow__node-default")[0] as HTMLElement;

			const nodeTxtColor = nodeTxt ? getComputedStyle(nodeTxt).color : null;
			let nodesToPush: any[] = [];
			let edgesToPush: any[] = [];

			nodes.forEach((node: any) => {
				nodesToPush.push({
					id: node.id,
					label: node.data ? node.data.label : node.label,
					color: node.color ? node.color : node.style ? node.style.borderColor + "50" : null,
					txtColor: nodeTxtColor,
					position: node.position,
					fontSize: node.style.fontSize,
					image: node.image ? node.image : null,
				});
			});
			edges.forEach((edge: any) => {
				edgesToPush.push(edge);
			});
			if (reactFlowInstance) {
				if (this.state.onlyMap) {
					saveMapOnly(nodesToPush, edgesToPush, noClose);
				} else {
					let content = {
						nodes: nodesToPush,
						edges: edgesToPush,
					};
					mapsServices
						.updateSingleConceptualMap(this.state.mapId, this.state.bearerToken, this.state.sessionId, content)
						.then(() => {
							console.log(this.state.session)
							if (noClose)
								if(this.state.session)
									this.props.history.push({
										pathname: "/session-details",
										state: { argument: this.props.history.location.state.session },
									});
								else
									this.props.history.push({
										pathname: "/sessions",
									});
						});
				}
			} else {
				if (this.state.onlyMap) {
					saveMapOnly(nodesToPush, edgesToPush, noClose);
				}
			}
			// clearInterval(intervalSave);
		};
		const saveMapOnly = async (nodesToPush: any[], edgesToPush: any[], noClose: boolean) => {
			// await updateDoc(doc(firestore, `m/${this.state.documentId}/`), {
			// 	d: {
			// 		n: nodesToPush,
			// 		e: edgesToPush,
			// 	},
			// 	f: null,
			// })
			// 	.then((response) => {
			// 		if (this.state.documentId.startsWith('MAPSPDF') && noClose)
			// 			// this.props.history.push(`/session-details/`);
			// 			this.props.history.goBack()
			// 		else
			// 			if (noClose) this.props.history.push(`/files/`);
			// 	})
			// 	.catch((e) => {
			// 		// console.error("[MindMap] error setting doc:", e);
			// 	});
			let content = {
				nodes: nodesToPush,
				edges: edgesToPush,
			};
			mapsServices.updateSingleConceptualMap(
				this.state.mapId,
				this.state.bearerToken,
				this.props.history.location.state.session,
				content
			);
		};

		const onAdd = useCallback(
			(sourceNode: any) => {
				// console.log("SOURCE NODE: ", sourceNode);
				// console.log("ALL NODES: ", this.state.rfInstance.getNodes());
				// console.log("ALL EDGES: ", this.state.rfInstance.getEdges());

				// let currentNode = nodes.filter(node => node.id === this.state.selectedNodeId)[0]
				let currentNode = sourceNode;
				// let nodesArr = nodes;

				let newNode: any;
				if (currentNode.id === "A") {
					// console.log("FOUND MAIN NODE");

					//connect a new node to the origin _> create a new section
					let sectionNumber = this.state.rfInstance
						.getNodes()
						.filter((node: any) => node.id.length === 1 && node.id.charCodeAt(0) >= 48 && node.id.charCodeAt(0) <= 57).length;
					// console.log("number: ", sectionNumber);

					newNode = {
						id: String(sectionNumber + 1),
						type: "default",
						style: {
							borderWidth: currentNode.style.borderWidth === "#000000" ? "" : "7px",
							borderColor: currentNode.style.borderColor === "#000000" ? "#000000" : currentNode.style.borderColor,
							backgroundColor:
								currentNode.style.backgroundColor === "#000000" ? currentNode.style.backgroundColor?.slice(0, -2) : currentNode.style.backgroundColor,
							borderRadius: "20px",
							color: currentNode.style.color || "#000000",
							fontSize: this.state.fontSettings?.font_size,
							textTransform: this.props.isUppercase ? "none" : "uppercase",
						},
						position: {
							x: currentNode.position.x,
							y: currentNode.position.y + 200,
						},
						data: { label: "Nuova Sezione" },
					};
				} else if (currentNode.id !== "A" && currentNode.id.length === 1 && currentNode.id.charCodeAt(0) >= 48 && currentNode.id.charCodeAt(0) <= 57) {
					let childNodes = this.state.rfInstance.getNodes().filter((node: any) => node.id.length === 2 && node.id[0] === currentNode.id);
					let nextLetter = (childNodes.length + 10).toString(36);
					newNode = {
						id: `${currentNode.id}${nextLetter}`,
						type: "default",
						style: {
							borderWidth: currentNode.style.borderWidth === "#000000" ? "" : "7px",
							borderColor: currentNode.style.borderColor === "#000000" ? "#000000" : currentNode.style.borderColor,
							backgroundColor:
								currentNode.style.backgroundColor === "#000000" ? currentNode.style.backgroundColor?.slice(0, -2) : currentNode.style.backgroundColor,
							borderRadius: "20px",
							color: currentNode.style.color || "#000000",
							fontSize: currentNode.style.fontSize ? currentNode.style.fontSize : this.state.fontSettings?.font_size,
							textTransform: this.props.isUppercase ? "none" : "uppercase",
						},
						position: {
							x: currentNode.position.x,
							y: currentNode.position.y + 200,
						},
						data: { label: "Nodo" },
					};
					console.log("Sono in questo caso: ", currentNode);
				} else if (currentNode.id.length === 2) {
					let childNodes = this.state.rfInstance
						.getNodes()
						.filter((node: any) => node.id.length === 3 && node.id[0] === currentNode.id[0] && node.id[1] === currentNode.id[1]);
					// console.log(childNodes);
					newNode = {
						id: `${currentNode.id[0]}${currentNode.id[1]}${childNodes.length + 1}`,
						type: "default",
						style: {
							borderWidth: currentNode.style.borderWidth === "#000000" ? "" : "7px",
							borderColor: currentNode.style.borderColor === "#000000" ? "#000000" : currentNode.style.borderColor,
							backgroundColor:
								currentNode.style.backgroundColor === "#000000" ? currentNode.style.backgroundColor?.slice(0, -2) : currentNode.style.backgroundColor,
							borderRadius: "20px",
							color: currentNode.style.color || "#000000",

							fontSize: currentNode.style.fontSize ? currentNode.style.fontSize : this.state.fontSettings?.font_size,
							textTransform: this.props.isUppercase ? "none" : "uppercase",
						},
						position: {
							x: currentNode.position.x,
							y: currentNode.position.y + 200,
						},
						data: { label: "Nodo" },
					};
					console.log("Sono quaaa: ", currentNode.style.textTransform);

					// console.log(currentNode.id[0])
					// console.log(currentNode.id[1])
					// console.log(newNode);
				} else {
					let newNodeId = this.generateNodeId();

					newNode = {
						id: newNodeId,
						type: "default",
						style: {
							borderWidth: currentNode.style.borderWidth === "#000000" ? "" : "7px",
							borderColor: currentNode.style.borderColor === "#000000" ? "#000000" : currentNode.style.borderColor,
							backgroundColor:
								currentNode.style.backgroundColor === "#000000" ? currentNode.style.backgroundColor?.slice(0, -2) : currentNode.style.backgroundColor,
							borderRadius: "20px",
							color: currentNode.style.color || "#000000",

							fontSize: currentNode.style.fontSize ? currentNode.style.fontSize : this.state.fontSettings?.font_size,
							textTransform: this.props.isUppercase ? "none" : "uppercase",
						},
						position: {
							x: currentNode.position.x,
							y: currentNode.position.y + 200,
						},
						data: { label: "Nodo" },
					};
					console.log("Oppure quaaa: ", currentNode.style.textTransform);
				}
				const newEdge: Edge = {
					id: `${currentNode.id}-${newNode.id}`,
					source: currentNode.id,
					target: newNode.id,
					type: this.state.edgeType,
					zIndex: 0,
				};
				// console.log("NODE TO ADD ", newNode);

				// console.log("EDGE TO ADD: ", newEdge);
				// nodesArr.push(newNode)

				// setNodes(nodesArr)
				// //console.log("Node To Add: ", newNode);
				this.state.rfInstance.addNodes(newNode);

				// alert(this.props.fillNodeColors)
				setTimeout(() => {
					// console.log(document.querySelector(`[data-id="${newNode.id}"]`))
					let node = document.querySelector(`[data-id="${newNode.id}"]`) as HTMLElement;
					if (this.props.fillNodeColors) {
						node.style.backgroundColor = currentNode.style.backgroundColor;
					} else {
						node.style.backgroundColor = "var(--bg-conetent-color)";
					}
				}, 300);
				setEdges((eds: any) => {
					const newEdges = addEdge(newEdge, eds);
					setTimeout(() => saveState([...nodes, newNode], newEdges), 0);
					return newEdges;
				});
				//_reactFlowInstance.addEdges(edge)

				/*  const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
					 nodes.concat(newNode),
					 addEdge(newEdge, edges),
					 this.state.direction
				 ); */

				/*  setNodes([...layoutedNodes]);
				 setEdges([...layoutedEdges]); */
			},

			[setNodes, setEdges, nodes, edges, saveState]
		);
		const getNodeId = () => `randomnode_${+new Date()}`;
		const onAddNew = useCallback(() => {
			const newNode = {
				id: getNodeId(),
				data: { label: this.state.docTitle ? this.state.docTitle : "Nodo" },
				style: {
					borderWidth: "7px",
					borderColor: "#3626a7",
					borderRadius: "20px",
					color: "#000",
					backgroundColor: "var(bg-content-color)",
					fontSize: this.state.fontSettings?.font_size,
					textTransform: this.props.isUppercase ? "none" : "uppercase", //naming is inverted
				},
				position: {
					x: window.innerWidth / 2 + 3000,
					y: window.innerHeight / 2 - 360,
				},
				image: null,
			};
			const adjustmentx = window.innerWidth * 0.5;
			const adjustmenty = window.innerHeight * 0.5;

			setNodes((nds: any) => nds.concat(newNode));
			this.state.rfInstance.setCenter(newNode.position.x - adjustmentx, newNode.position.y - adjustmenty, { duration: 500, zoom: 1 });
		}, [setNodes]);
		const onNodeDoubleClick = (event: any, node: any) => {
			// this.setState({ showNameChangePopup: true, selectedNodeId: (event.target as any).getAttribute('data-id'), newNodeName: node.data.label })
		};

		const uploadCustomImage = (media: File | null | undefined, alreadyExists: boolean) => {
			if (media && this.state.userUuid && media.type.includes("image/")) {
				let currentNodeText = nodes
					.filter((node: any) => node.id === this.state.selectedNodeId)[0]
					.data.label.toLowerCase()
					.trim();
				uploadBytes(storageRef(storage, `i/${this.state.userUuid}/${currentNodeText.toLowerCase().trim()}/`), media)
					.then((data) => {
						//console.log("[Utils] upload success:", data.ref);
						this.customImageFromGSUrltoUrl(data.ref.fullPath).then(async (url) => {
							if (!alreadyExists) {
								// currentStep.imageGsUrl = url
								console.log("NEW URL PUBLIC: ", url);

								// task.steps[task.steps.indexOf(this.state.currentStep)].imageGsUrl = url
								await setDoc(doc(firestore, `i/${this.state.userUuid}/i/${makeId(16)}`), {
									w: currentNodeText,
									i: data.metadata.fullPath,
								})
									.then((response) => {
										console.log("[MindMap] setting custom image doc response:", response);
										let imageSearchResponse = this.state.imageSearchResponse;
										imageSearchResponse.push(url);
										this.setState({ imageSearchResponse: imageSearchResponse });
										// (nodes.filter(node => node.id === this.state.selectedNodeId)[0] as any).image = url
										// setNodes(nodes)
									})
									.catch((e) => {
										console.error("[MindMap] error setting custom image doc:", e);
									});
							}
							// else {
							//     if (this.state.originalMedia.url === '') {
							//         //console.log("setting original media to ", currentStep.imageGsUrl);
							//         if (this.state.currentStep.imageGsUrl !== null) {
							//             this.setState({
							//                 originalMedia: {
							//                     type: 'image', url: currentStep.imageGsUrl
							//                 }
							//             })
							//         }
							//         else {
							//             this.setState({
							//                 originalMedia: {
							//                     type: 'video', url: currentStep.videoGsUrl
							//                 }
							//             })
							//         }
							//     }
							//     currentStep.videoGsUrl = null;
							//     this.state.uploadedMediaIds.push(data.ref.name)
							//     currentStep.imageGsUrl = url
							//     task.steps[task.steps.indexOf(this.state.currentStep)].imageGsUrl = url
							//     this.setState({ task: task, currentStep: currentStep })
							// }
						});
					})
					.catch((err) => {
						console.log("[MindMap] error uploading custom image", err);
					});
			}
		};

		const onDragOver = useCallback((event: any) => {
			event.preventDefault();
			event.dataTransfer.dropEffect = "move";
		}, []);

		const generateNodeTTS = (node: any) => {
			console.log("rere");

			this.setState({ disableTtsButton: true });
			console.log(node);
			settingsServices.getUserSettings(this.state.userUuid, this.state.bearerToken).then((ttsSettings) => {
				let elArr: any[] = [];
				nodes.forEach((node: any) => {
					elArr.push({ text: node.data.label });
				});
				let elements = elArr.slice(0, 10);
				getTTS(elements, node.data.label, ttsSettings).then((response) => {
					this.setState({
						audioToPlay: response.audio,
						speechMarks: response.speech_marks,
					});
					let audio = document.getElementById("mapAudioElement") as HTMLAudioElement;
					audio.src = response.audio;
					audio.play();
				});
			});
		};
		const onNodeClick = (event: any, node: any) => {
			// if (event.target.id === 'actionsDiv')
			//     return
			// if (event.target.id === 'mapTtsButton') {
			//     //handle on click here to include the mobile touch event too
			//     console.log("rere")

			//     if (isMobile)
			//         generateNodeTTS(node)
			//     return
			// }
			if (this.state.connectingMode /* && isMobile */) {
				let nodesToConnect = this.state.selectedNodesToConnect;
				if (nodesToConnect.length === 2) {
					console.log("3");
					//CREATE CONNECTION
					let newEdge: Edge = {
						id: this.getEdgeId(),
						source: nodesToConnect[0].id,
						target: nodesToConnect[1].id,
						type: this.state.edgeType,
					};
					setEdges((eds: any) => addEdge(newEdge, eds));
					this.setState({ selectedNodesToConnect: [] });
				} else if (nodesToConnect.length === 1) {
					console.log("2");
					//push then connect and restore array to zero
					if (nodesToConnect[0].id === node.id) return;

					nodesToConnect.push(node);

					let newEdge: Edge = {
						id: this.getEdgeId(),
						source: nodesToConnect[0].id,
						target: nodesToConnect[1].id,
						type: this.state.edgeType,
					};
					this.setState({ showEdgeCreatePopover: true, tempEdgeParams: newEdge });
					// setEdges((eds) => addEdge(newEdge, eds))
					this.setState({ selectedNodesToConnect: [], connectingMode: false });
				} else {
					console.log("1");

					nodesToConnect.push(node);
					this.setState({ selectedNodesToConnect: nodesToConnect });
				}
			} else {
				if (node.image && node.image !== "") this.setState({ selectedNodeHasImage: true });
				this.setState({ isNodeSelected: true, popoverEvent: event });
				let actionsDiv = document.getElementById("actionsDiv");
				if (isMobile && this.state.modeEditing === "main" && actionsDiv) {
					actionsDiv.classList.add("visible"); // Add the 'visible' class to show the div
					actionsDiv.hidden = false;
					actionsDiv.style.position = "absolute";
					actionsDiv.style.top = node.height + "px";
					event.target.append(actionsDiv);
					clearTimeout(hideTimeout);
					clearTimeout(animationTimeout);
					actionsDiv.classList.remove("hidden"); // Ensure the 'hidden' class is removed
				}
			}
			this.setState({ selectedNodeId: (event.target as any).getAttribute("data-id"), selectedEdgeId: "" });
			saveAndExit(false);
		};
		const onEdgeClick = (event: any, edge: Edge) => {
			this.setState({ selectedEdgeId: edge.id, selectedNodeId: "" });
			saveAndExit(false);
		};
		const onPaneClick = (event: any) => {
			this.setState({ selectedNodeId: "", selectedEdgeId: "" });
			let actionsDiv = document.getElementById("actionsDiv");
			if (isMobile && this.state.modeEditing === "main" && actionsDiv) {
				actionsDiv!.classList.remove("visible"); // Ensure the 'visible' class is removed
				actionsDiv!.classList.add("hidden"); // Add the 'hidden' class to trigger the slideToTop animation
			}
			saveAndExit(false);
		};

		const onEdgeUpdate = (oldEdge: any, newConnection: any) => setEdges((els: any) => updateEdge(oldEdge, newConnection, els));
		let hideTimeout: any;
		let animationTimeout: any;
		let hoveredNodeId: string = "";
		const onNodeHoverStart = (event: any, node: any) => {
			let actionsDiv = document.getElementById("actionsDiv");
			if (hoveredNodeId === "") {
				hoveredNodeId = node.id;
				this.setState({ hoveredId: hoveredNodeId });
			}
			if (node.id !== this.state.hoveredId && actionsDiv) {
				clearTimeout(hideTimeout);
				clearTimeout(animationTimeout);
				hoveredNodeId = node.id;
				actionsDiv!.classList.remove("visible"); // Ensure the 'visible' class is removed
				actionsDiv!.classList.add("hidden"); // Add the 'hidden' class to trigger the slideToTop animation
				actionsDiv!.hidden = true;
			}
			if (this.state.modeEditing === "main" && actionsDiv) {
				if (!actionsDiv.classList.contains("visible")) {
					actionsDiv.classList.add("visible"); // Add the 'visible' class to show the div
					actionsDiv.hidden = false;
					actionsDiv.style.position = "absolute";
					actionsDiv.style.top = node.height + "px";
					event.target.append(actionsDiv);
					clearTimeout(hideTimeout);
					clearTimeout(animationTimeout);
					actionsDiv.classList.remove("hidden"); // Ensure the 'hidden' class is removed
				} else {
					clearTimeout(hideTimeout);
					clearTimeout(animationTimeout);
					actionsDiv.classList.remove("hidden"); // Ensure the 'hidden' class is removed
				}
			}
		};
		const onNodeHoverEnd = (event: any) => {
			let actionsDiv = document.getElementById("actionsDiv");
			if (this.state.connectingMode) return;
			if (actionsDiv) {
				hideTimeout = setTimeout(() => {
					actionsDiv!.classList.remove("visible"); // Ensure the 'visible' class is removed
					actionsDiv!.classList.add("hidden"); // Add the 'hidden' class to trigger the slideToTop animation
					//timeout to wait for the animation to complete
					animationTimeout = setTimeout(() => {
						actionsDiv!.hidden = true;
						document.getElementsByClassName("ion-page")[0].appendChild(actionsDiv!);
					}, 500);
				}, 750); // Adjust the delay as needed
			}
		};

		const onNodeContextMenu = (event: any, node: any) => {};

		const onEdgeDoubleClick = (event: any, edge: Edge) => {
			if (this.state.modeEditing !== "editing") return;
			else {
				let selectedEdge = edges.filter((filteredEdge: any) => filteredEdge.id === edge.id)[0];
				this.setState({ showEdgeLabelPopover: true, selectedEdgeId: edge.id, edgeLabel: selectedEdge.label as any });
			}
		};

		const onNodeDragStop = (event: any) => {
			//FIXES EDGE FONT SIZE RESET ON NODE DRAG
			let edgesTextEls = document.getElementsByClassName("react-flow__edge-text");
			for (let i = 0; i < edgesTextEls.length; i++) {
				(edgesTextEls[i] as HTMLElement).style.fontSize = this.state.fontSettings?.font_size ? this.state.fontSettings?.font_size.toString() : "20";
			}
		};

		const onNodesDelete = (event: any) => {
			this.setState({ selectedNodeId: "" });
		};
		const zoomMaps = () => {
			// let x,y
			const xPercentageOffset = 0.1; // Example: 10% offset for X
			const yPercentageOffset = 0.1; // Example: 10% offset for Y
			const viewportWidth = window.innerWidth;
			const viewportHeight = window.innerHeight;

			const xOffset = viewportWidth * xPercentageOffset;
			const yOffset = viewportHeight * yPercentageOffset;
			let nodeToZoom = nodes.filter((node: any) => node.id === this.state.selectedNodeId)[0];
			reactFlowInstance.setCenter(nodeToZoom.position.x - xOffset - 250, nodeToZoom.position.y - yOffset - 250, { duration: 800, zoom: 1 });
		};

		const toggleImagesCaptions = () => {
			this.props.toggleImagesCaptions();
			let nodesWithImage = nodes.filter((node: any) => node.image != null);
			let nodesWithImageIds = nodesWithImage.map((node: any) => node.id);
			console.log(nodesWithImageIds);
			for (let i = 0; i < document.getElementsByClassName("react-flow__node").length; i++) {
				let nodeElement = document.getElementsByClassName("react-flow__node")[i] as HTMLElement;
				let imgElement = nodeElement.querySelector("img");
				if (nodesWithImageIds.includes(nodeElement.getAttribute("data-id"))) {
					if (!this.props.showImagesCaptions === true)
						nodeElement.style.fontSize = this.state.fontSettings?.font_size ? this.state.fontSettings?.font_size + "px" : 10 + "px";
					else nodeElement.style.fontSize = "0px";
				}
			}
		};

		return (
			<IonPage
				onClick={(e) => {
					// if ((e.target as HTMLElement).classList[0] === "ion-page") {
					// 	(document.getElementById("sideMenu") as HTMLIonMenuElement).close(true);
					// }
				}}
			>
				<Joyride
					steps={this.state.modifyNodeTutorial ? this.state.stepsModifyNode : this.state.steps}
					run={this.state.tutorial} // Set to true to start the tour automatically
					continuous={true} // Allow continuous navigation through the tour
					showProgress={true} // Display progress indicator
					showSkipButton={true} // Display skip button
					spotlightClicks={true}
					callback={(data) => handleJoyrideCallback.call(this, data, "Maps")}
					hideBackButton={false}
					disableOverlayClose={true}
					hideCloseButton={true}
					locale={{
						skip: (
							<div
								className="skip"
								onClick={() => {
									updateChoice.call(this, this.state.modifyNodeTutorial ? "tutorialModifyNodes" : "tutorialMindMap");
								}}
							>
								<Trans>Salta</Trans>
							</div>
						),
						back: (
							<div className="nextTutorialBtn">
								<Trans>Precedente</Trans>
							</div>
						),
						next: (
							<span>
								<Trans>Avanti</Trans>
							</span>
						),
						last: (
							<div
								className="nextTutorialBtn"
								onClick={() => {
									updateChoice.call(this, this.state.modifyNodeTutorial ? "tutorialModifyNodes" : "tutorialMindMap");
								}}
							>
								<Trans>Ultimo</Trans>
							</div>
						),
					}}
					styles={{
						buttonNext: {
							fontSize: "12px",
							outline: "none",
						},
					}}
				/>
				<IonIcon
					id="homeHelpIcon"
					className="helpIcon"
					style={{ fontSize: "35px", bottom: "0px", top: "unset" }}
					onClick={() => {
						const element = document.getElementsByClassName("react-flow__node")[0] as HTMLDivElement;
						if (element) {
							let attribute = element.getAttribute("data-id") || "";
							this.setState({ selectedNodeId: attribute });
						}
						if (this.state.modeEditing === "editing") {
							let oneNode = document.getElementsByClassName("react-flow__node")[0];
							let nodeId = oneNode.getAttribute("data-id");
							console.log(oneNode.getAttribute("data-id"));
							this.setState({ isNodeSelected: true, selectedNodeId: nodeId ? nodeId : "" }, () => {
								setTimeout(() => {
									this.setState({ modifyNodeTutorial: true, tutorial: true }, () => {
										console.log("wewe");

										sendEvent({
											"user_id": this.state.userUuid,
											"event_type": "Tutorial opened",
											"event_properties": {
												"page": "Maps",
											},
											"language": i18n.language,
											"platform": isPlatform("ios") ? "ios" : isPlatform("android") ? "android" : "desktop",
											"app_version": appVersion,
											"time": Date.now(),
										});
									});
								}, 100);
							});
						} else {
							this.setState({ modifyNodeTutorial: false }, () => {
								this.setState({ tutorial: true }, () => {
									sendEvent({
										"user_id": this.state.userUuid,
										"event_type": "Tutorial opened",
										"event_properties": {
											"page": "Maps",
										},
										"language": i18n.language,
										"platform": isPlatform("ios") ? "ios" : isPlatform("android") ? "android" : "desktop",
										"app_version": appVersion,
										"time": Date.now(),
									});
								});
							});
						}

						this.setState({ tutorial: true }, () => {
							console.log("wewe");

							// sendEvent({
							//     "user_id": this.state.userUuid,
							//     "event_type": "Tutorial opened",
							//     "event_properties": {
							//         "page": "Maps"
							//     },
							//     "language": i18n.language,
							//     "platform": isPlatform('ios') ? 'ios' : isPlatform('android') ? 'android' : 'desktop',
							//     "app_version": appVersion,
							//     "time": Date.now()
							// })
						});
					}}
					icon={helpCircleOutline}
				></IonIcon>

				<IonHeader id="main-content" style={{ position: "absolute" }}>
					<IonToolbar id="mapsToolbarSave">
						<div className="MapsToolbar">
							{!isMobile && (
								<>
									<div className="mapsSaveAndExit" hidden={isMobile && this.state.modeEditing === "editing"}>
										<IonButtons slot="start" className="mapBackBtn">
											<IonButton
												disabled={this.state.disableExitButton}
												onClick={async () => {
													saveAndExit(true);
												}}
											>
												<IonIcon icon={arrowBackOutline} />
											</IonButton>
										</IonButtons>
									</div>
								</>
							)}
							<IonButton
								slot="start"
								hidden={!isMobile || this.state.modeEditing === "main"}
								className="Button"
								color="white"
								onClick={() => {
									this.setState({ modeEditing: "main", isModalMenuOpen: false, isDropDownMenuMobileOpen: false, isNodeSelected: false, selectedNodeId: "" });
								}}
							>
								<IonIcon icon={chevronBack} />
								<Trans>Fatto</Trans>
							</IonButton>
							<IonTitle hidden={isMobile && this.state.modeEditing === "main"} className="mapTitleSection">
								{this.state.modeEditing === "main" ? this.state.docTitle ? this.state.docTitle : "" : <Trans>Modifica</Trans>}
							</IonTitle>
							{!isMobile && (
								<div className="desktopHeaderButtonsDiv">
									<IonButton
									className="undoBtn"
												onClick={() => {
													undo(setNodes, setEdges);
												}}
												disabled={!canUndo}
											>
												<IonIcon className="undoBtnIcon" icon={arrowUndo} />
											</IonButton>
											<IonButton
											className="redoBtn" onClick={() => redo(setNodes, setEdges)} disabled={!canRedo}>
												<IonIcon className="redoBtnIcon" icon={arrowRedo} />
											</IonButton>
											<div className="headerSeparator" />

									<IonIcon
										className="headerMapButtons"
										style={{ cursor: "pointer", marginRight: "4px" }}
										onClick={() => {
											toggleImagesCaptions();
										}}
										icon={this.props.showImagesCaptions ? eyeOutline : eyeOffOutline}
									/>
									<IonIcon
										onClick={() => {
											this.props.toggleColorFill();
											for (let i = 0; i < document.getElementsByClassName("react-flow__node").length; i++) {
												let mapBackground = document.getElementsByClassName("reactflow-wrapper")[0] as HTMLElement;
												const backgroundColor = getComputedStyle(mapBackground).backgroundColor;
												let nodeBackground = document.getElementsByClassName("react-flow__node-default")[0] as HTMLElement;
												const nodeBackgroundColor = getComputedStyle(nodeBackground);
												if (this.props.fillNodeColors) {
													const node = document.getElementsByClassName("react-flow__node")[i] as HTMLElement;
													node.style.backgroundColor = "var(--bg-content-color)";
													if (isBackgroundColorDark(backgroundColor)) {
														node.style.color = "#000";
													} else {
														node.style.color = "#fff";
														if (hasBorderColor(nodeBackgroundColor)) {
															node.style.color = "#000";
														} else {
															node.style.color = "#fff";
														}
													}
												} else {
													const node = document.getElementsByClassName("react-flow__node")[i] as HTMLElement;
													let nodeColor = nodes[i].style!.borderColor! == undefined ? "#bcbcbc" : nodes[i].style!.borderColor! + 50;
													node.style.backgroundColor = nodeColor;
													// node.style.color = "#fff";
													if (isBackgroundColorDark(backgroundColor)) {
														node.style.color = "#fff";
														if (hasBorderColor(nodeBackgroundColor)) {
															node.style.color = "#fff";
														} else {
															node.style.color = "#000";
														}
													} else {
														node.style.color = "#000";
													}
												}
											}
											saveAndExit(false);
										}}
										className="headerMapButtons"
										style={{ cursor: "pointer", marginLeft: "4px", opacity: this.props.fillNodeColors ? 1 : 0.5 }}
										icon={nodeFill}
									/>
									<div className="headerSeparator" />
									<IonIcon
										className="headerMapButtons"
										style={{ cursor: "pointer", marginRight: "4px" }}
										onClick={() => {
											this.setState({ connectingMode: !this.state.connectingMode, selectedNodesToConnect: [] });
											// (document.getElementById("sideMenu") as HTMLIonMenuElement).close();
										}}
										icon={gitBranchOutline}
									/>
									<IonIcon
										className="headerMapButtons"
										style={{ cursor: "pointer", marginLeft: "4px" }}
										onClick={(e) => {
											this.setState({ showEdgeTypePopover: true, popoverEvent: e });
										}}
										icon={TypeLink}
									/>
									<div className="headerSeparator" />
									{/* TEMPORARY HIDDEN */}
									<div className="fontSizeChangeDiv">
										<IonIcon
											className="fontChangeIcon"
											icon={removeOutline}
											onClick={() => {
												let newFontSize = this.state.fontSettings?.font_size ? this.state.fontSettings?.font_size - 1 : 16 - 1;
												updateFontSize(newFontSize);
												this.setState({ initialFontSize: newFontSize });
											}}
										/>
										<IonInput
											className="fontSizeInput"
											type="number"
											max={110}
											onIonChange={(e) => {
												if (Number(e.detail.value) > 110 || Number(e.detail.value) < 10) return;
												let newFontSize = Number(e.detail.value);
												updateFontSize(newFontSize);
												this.setState({ initialFontSize: newFontSize });
											}}
											debounce={500}
											value={this.state.initialFontSize ? this.state.initialFontSize : 0}
										/>
										<IonIcon
											className="fontChangeIcon"
											icon={addOutline}
											onClick={() => {
												let newFontSize = this.state.fontSettings?.font_size ? this.state.fontSettings?.font_size + 1 : 16 + 1;
												updateFontSize(newFontSize);
												this.setState({ initialFontSize: newFontSize });
											}}
										/>
										{/* <IonIcon style={{ fontSize: "32px" }} icon={textOutline} /> */}
									</div>
									<div className="headerSeparator" />
									<IonIcon
										className="headerMapButtons"
										icon={textOutline}
										style={{ cursor: "pointer" }}
										onClick={() => {
											this.props.toggleUppercase();
											for (let i = 0; i < document.getElementsByClassName("react-flow__edge").length; i++) {
												const edge = document.getElementsByClassName("react-flow__edge")[i] as HTMLElement;
												if (!this.props.isUppercase) {
													edge.style.textTransform = "none";
												} else {
													edge.style.textTransform = "uppercase";
												}
											}
											for (let i = 0; i < document.getElementsByClassName("react-flow__node").length; i++) {
												const node = document.getElementsByClassName("react-flow__node")[i] as HTMLElement;
												if (!this.props.isUppercase) {
													node.style.textTransform = "none";
												} else {
													node.style.textTransform = "uppercase";
												}
											}
										}}
									></IonIcon>
									<div className="headerSeparator" />
									<IonIcon
										style={{ cursor: "pointer", fontSize: "32px" }}
										className="headerMapButtons"
										onClick={(e) => this.setState({ showLayoutOptions: true, popoverEvent: e })}
										icon={getDarkMode() ? mindMapIconDark : mindMapIconLight}
									/>
								</div>
							)}
							<IonPopover
								isOpen={this.state.showLayoutOptions}
								onDidDismiss={() => this.setState({ showLayoutOptions: false })}
								showBackdrop={false}
								event={this.state.popoverEvent}
								className="layoutOptionsPopover"
							>
								<div className="layoutOptionsDiv">
									<p className="layoutOptionsHeader">
										<Trans>Cambia layout</Trans>
									</p>
									<div className="layoutOptionsInnerDiv">
										<div className="layoutOption" onClick={() => autoLayout("radial", "RADIAL")}>
											<IonIcon className="layoutIcon" icon={getDarkMode() ? mindMapIconDark : mindMapIconLight} />
											<IonLabel>
												<Trans>Mappa mentale</Trans>
											</IonLabel>{" "}
											{/* TO LOCALIZE THIS */}
										</div>
										<div className="layoutOption" onClick={() => autoLayout("mrtree", "DOWN")}>
											<IonIcon className="layoutIcon" icon={getDarkMode() ? topDownTreeDark : topDownTreeLight} />
											<IonLabel>
												<Trans>Verticale</Trans>
											</IonLabel>{" "}
											{/* TO LOCALIZE THIS */}
										</div>
										<div className="layoutOption" onClick={() => autoLayout("mrtree", "RIGHT")}>
											<IonIcon className="layoutIcon" icon={getDarkMode() ? leftRightTreeDark : leftRightTreeLight} />
											<IonLabel>
												<Trans>Orizzontale</Trans>
											</IonLabel>{" "}
											{/* TO LOCALIZE THIS */}
										</div>
									</div>
								</div>
							</IonPopover>
							<IonPopover
								className="edgeTypeSelectorPopover"
								isOpen={this.state.showEdgeTypePopover}
								onDidDismiss={() => this.setState({ showEdgeTypePopover: false })}
								event={this.state.popoverEvent}
								showBackdrop={false}
							>
								<p>
									<Trans>Tipo di collegamento</Trans>
								</p>
								<div className="edgeTypeSelectorDiv">
									<div
										onClick={() => {
											if (this.state.edgeType === "default") return;
											changeEdgeType("default");
										}}
										className="edgeTypeSelectorOption"
										style={this.state.edgeType === "default" ? { opacity: 1 } : { opacity: 0.5, cursor: "pointer" }}
									>
										<p>Bezier</p>
										<IonIcon size="large" icon={getDarkMode() ? defaultConnectionDark : defaultConnectionLight} />
									</div>
									<div
										onClick={() => {
											if (this.state.edgeType === "smoothstep") return;
											changeEdgeType("smoothstep");
										}}
										className="edgeTypeSelectorOption"
										style={this.state.edgeType === "smoothstep" ? { opacity: 1 } : { opacity: 0.5, cursor: "pointer" }}
									>
										<p>Smooth</p>
										<IonIcon size="large" icon={getDarkMode() ? smoothConnectionDark : smoothConnectionLight} />
									</div>
									<div
										onClick={() => {
											if (this.state.edgeType === "floating") return;
											changeEdgeType("floating");
										}}
										className="edgeTypeSelectorOption"
										style={this.state.edgeType === "floating" ? { opacity: 1 } : { opacity: 0.5, cursor: "pointer" }}
									>
										<p>Floating</p>
										<IonIcon size="large" icon={getDarkMode() ? floatingConnectionDark : floatingConnectionLight} />
									</div>
								</div>
							</IonPopover>
							{isMobile && this.state.modeEditing === "main" && (
								<>
									<IonButton
										disabled={this.state.disableExitButton}
										fill="clear"
										style={{ textTransform: "none" }}
										onClick={async () => {
											saveAndExit(true);
										}}
									>
										<IonIcon icon={arrowBackOutline} />
									</IonButton>
									<IonButton className="undoBtn" onClick={() => undo(setNodes, setEdges)} disabled={!canUndo}>
										<IonIcon className="undoBtnIcon" icon={arrowUndo} />
									</IonButton>
									<IonButton className="redoBtn" onClick={() => redo(setNodes, setEdges)} disabled={!canRedo}>
										<IonIcon className="redoBtnIcon" icon={arrowRedo} />
									</IonButton>
								</>
							)}
							{isMobile && this.state.modeEditing === "main" && (
								<div id="mapsModeEditingMobile">
									<div className="zoomInModeEditing">
										<IonButton
											id="zoomInMobile"
											className="Button"
											color="white"
											style={{ position: " relative" }}
											onClick={() => {
												reactFlowInstance.fitView({ duration: 1000 });
											}}
										>
											<IonIcon icon={ZoomInMaps} size="medium" />
										</IonButton>
										<IonButton
											id="addNodeMobile"
											className="Button"
											color="white"
											style={{ position: " relative" }}
											onClick={() => {
												onAddNew();
											}}
										>
											<IonIcon icon={addOutline} size="medium" />
										</IonButton>

										<IonButton
											id="zoomNodeMobile"
											hidden={this.state.selectedNodeId === ""}
											className="Button"
											color="white"
											onClick={() => {
												zoomMaps();
											}}
										>
											<IonIcon icon={centerElement} size="medium" />
										</IonButton>

										<IonButton
											id="modifyMapMobile"
											className="Button"
											color="white"
											onClick={() => {
												let actionsDiv = document.getElementById("actionsDiv");
												if (actionsDiv) {
													actionsDiv.classList.add("hidden");
													actionsDiv.classList.remove("visible");
													setTimeout(() => {
														actionsDiv!.hidden = true;
														this.setState({ modeEditing: "editing" });
													}, 150);
												}
											}}
										>
											<IonIcon icon={pencilEdit} size="medium" />
										</IonButton>
										<IonButton
											id="deleteMapMobile"
											className="Button"
											slot="end"
											onClick={(e) => {
												if (this.state.modeEditing === "main") {
													this.deleteMap();
												} else {
													e.persist();
													this.setState({ isDropDownMenuMobileOpen: true, popoverEvent: e });
												}
											}}
										>
											<IonLabel className="typeMaps">
												<IonIcon className="mobileIcon darkSensitive" src={trashBin} />
												{/* <IonIcon icon={this.state.modeEditing === 'main' ? trashBin : ellipsisVertical} /> */}
											</IonLabel>
										</IonButton>
									</div>

									{/* HIDDEN SINCE ITS DUPLICATED BELOW AND NOT USED,
                                        DELETE MAP BUTTON HAS BEEN PUT OUTSIDE OF THIS POPOVER
                                    */}
									{/*  <IonPopover
                                        isOpen={this.state.isDropDownMenuMobileOpen}
                                        onDidDismiss={() => this.setState({ isDropDownMenuMobileOpen: false, })}
                                        event={this.state.popoverEvent}
                                        showBackdrop={false}
                                        id="popoverContent"
                                    >
                                        <IonButton className='Button'
                                            onClick={() => {
                                                if (this.state.documentId) {
                                                    if (window.confirm(
                                                        i18n.language === 'it' ? "Vuoi eliminare la mappa?" :
                                                            i18n.language === 'es' ? "¿Quieres borrar el mapa?" :
                                                                i18n.language === 'fr' ? "Voulez-vous supprimer la carte?" :
                                                                    'Do you want to delete the map?') === true
                                                    ) {
                                                        deleteDoc(doc(firestore, `m/${this.state.documentId}`))
                                                        this.props.history.goBack()
                                                    }
                                                }
                                            }}>
                                            <IonLabel className='typeMaps'>
                                                <IonIcon icon={trash} size='medium' />
                                                <span style={{ margin: '10px' }}> ELIMINA MAPPA</span>
                                            </IonLabel>
                                        </IonButton>
                                        {/* HIDING THIS UNTIL WE HAVE THE FEATURE TO SHARE */
									/*}
                                        <IonButton hidden className='Button'>
                                            <IonIcon icon={shareOutline} size='medium' />
                                            <span style={{ margin: '10px' }}>CONDIVIDI MAPPA </span>
                                        </IonButton>
                                    </IonPopover> */}
								</div>
							)}
							{this.state.modeEditing === "editing" && (
								<>
									<IonPopover
										className="resolution"
										showBackdrop={false}
										event={this.state.popoverEvent}
										isOpen={this.state.isNodeSelected && this.state.selectedNodeId !== ""}
										onDidDismiss={() => {
											this.setState({ showContentPopover: false, popoverEvent: null, selectedNodeHasImage: false, isNodeSelected: false });
										}}
									>
										<IonList className="menuBtm-container">
											<IonButton
												id="modifyNode"
												className="menuBtn"
												hidden={this.state.selectedNodeId === ""}
												onClick={() => {
													if (this.state.selectedNodeId !== "") {
														let currentNode = nodes.filter((node: any) => node.id === this.state.selectedNodeId)[0];
														// console.log(currentNode);
														// console.log(currentNode.id);
														onAdd(currentNode);
														// this.setState({ selectedNodeId: '' })
													}
												}}
											>
												<IonIcon icon={addOutline} size="medium" />
											</IonButton>

											<IonButton
												id="add-image"
												className="menuBtn"
												onClick={async (e) => {
													if (this.state.selectedNodeHasImage) {
														for (let i = 0; i < document.getElementsByClassName("react-flow__node").length; i++) {
															if (document.getElementsByClassName("react-flow__node")[i].getAttribute("data-id") === this.state.selectedNodeId) {
																const image = document.getElementById(`image-${this.state.selectedNodeId}`);
																(nodes.filter((node: any) => node.id === this.state.selectedNodeId)[0] as any).image = null;
																if (image) {
																	image.remove();
																	saveAndExit(false);
																}
																(document.getElementsByClassName("react-flow__node")[i] as HTMLElement).style.fontSize =
																	this.state.fontSettings?.font_size + "px";
																this.setState({
																	showContentPopover: false,
																	isNodeSelected: false,
																	selectedNodeId: "",
																	selectedNodeHasImage: false,
																	wordPicture: "",
																});
															}
														}
													} else {
														this.setState({ isLoadingImages: true, popoverEvent: e });
														let currentNodeText = nodes
															.filter((node: any) => node.id === this.state.selectedNodeId)[0]
															.data.label.toLowerCase()
															.trim();
														const q = query(collection(firestore, `d/${auth.currentUser?.uid}/d`), where("w", "==", currentNodeText.trim()));
														const customImageQuery = query(collection(firestore, `i/${auth.currentUser?.uid}/i/`), where("w", "==", currentNodeText));
														imagesSearch(currentNodeText.split(" ").slice(0, 5).join(" "))
															.then(async (images: any) => {
																this.setState({ showImagesPopover: true, imageSearchResponse: images, isLoadingImages: false }, async () => {
																	const querySnapshot = await getDocs(q);
																	const customImageQuerySnapshot = await getDocs(customImageQuery);
																	let tempImagesArray = this.state.imageSearchResponse;
																	if (!querySnapshot.empty)
																		querySnapshot.forEach(async (doc: any) => {
																			await this.fromGSUrltoUrl(doc.data().p).then((url: string) => {
																				tempImagesArray.push(url);
																				this.setState({ imageSearchResponse: tempImagesArray, wordPicture: url });
																			});
																		});
																	else {
																	}
																	if (!customImageQuerySnapshot.empty) {
																		customImageQuerySnapshot.forEach(async (doc: any) => {
																			await this.customImageFromGSUrltoUrl(doc.data().i).then((url: string) => {
																				console.log("URL: ", url);
																				tempImagesArray.push(url);
																				this.setState({ imageSearchResponse: tempImagesArray });
																			});
																		});
																	}
																	console.log("FINAL ARRAY: ", tempImagesArray);
																});
															})
															.catch();
													}
												}}
											>
												<IonIcon icon={imageOutline} size="medium" />
											</IonButton>
											<div id="change-color">
												<input
													type="color"
													className="colorpicker"
													value="#ff66ff"
													onChange={(event) => {
														//console.log('[Settings] selected color:', color);
														let colorValue = event.target.value;
														let nodeFill = !this.props.fillNodeColors;
														if (colorValue) {
															let selectedNode = nodes.filter((node: any) => node.id === this.state.selectedNodeId)[0];
															if (selectedNode) {
																selectedNode.style = {
																	borderColor: colorValue,
																	backgroundColor: !nodeFill ? colorValue + "50" : "",
																	borderWidth: "7px",
																	textTransform: this.props.isUppercase ? "none" : "uppercase", //the naming is inverted
																	borderRadius: "20px",
																	color: event.target.style.color,
																	fontSize: this.state.fontSettings?.font_size,
																};
															}
															onNodesChange(nodes);
															this.setState({ pickedColor: colorValue });
															this.debounce(() => {
																console.log("User stopped changing node colors");
																saveAndExit(false);
															});
															//this.applyColorToNode(color);
														}
													}}
												></input>
											</div>
											<IonButton
												id="change-text"
												className="menuBtn"
												onClick={(event) => {
													this.setState({
														showNameChangePopup: true,
														newNodeName: nodes[nodes.indexOf(nodes.filter((node: any) => node.id === this.state.selectedNodeId)[0])].data.label,
													});
												}}
											>
												<IonIcon icon={createOutline} size="medium" />
											</IonButton>

											<IonButton
												id="delete-node"
												className="menuBtn"
												hidden={this.state.selectedNodeId === "" && this.state.selectedEdgeId === ""}
												onClick={() => {
													if (this.state.selectedNodeId !== "") {
														let nodeToRemove = nodes.filter((node: any) => node.id === this.state.selectedNodeId);
														let edgesToRemove = getConnectedEdges(nodeToRemove, edges);
														setEdges(edges.filter((edge: any) => !edgesToRemove.includes(edge)));
														setNodes(nodes.filter((node: any) => node.id !== this.state.selectedNodeId));
														this.setState({ selectedNodeId: "" });
													} else {
														//remove edge
														let edgeToRemove = edges.filter((edge: any) => edge.id === this.state.selectedEdgeId);
														setEdges(edges.filter((edge: any) => !edgeToRemove.includes(edge)));
														this.setState({ selectedEdgeId: "" });
													}
												}}
											>
												<IonIcon icon={trash} size="medium" />
											</IonButton>
										</IonList>
									</IonPopover>

									{/* <IonButton className='Button' color='white'
                                        onClick={() => undo()} disabled={canUndo}>
                                        <IonIcon icon={arrowUndo} />
                                    </IonButton>
                                    <IonButton className='Button' color='white'
                                        onClick={() => redo()} disabled={!canRedo}>
                                        <IonIcon icon={arrowRedo} />
                                    </IonButton> */}
									<IonButtons>
										<IonButton
											hidden={!isMobile}
											className="Button"
											slot="end"
											color="white"
											onClick={() => {
												this.setState({ isModalMenuOpen: !this.state.isModalMenuOpen });
											}}
										>
											<IonLabel className="typeMaps">
												<IonIcon style={{ fontSize: "20px" }} icon={brush} />
											</IonLabel>
										</IonButton>
									</IonButtons>
									{/* <IonPopover
										className="resolution"
										isOpen={this.state.isDropDownMenuMobileOpen}
										onDidDismiss={() => this.setState({ isDropDownMenuMobileOpen: false })}
										event={this.state.popoverEvent}
										showBackdrop={false}
										id="popoverContent"
									>
										<IonButton
											className="Button"
											onClick={() => {
												this.deleteMap();
											}}
										>
											<IonLabel className="typeMaps">
												<IonIcon icon={trash} size="medium" />
												<span style={{ margin: "10px" }}>
													<Trans>Elimina mappa</Trans>
												</span>
											</IonLabel>
										</IonButton>
										<IonButton
											className="Button"
											onClick={() => {
												this.setState({ isModalMenuOpen: true });
												this.setState({ isDropDownMenuMobileOpen: false });
											}}
										>
											<IonLabel className="typeMaps">
												<IonIcon icon={brush} size="medium" />
												<span style={{ margin: "10px" }}>
													<Trans>Formatta mappa</Trans>
												</span>
											</IonLabel>
										</IonButton>
									</IonPopover> */}
								</>
							)}
							<MapFormatModal
								modalMenuPage={this.state.modalMenuPage}
								isModalMenuOpen={this.state.isModalMenuOpen}
								isUppercase={this.props.isUppercase}
								fontSettings={this.state.fontSettings}
								edgeType={this.state.edgeType}
								fillNodeColors={!this.props.fillNodeColors}
								organizationName={this.props.organizationName}
								showTextOnImages={this.state.showTextOnImages}
								switchPage={(page: any) => this.setState({ modalMenuPage: page })}
								toggleUppercase={() => {
									this.props.toggleUppercase();
								}}
								fontMinusClicked={() => {
									let newFontSize = this.state.fontSettings?.font_size ? this.state.fontSettings?.font_size - 1 : 16 - 1;
									updateFontSize(newFontSize);
								}}
								fontRangeDidChange={(e) => {
									let newFontSize = e.detail.value;
									updateFontSize(newFontSize);
								}}
								fontPlusClicked={() => {
									let newFontSize = this.state.fontSettings?.font_size ? this.state.fontSettings?.font_size + 1 : 16 + 1;
									updateFontSize(newFontSize);
								}}
								edgeTypeDidChange={(e) => {
									changeEdgeType(e.detail.value);
								}}
								fillNodesClicked={() => {
									this.props.toggleColorFill();
									console.log(this.props.toggleColorFill, this.props.fillNodeColors);
									for (let i = 0; i < document.getElementsByClassName("react-flow__node").length; i++) {
										let mapBackground = document.getElementsByClassName("reactflow-wrapper")[0] as HTMLElement;
										const backgroundColor = getComputedStyle(mapBackground).backgroundColor;
										let nodeBackground = document.getElementsByClassName("react-flow__node-default")[0] as HTMLElement;
										const nodeBackgroundColor = getComputedStyle(nodeBackground);
										console.log("NODE BACKGROUND COLOR", backgroundColor);
										if (this.props.fillNodeColors) {
											const node = document.getElementsByClassName("react-flow__node")[i] as HTMLElement;
											node.style.backgroundColor = "var(--bg-content-color)";
											if (isBackgroundColorDark(backgroundColor)) {
												node.style.color = "#000";
											} else {
												node.style.color = "#fff";
												if (hasBorderColor(nodeBackgroundColor)) {
													node.style.color = "#000";
												} else {
													node.style.color = "#fff";
												}
											}
										} else {
											const node = document.getElementsByClassName("react-flow__node")[i] as HTMLElement;
											let nodeColor = nodes[i].style!.borderColor! == undefined ? "#bcbcbc" : nodes[i].style!.borderColor! + 50;
											node.style.backgroundColor = nodeColor;
											console.log("colorti nodi", nodeColor);
											// node.style.color = "#fff";
											if (isBackgroundColorDark(backgroundColor)) {
												node.style.color = "#fff";
												if (hasBorderColor(nodeBackgroundColor)) {
													node.style.color = "#fff";
												} else {
													node.style.color = "#000";
												}
											} else {
												node.style.color = "#000";
											}
										}
									}
								}}
								showImagesClicked={() => {
									toggleImagesCaptions();
								}}
								downloadButtonClicked={() => {
									this.generatePDF();
								}}
								connectNodesClicked={() => {
									this.setState({ connectingMode: !this.state.connectingMode, selectedNodesToConnect: [] });
									// if (isMobile) {
									this.setState({ selectedNodesToConnect: [], isModalMenuOpen: false });
								}}
								connectionTypeClicked={() => this.setState({ modalMenuPage: "edgeType" })}
								textClicked={() => {
									this.setState({ modalMenuPage: "text" });
								}}
								autoLayout={(algorithm, direction) => {
									autoLayout(algorithm, direction);
								}}
								closeModal={() => {
									this.setState({ isModalMenuOpen: false });
									setTimeout(() => {
										this.setState({ modalMenuPage: "main" });
									}, 200);
								}}
							/>

							{!isMobile &&
								false && ( //hidden since brought accessibilities features outside of this menu
									<div className="mapsMenu">
										<IonMenuToggle className="Button" id="main-content" aria-hidden="false">
											<IonButton className="Button buttonCloseModalMap">
												<IonIcon icon={menuOutline} size="large" />
											</IonButton>
										</IonMenuToggle>
									</div>
								)}
						</div>
					</IonToolbar>
				</IonHeader>

				<p className="nodeConnectionText" hidden={!this.state.connectingMode}>
					<Trans>{this.state.selectedNodesToConnect.length === 0 ? "Clicca sul primo nodo da collegare" : "Clicca un altro nodo"}</Trans>
					<IonIcon
						style={{ cursor: "pointer" }}
						icon={closeOutline}
						onClick={() => this.setState({ connectingMode: false, selectedNodesToConnect: [] })}
					></IonIcon>
				</p>

				<div
					id="actionsDiv"
					onMouseEnter={() => {
						clearTimeout(hideTimeout);
						clearTimeout(animationTimeout);
					}}
					onMouseLeave={(e) => {
						let actionsDiv = document.getElementById("actionsDiv")!;
						hideTimeout = setTimeout(() => {
							if (actionsDiv) {
								actionsDiv!.classList.remove("visible"); // Ensure the 'visible' class is removed
								actionsDiv!.classList.add("hidden"); // Add the 'hidden' class to trigger the slideToTop animation
								//timeout to wait for the animation to complete
								animationTimeout = setTimeout(() => {
									actionsDiv!.hidden = true;
								}, 500);
							}
						}, 750); // Adjust the delay as needed
					}}
					hidden={true}
				>
					<IonButton
						id="mapTtsButton"
						/* CLICK EVENT IS HANDLED IN onNodeClick when checking for id==='mapTtsButton' */
						onClick={() => {
							let node = nodes.filter((node: any) => node.id === this.state.hoveredId)[0];
							if (node) generateNodeTTS(node);
						}}
						disabled={this.state.disableTtsButton}
						fill="clear"
						className="mapTtsButton"
					>
						<IonIcon className="mapTtsIcon" icon={voiceOff} slot="start" />
						<Trans>Sintesi vocale</Trans>
					</IonButton>
				</div>
				<audio
					onEnded={() => {
						this.setState({ disableTtsButton: false });
					}}
					id="mapAudioElement"
				/>
				<ReactFlowProvider>
					<div className="reactflow-wrapper" ref={reactFlowWrapper}>
						<ReactFlow
							nodeOrigin={[0.5, 0.5]}
							defaultViewport={{ x: -300, y: -380, zoom: 0.1 }}
							minZoom={0.1}
							selectNodesOnDrag={true}
							fitView={true}
							zoomOnScroll
							fitViewOptions={{ minZoom: 0.1 }}
							onNodeMouseEnter={onNodeHoverStart}
							onNodeMouseLeave={onNodeHoverEnd}
							onPaneClick={onPaneClick}
							onNodesDelete={onNodesDelete}
							nodes={nodes}
							edges={edges}
							onNodeContextMenu={onNodeContextMenu}
							onConnect={onConnect}
							onNodesChange={onNodesChange}
							// onEdgeUpdateStart={onEdgeUpdateStart}
							onEdgeDoubleClick={onEdgeDoubleClick}
							onEdgeClick={onEdgeClick}
							onNodeDragStop={onNodeDragStop}
							// onEdgesChange={onEdgesChange}
							//onLoad={onLoad}
							edgeTypes={this.edgeTypes}
							onInit={(_reactFlowInstance: ReactFlowInstance) => {
								auth.onAuthStateChanged(async (userData) => {
									if (userData) {
										// console.log("USER DATA", userData);
										this.setState({ userUuid: userData.uid });
										// let viewingSettings = await settingsServices.getDocumentViewingSettings(userData.uid);
										// console.log("GOT VIEWING SETTINGS", viewingSettings);

										// // viewingSettings.fontSize = viewingSettings.fontSize >= 15 ? viewingSettings.fontSize - 5 : viewingSettings.fontSize;
										// this.setState({
										// 	fontSettings: {
										// 		font: viewingSettings.font,
										// 		fontSize: viewingSettings.fontSize,
										// 		lineLeading: viewingSettings.lineLeading,
										// 	},
										// });

										userData.getIdToken().then((token) => {
											this.setState({ bearerToken: token });
											const pathname = window.location.pathname;

											// Find the position of "/maps/" in the pathname
											const mapsIndex = pathname.indexOf('/maps/');
											const afterMaps = pathname.substring(mapsIndex + 6); // "resourceid/sessionid"
											const parts = afterMaps.split('/');

											let resourceIdFromUrl = parts[0]; // "resourceid"
											let sessionIdFromUrl = parts[1]; // "sessionid"
											if (mapsIndex !== -1) {
												// Extract everything after "/maps/"
												
												resourceIdFromUrl = parts[0]; // "resourceid"
												sessionIdFromUrl = parts[1]; // "sessionid"
												
												console.log('Resource ID:', resourceIdFromUrl);
												console.log('Session ID:', sessionIdFromUrl);
											} else {
												console.log('URL does not contain "/maps/"');
											}
											let mapId = resourceIdFromUrl || this.props.history.location.state.mapId;
											let sessionId= sessionIdFromUrl || this.props.history.location.state.session.id;

											this.setState({mapId:mapId,sessionId:sessionId},()=>{
											if (mapId) {
												mapsServices
													.getSingleConceptualMap(this.state.mapId, token)
													.then((response) => {
														settingsServices.getUserSettings(userData.uid, token).then((settings) => {
															let fontSettings = settings;
															// fontSettings.font_size = settings.font_size ? settings.font_size + 20 : 40;
															let objFontSize = response.content.nodes[0].fontSize;
															if (objFontSize) {
																updateFontSize(objFontSize);
																this.setState({ initialFontSize: objFontSize });
															}
															this.setState({ mapData: response, fontSettings: fontSettings, initialFontSize: objFontSize ? objFontSize : null }, () => {
																onInit(_reactFlowInstance);
															});
														});
													})
													.catch((err) => {
														console.error("Error fetching map data from new backend: ", err);
													});
											}
											if (this.props.history.location.state?.session) {
												this.setState({ session: this.props.history.location.state.session });
											}
										})
										});
									}
								});
								// setTimeout(() => {
								// }, 100);
							}}
							// onDrop={onDrop}
							onNodeDoubleClick={onNodeDoubleClick}
							onEdgeUpdate={onEdgeUpdate}
							onDragOver={onDragOver}
							onNodeClick={onNodeClick}
							deleteKeyCode={null}
							defaultMarkerColor="#000"
						>
							<Controls hidden={isMobile} />
							<Background variant={BackgroundVariant.Dots} size={3} gap={65} />
						</ReactFlow>
					</div>
				</ReactFlowProvider>
				{!isMobile && (
					<MapSideButtons
						selectedNodeId={this.state.selectedNodeId}
						modeEditing={this.state.modeEditing}
						documentId={this.state.documentId}
						history={this.props.history}
						onlyMap={this.state.onlyMap}
						zoomInMapsClicked={() => {
							reactFlowInstance.fitView({ duration: 1000, minZoom: 0.1 });
						}}
						zoomMaps={() => zoomMaps()}
						zoomOut={() => reactFlowInstance.zoomOut({ duration: 500 })}
						zoomIn={() => reactFlowInstance.zoomIn({ duration: 500 })}
						editingModeClicked={() => {
							this.setState({ modeEditing: this.state.modeEditing === "main" ? "editing" : "main" });
						}}
						downloadButtonClicked={() => this.generatePDF()}
						printClicked={() => {
							// this.state.rfInstance.fitView({ duration: 500, minZoom: 0.1 });
							// window.print();
							setTimeout(() => {
								window.print();
							}, 500);
						}}
						addnode={() => {
							onAddNew();
						}}
						openTutorial={() => {
							this.getUserChoiceOnTutorial(this.state.userUuid, "tMN", "auto");

							// this.setState({modifyNodeTutorial:true,tutorial:true})
						}}
					/>
				)}

				<IonPopover
					className="nodeNameChangePopover"
					onDidDismiss={() => {
						this.setState({ newNodeName: "", showNameChangePopup: false });
					}}
					isOpen={this.state.showNameChangePopup}
				>
					<div className="nodeNameChangePopoverContent">
						<p style={{ textAlign: "center" }}>
							<b>
								<Trans>Inserire il nome del nodo</Trans>
							</b>
						</p>
						<IonTextarea
							id="popoverNameInput"
							placeholder={
								i18n.language === "it"
									? "Inserisci contenuto"
									: i18n.language === "fr"
									? "Insérer le contenu"
									: i18n.language === "es"
									? "Insertar contenido"
									: i18n.language === "de"
									? "Inhalte einfügen"
									: i18n.language === "ca"
									? "Insereix contingut"
									: "Insert content"
							}
							value={this.state.newNodeName}
							rows={5}
							cols={70}
							style={{ height: "fit-content" }}
							onKeyUp={(e) => {
								const inputvalue = (e.target as HTMLInputElement).value;
								console.log("EEE aa", inputvalue);
								setNodeName(this.state.newNodeName);
								this.setState({ newNodeName: inputvalue });
								// //console.log(e.detail.value!);
							}}
						></IonTextarea>
						{/* <IonInput
                        id='popoverNameInput'
                        placeholder='Inserisci un nome'
                        value={this.state.newNodeName}
                        onIonChange={(e) => {
                            this.setState({ newNodeName: e.detail.value! })
                        }}
                    /> */}
						<IonButton
							className="nodeNameChangePopoverButton"
							onClick={() => {
								// //console.log(this.state.newNodeName);
								this.setState({ showNameChangePopup: false });
								saveAndExit(false);
							}}
						>
							<Trans>Aggiorna</Trans>
							{/* TO LOCALIZE THIS */}
						</IonButton>
					</div>
				</IonPopover>

				<IonPopover
					className="mapEdgeLabelChangePopover"
					onDidDismiss={() => {
						this.setState({ edgeLabel: "", showEdgeCreatePopover: false });
					}}
					isOpen={this.state.showEdgeCreatePopover}
				>
					<p className="mapEdgeLabelChangePopoverTitle">
						<Trans>Vuoi aggiungere un etichetta al collegamento?</Trans>
					</p>
					<IonInput
						placeholder={
							i18n.language === "it"
								? "Inserisci un'etichetta"
								: i18n.language === "en"
								? "Insert label"
								: i18n.language === "fr"
								? "Insérer une étiquette"
								: i18n.language === "de"
								? "Geben Sie eine Bezeichnung ein"
								: i18n.language === "ca"
								? "Introduïu una etiqueta"
								: "Insertar una etiqueta"
						}
						value={this.state.edgeLabel}
						debounce={300}
						onIonChange={(e) => {
							this.setState({ edgeLabel: e.detail.value as any });
						}}
					/>
					<IonButton
						onClick={() => {
							// //console.log(this.state.tempEdgeParams);
							let tempParams = this.state.tempEdgeParams;
							setEdges((eds: any) => addEdge({ ...tempParams, label: this.state.edgeLabel, type: this.state.edgeType }, eds));
							this.setState({ showEdgeCreatePopover: false, edgeLabel: "" });
						}}
					>
						<Trans>Aggiungi</Trans>
					</IonButton>
				</IonPopover>

				<IonPopover
					className="mapEdgeLabelChangePopover"
					onDidDismiss={() => {
						this.setState({ edgeLabel: "", showEdgeLabelPopover: false });
					}}
					isOpen={this.state.showEdgeLabelPopover}
				>
					<p className="mapEdgeLabelChangePopoverTitle">
						<Trans>Modifica l'etichetta</Trans>
					</p>
					<IonInput
						className="mapEdgeLabelChangePopoverInput"
						placeholder={
							i18n.language === "it"
								? "Inserisci un'etichetta"
								: i18n.language === "en"
								? "Insert label"
								: i18n.language === "fr"
								? "Insérer une étiquette"
								: i18n.language === "de"
								? "Geben Sie eine Bezeichnung ein"
								: i18n.language === "ca"
								? "Introduïu una etiqueta"
								: "Insertar una etiqueta"
						}
						value={this.state.edgeLabel}
						debounce={300}
						onIonChange={(e) => {
							this.setState({ edgeLabel: e.detail.value as any });
						}}
					/>
					<IonButton
						onClick={() => {
							//console.log(this.state.tempEdgeParams);
							let tempParams = this.state.tempEdgeParams;
							let selectedEdge = edges.filter((edge: any) => edge.id === this.state.selectedEdgeId)[0];

							edges[edges.indexOf(selectedEdge)].label = this.state.edgeLabel;
							setEdges((eds: any) => addEdge({ ...tempParams, label: this.state.edgeLabel, type: this.state.edgeType }, eds));
							//console.log(edges);

							this.setState({ showEdgeLabelPopover: false, edgeLabel: "" });
						}}
					>
						<Trans>Aggiungi</Trans>
					</IonButton>
				</IonPopover>

				{/* <ColorPickerPopover
                    showResetButton
                    popoverEvent={this.state.popoverEvent}
                    isOpen={this.state.showColorPickerPopover}
                    selectedColor={(color) => {
                        //console.log('[Settings] selected color:', color);
                        if (color) {
                            let selectedNode = nodes.filter(node => node.id === this.state.selectedNodeId)[0]
                            if (selectedNode) {
                                selectedNode.style = { borderColor: color === '#000000' ? '#000000' : color.slice(0, -2), backgroundColor: color === '#000000' ? color.slice(0, -2) : color, borderWidth: color === '#000000' ? '' : '3px', textTransform: 'none' }
                            }
                            onNodesChange(nodes)
                            this.setState({ pickedColor: color })
                            //this.applyColorToNode(color);
                        }
                    }}
                    onDidDismiss={() => {
                        this.setState({
                            selectedNodeHasImage: false,
                            showColorPickerPopover: false,
                            showContentPopover: false,
                            popoverEvent: null,
                        })
                    }}
                /> */}

				<IonModal
					isOpen={this.state.showImagesPopover}
					// event={this.state.popoverEvent}
					className="mapsImagePickerPopover"
					onDidDismiss={() => {
						this.setState({
							showContentPopover: false,
							popoverEvent: null,
							showImagesPopover: false,
							selectedNodeHasImage: false,
							wordPicture: "",
							wordCustomImage: null,
						});
					}}
				>
					<IonHeader>
						<IonToolbar>
							<p className="imagePickerPopoverTitle">
								<Trans>Scegli un immagine da associare al nodo</Trans>
							</p>
							<IonIcon
								slot="end"
								size="large"
								icon={closeOutline}
								style={{ cursor: "pointer" }}
								onClick={() => {
									this.setState({ showContentPopover: false, popoverEvent: null, showImagesPopover: false, selectedNodeHasImage: false });
								}}
							/>
						</IonToolbar>
					</IonHeader>
					<IonGrid style={{ overflowY: "scroll" }}>
						<IonRow>
							{this.state.imageSearchResponse &&
								this.state.imageSearchResponse.length > 0 &&
								this.state.imageSearchResponse.map((image: string, key: number) => {
									return (
										<IonCol key={key} size="4">
											<IonImg
												onClick={() => {
													this.text_to_image(`${image.startsWith("https://") ? image : "data:image/png;base64," + image}`);
													this.setState({ showContentPopover: false, showImagesPopover: false, isNodeSelected: false, selectedNodeId: "" });
													if (!this.props.showImagesCaptions) {
														toggleImagesCaptions();
													}
													(nodes.filter((node: any) => node.id === this.state.selectedNodeId)[0] as any).image = image;
													saveAndExit(false);
												}}
												key={key}
												style={{ width: "10vw", height: "10vw", objectFit: "scale-down", cursor: "pointer" }}
												src={`${image.startsWith("https://") ? image : "data:image/png;base64," + image}`}
												//alt={hit.tags}
											/>
										</IonCol>
									);
								})}
						</IonRow>
						<div className="mapImgsPopup" style={{ textAlign: "center", display: "flex", flexDirection: "column" }}>
							{this.state.imageSearchResponse?.length === 0 && (
								<p>
									<Trans>Non ci sono immagini da assegnare al nodo</Trans>
								</p>
							)}
							{/* <IonButton fill='clear'>Carica immagine</IonButton> */}
							<IonButton
								onClick={async () => {
									//save to db before leaving page
									let nodesToPush: any[] = [];
									let edgesToPush: any[] = [];

									if (reactFlowInstance) {
										nodes.forEach((node: any) => {
											nodesToPush.push({
												id: node.id,
												label: node.data ? node.data.label : node.label,
												color: node.color ? node.color : node.style ? node.style.backgroundColor : null,
												position: node.position,
												image: node.image ? node.image : null,
											});
										});
										// //console.log("NODES TO PUSH", nodesToPush);

										edges.forEach((edge: any) => {
											edgesToPush.push(edge);
										});
										// //console.log("NODES TO PUSH", nodesToPush);
										// //console.log("EDGES TO PUSH", edgesToPush);
										// await setDoc(doc(firestore, `m/${this.state.documentId}/`), {
										//     f: this.state.documentId,
										//     d: {
										//         n: nodesToPush,
										//         e: edgesToPush,
										//     },
										// })
										//     .then(response => {
										let currentNodeText = nodes.filter((node: any) => node.id === this.state.selectedNodeId)[0].data.label;
										this.props.history.push({
											pathname: "/sketch",
											state: {
												selectedNodeId: this.state.selectedNodeId ? this.state.selectedNodeId : null,
												documentId: this.state.documentId,
												docTitle: this.state.docTitle,
												word: currentNodeText.trim(),
												drawing: this.state.imageSearchResponse.filter((image: string) => image === this.state.wordPicture)[0],
											},
										});
										// })
										// .catch(e => {
										//     console.error("[MindMap] error setting doc:", e);
										// })
									}
								}}
								fill="clear"
							>
								<Trans>
									{this.state.wordPicture === "" ? "Crea " : "Modifica "}
									disegno
								</Trans>
								<IonIcon icon={drawingPencil} size="large" />
							</IonButton>
							<IonButton
								onClick={async () => {
									console.log(this.state.wordCustomImage);
									if (this.state.wordCustomImage !== "" && this.state.wordCustomImage !== null) {
										console.log(this.state.wordCustomImage);
										//REMOVE EXISTING IMAGE
										let imageRef = await storageRef(storage, this.state.wordCustomImage!);
										console.log("Found ref: ", imageRef);
										await deleteObject(imageRef)
											.then(async (response) => {
												let currentNodeText = nodes.filter((node: any) => node.id === this.state.selectedNodeId)[0].data.label;
												console.log("Successfully removed custom image from storage: ", response);
												//also remove from firestore db
												const imageQuery = query(collection(firestore, `i/${this.state.userUuid}/i`), where("w", "==", currentNodeText.trim()));
												const querySnapshot = await getDocs(imageQuery);
												if (!querySnapshot.empty) {
													querySnapshot.forEach(async (doc) => {
														await deleteDoc(doc.ref)
															.then((response) => {
																console.log("susscesfully removed custom image firestore doc");
															})
															.catch((error) => {
																console.log("Error during custom image doc deletion: ", error);
															});
													});
												}
												const image = document.getElementById(`image-${this.state.selectedNodeId}`);
												(nodes.filter((node: any) => node.id === this.state.selectedNodeId)[0] as any).image = null;
												if (image) {
													image.remove();
													saveAndExit(false);
												}
												this.setState({
													imageSearchResponse: this.state.imageSearchResponse.filter((url: any) => url !== this.state.wordCustomImage),
													wordCustomImage: null,
													showContentPopover: false,
													popoverEvent: null,
													showImagesPopover: false,
												});
											})
											.catch((error) => {
												console.log("Error removing custom image from storage: ", error);
											});
									} else {
										document.getElementById("utilsFileInput")?.click();
									}
								}}
								fill="clear"
							>
								<Trans>
									{!this.state.wordCustomImage ? "Carica " : "Rimuovi "}
									immagine
								</Trans>
								<IonIcon slot="end" icon={!this.state.wordCustomImage ? cloudUploadOutline : imageOutline} />
							</IonButton>
						</div>
					</IonGrid>
				</IonModal>

				<IonLoading
					message={
						i18n.language === "it"
							? "Sto caricando le immagini..."
							: i18n.language === "en"
							? "Loading images..."
							: i18n.language === "es"
							? "Carga de imágenes..."
							: i18n.language === "de"
							? "Ich lade die Bilder hoch..."
							: i18n.language === "ca"
							? "Estic penjant les imatges..."
							: "Téléchargement d'images..."
					}
					isOpen={this.state.isLoadingImages}
				/>
				{
					// !isMobile && (
					// 	<MapSideMenu
					// 		goBack={() => this.setState({ menuPage: "main" })}
					// 		menuPage={this.state.menuPage}
					// 		showTextOnImages={this.state.showTextOnImages}
					// 		isUppercase={this.props.isUppercase}
					// 		edgeType={this.state.edgeType}
					// 		fontSettings={this.state.fontSettings}
					// 		fillNodeColors={!this.props.fillNodeColors}
					// 		organizationName={this.props.organizationName}
					// 		showImageCaptions={this.props.showImagesCaptions}
					// 		toggleColorFill={() => {
					// 			this.props.toggleColorFill();
					// 			for (let i = 0; i < document.getElementsByClassName("react-flow__node").length; i++) {
					// 				let mapBackground = document.getElementsByClassName("reactflow-wrapper")[0] as HTMLElement;
					// 				const backgroundColor = getComputedStyle(mapBackground).backgroundColor;
					// 				let nodeBackground = document.getElementsByClassName("react-flow__node-default")[0] as HTMLElement;
					// 				const nodeBackgroundColor = getComputedStyle(nodeBackground);
					// 				if (this.props.fillNodeColors) {
					// 					console.log(this.props.fillNodeColors);
					// 					const node = document.getElementsByClassName("react-flow__node")[i] as HTMLElement;
					// 					node.style.backgroundColor = "var(--bg-content-color)";
					// 					if (isBackgroundColorDark(backgroundColor)) {
					// 						node.style.color = "#000";
					// 					} else {
					// 						node.style.color = "#fff";
					// 						if (hasBorderColor(nodeBackgroundColor)) {
					// 							node.style.color = "#000";
					// 						} else {
					// 							node.style.color = "#fff";
					// 						}
					// 					}
					// 				} else {
					// 					const node = document.getElementsByClassName("react-flow__node")[i] as HTMLElement;
					// 					console.log(this.props.fillNodeColors);
					// 					node.style.backgroundColor = nodes[i].style!.borderColor! + "50";
					// 					// node.style.color = "#fff";
					// 					if (isBackgroundColorDark(backgroundColor)) {
					// 						node.style.color = "#fff";
					// 						if (hasBorderColor(nodeBackgroundColor)) {
					// 							node.style.color = "#fff";
					// 						} else {
					// 							node.style.color = "#000";
					// 						}
					// 					} else {
					// 						node.style.color = "#000";
					// 					}
					// 				}
					// 			}
					// 			saveAndExit(false);
					// 		}}
					// 		showImages={() => {
					// 			this.props.toggleImagesCaptions();
					// 			let nodesWithImage = nodes.filter((node: any) => node.image != null);
					// 			let nodesWithImageIds = nodesWithImage.map((node: any) => node.id);
					// 			for (let i = 0; i < document.getElementsByClassName("react-flow__node").length; i++) {
					// 				let nodeElement = document.getElementsByClassName("react-flow__node")[i] as HTMLElement;
					// 				console.log(nodesWithImage[i])
					// 				let imgElement = nodeElement.querySelector('img');
					// 				if (imgElement) {
					// 					if (!this.props.showImagesCaptions === true)
					// 						imgElement.style.display = "flex"
					// 					else
					// 						imgElement.style.display = "none"
					// 					console.log('An img element exists within this node.');
					// 				} else {
					// 					console.log('No img element found within this node.');
					// 				}
					// 				// if (nodesWithImageIds.includes(nodeElement.getAttribute("data-id")!)) {
					// 				// 	if (!this.props.showImagesCaptions === true) {
					// 				// 		nodesWithImageIds[i].style.display="flex"
					// 				// 	} else nodesWithImageIds[i].style.display="none"
					// 				// }
					// 			}
					// 		}}
					// 		connectNodes={() => {
					// 			this.setState({ connectingMode: !this.state.connectingMode, selectedNodesToConnect: [] });
					// 			this.setState({ selectedNodesToConnect: [] });
					// 			(document.getElementById("sideMenu") as HTMLIonMenuElement).close();
					// 		}}
					// 		connectionTypeClicked={() => this.setState({ menuPage: "edgeType" })}
					// 		textClicked={() => this.setState({ menuPage: "text" })}
					// 		edgeTypeDidChange={(e) => {
					// 			console.log("CHANGE CONNECTIONS", e, edges)
					// 			edges.forEach((edge: any) => (edge.type = e.detail.value));
					// 			this.setState({ edgeType: e.detail.value }, () => {
					// 				saveAndExit(false).then(() => {
					// 					window.location.reload()
					// 				})
					// 			});
					// 		}}
					// 		getDarkMode={() => getDarkMode()}
					// 		toggleUppercase={() => {
					// 			this.props.toggleUppercase()
					// 		}}
					// 		fontMinus={() => {
					// 			if (this.state.fontSettings.fontSize === 0) {
					// 				alert("Limite minimo raggiunto");
					// 				return;
					// 			}
					// 			let nodesWithImage = nodes.filter((node: any) => node.image != null);
					// 			let nodesWithImageIds = nodesWithImage.map((node: any) => node.id);
					// 			for (let i = 0; i < document.getElementsByClassName("react-flow__edge-text").length; i++) {
					// 				(document.getElementsByClassName("react-flow__edge-text")[i] as HTMLElement).style.fontSize = this.state.fontSettings.fontSize + -2 + "px";
					// 			}
					// 			for (let i = 0; i < document.getElementsByClassName("react-flow__node").length; i++) {
					// 				if (this.state.fontSettings.fontSize <= 20) (document.getElementsByClassName("react-flow__node")[i] as HTMLElement).style.maxWidth = "300px";
					// 				if (
					// 					!nodesWithImageIds.includes((document.getElementsByClassName("react-flow__node")[i] as HTMLElement).getAttribute("data-id")!) &&
					// 					!this.state.showTextOnImages
					// 				) {
					// 					(document.getElementsByClassName("react-flow__node")[i] as HTMLElement).style.fontSize = this.state.fontSettings.fontSize - 2 + "px";
					// 				} else if (this.state.showTextOnImages) {
					// 					(document.getElementsByClassName("react-flow__node")[i] as HTMLElement).style.fontSize = this.state.fontSettings.fontSize - 2 + "px";
					// 				}
					// 				let newFontSettings = {
					// 					font: this.state.fontSettings.font,
					// 					fontSize: this.state.fontSettings.fontSize - 2,
					// 					lineLeading: this.state.fontSettings.lineLeading,
					// 				};
					// 				this.setState({ fontSettings: newFontSettings });
					// 			}
					// 		}}
					// 		fontRangeDidChange={(e) => {
					// 			let nodesWithImage = nodes.filter((node: any) => node.image != null);
					// 			let nodesWithImageIds = nodesWithImage.map((node: any) => node.id);
					// 			for (let i = 0; i < document.getElementsByClassName("react-flow__edge-text").length; i++) {
					// 				(document.getElementsByClassName("react-flow__edge-text")[i] as HTMLElement).style.fontSize = e.detail.value + "px";
					// 			}
					// 			for (let i = 0; i < document.getElementsByClassName("react-flow__node").length; i++) {
					// 				if (this.state.fontSettings.fontSize <= 20) (document.getElementsByClassName("react-flow__node")[i] as HTMLElement).style.maxWidth = "500px";
					// 				if (
					// 					!nodesWithImageIds.includes((document.getElementsByClassName("react-flow__node")[i] as HTMLElement).getAttribute("data-id")!) &&
					// 					!this.state.showTextOnImages
					// 				) {
					// 					(document.getElementsByClassName("react-flow__node")[i] as HTMLElement).style.fontSize = e.detail.value + "px";
					// 				} else if (this.state.showTextOnImages) {
					// 					(document.getElementsByClassName("react-flow__node")[i] as HTMLElement).style.fontSize = e.detail.value + "px";
					// 				}
					// 			}
					// 			if (e) {
					// 				newFontSettings = {
					// 					font: this.state.fontSettings.font,
					// 					fontSize: e.detail.value,
					// 					lineLeading: this.state.fontSettings.lineLeading,
					// 				};
					// 				//console.log('[Settings] editing font size success', documentViewingSettings);
					// 				//this.setState({ fontSettings: newFontSettings })
					// 			}
					// 			this.setState({ fontSettings: newFontSettings });
					// 		}}
					// 		fontPlus={() => {
					// 			if (this.state.fontSettings.fontSize === 30) {
					// 				alert("Limite massimo raggiunto");
					// 				return;
					// 			}
					// 			let nodesWithImage = nodes.filter((node: any) => node.image != null);
					// 			let nodesWithImageIds = nodesWithImage.map((node: any) => node.id);
					// 			for (let i = 0; i < document.getElementsByClassName("react-flow__edge-text").length; i++) {
					// 				(document.getElementsByClassName("react-flow__edge-text")[i] as HTMLElement).style.fontSize = this.state.fontSettings.fontSize + 2 + "px";
					// 			}
					// 			for (let i = 0; i < document.getElementsByClassName("react-flow__node").length; i++) {
					// 				if (this.state.fontSettings.fontSize >= 20) (document.getElementsByClassName("react-flow__node")[i] as HTMLElement).style.maxWidth = "500px";
					// 				// (document.getElementsByClassName('react-flow__node')[i] as HTMLElement).style.maxWidth = "300px";
					// 				if (
					// 					!nodesWithImageIds.includes((document.getElementsByClassName("react-flow__node")[i] as HTMLElement).getAttribute("data-id")!) &&
					// 					!this.state.showTextOnImages
					// 				) {
					// 					(document.getElementsByClassName("react-flow__node")[i] as HTMLElement).style.fontSize = this.state.fontSettings.fontSize + 2 + "px";
					// 				} else if (this.state.showTextOnImages) {
					// 					(document.getElementsByClassName("react-flow__node")[i] as HTMLElement).style.fontSize = this.state.fontSettings.fontSize + 2 + "px";
					// 				}
					// 				let newFontSettings = {
					// 					font: this.state.fontSettings.font,
					// 					fontSize: this.state.fontSettings.fontSize + 2,
					// 					lineLeading: this.state.fontSettings.lineLeading,
					// 				};
					// 				this.setState({ fontSettings: newFontSettings });
					// 			}
					// 		}}
					// 	/>
					// )
				}

				{this.state.modeEditing === "editing" && this.state.selectedEdgeId !== "" && (
					<IonFab horizontal="center" vertical="bottom" style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
						<IonFabButton
							style={{ marginRight: "15px", marginLeft: "-43px" }}
							color="danger"
							onClick={() => {
								let edgeToRemove = edges.filter((edge: any) => edge.id === this.state.selectedEdgeId);
								setEdges(edges.filter((edge: any) => !edgeToRemove.includes(edge)));
								this.setState({ selectedEdgeId: "" });
							}}
						>
							<IonIcon icon={trash} />
						</IonFabButton>
						<IonFabButton
							color="secondary"
							onClick={() => {
								let edgeLabel = edges.filter((edge: any) => edge.id === this.state.selectedEdgeId)[0].label;
								this.setState({ showEdgeLabelPopover: true, edgeLabel: edgeLabel as any });
							}}
						>
							<IonIcon size="large" icon={pencilEdit} />
						</IonFabButton>
					</IonFab>
				)}
				<input
					style={{ position: "absolute", top: "-100px" }}
					id="utilsFileInput"
					type={"file"}
					accept="image/png,image/gif,image/jpeg"
					onChange={(e) => {
						console.log(e);
						uploadCustomImage((e.nativeEvent.target as HTMLInputElement).files?.item(0), false);
						e.target.value = "";
					}}
				/>
			</IonPage>
		);
	};

	render() {
		return (
			<Provider store={store}>
				<PersistGate loading={null} persistor={persistor}>
					<this.DiagramComponent />
				</PersistGate>
			</Provider>
		);
	}
}
const mapStateToProps = (state: any) => {
	return {
		fillNodeColors: state.maps.fillNodeColors,
		showImagesCaptions: state.maps.showImagesCaptions,
		isUppercase: state.maps.isUppercase,
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		toggleColorFill: () => {
			dispatch(mapsActions.toggleColorFill());
		},
		toggleImagesCaptions: () => {
			dispatch(mapsActions.toggleImagesCaptions());
		},
		toggleUppercase: () => {
			dispatch(mapsActions.toggleUppercase());
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(MindMap);
