import {
	IonAlert,
	IonButton,
	IonButtons,
	IonCol,
	IonContent,
	IonFab,
	IonGrid,
	IonIcon,
	IonImg,
	IonItem,
	IonLabel,
	IonLoading,
	IonModal,
	IonPage,
	IonPopover,
	IonSpinner,
	IonText,
	IonToast,
	IonToolbar,
	isPlatform
} from "@ionic/react";
import { Unsubscribe, addDoc, collection, deleteDoc, doc, getDoc, getDocs, query, setDoc, updateDoc, where, writeBatch } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { deleteObject, getDownloadURL, ref as storageRef, uploadBytes } from "firebase/storage";
import {
	addOutline,
	arrowDown,
	checkmark,
	checkmarkCircle,
	chevronBack,
	closeOutline,
	helpCircleOutline,
	timeOutline,
	volumeMediumOutline
} from "ionicons/icons";
import React, { createRef, RefObject } from "react";
import { isIOS, isMobile, isTablet } from "react-device-detect";
import { Trans } from "react-i18next";
import Joyride from "react-joyride";
import Lottie from "react-lottie";
import { connect } from "react-redux";
import { appVersion } from "../../../appVersion";
import processingAnimation from "../../../assets/animations/processing.json";
import ArrowDown from "../../../assets/icons/arrowDown.svg";
import ArrowUp from "../../../assets/icons/arrowUp.svg";
import ediText from "../../../assets/icons/editText.svg"; //!
import genSumm from "../../../assets/icons/generateSummary.svg"; //!
import keyWord from "../../../assets/icons/keyword.svg"; //!
import loading from "../../../assets/icons/loading.svg";
import MapsButtonLogo from "../../../assets/icons/map.svg"; //!
import reasyLogo from "../../../assets/icons/reasyLogo.svg";
import reasyRoundedLogo from "../../../assets/icons/reasyRoundedLogo.svg";
import voiceOff from "../../../assets/icons/speechdictionary.svg";
import trashBin from "../../../assets/icons/trashBin.svg";
import editTextOff from "../../../assets/icons/viewingMode.svg";
import voiceOn from "../../../assets/icons/voiceOn.svg";
import ZoomOut from "../../../assets/icons/zoomminus.svg";
import ZoomIN from "../../../assets/icons/zoomplus.svg";
import { sendEvent } from "../../../common/amplitudeUtils";
import { Step, handleJoyrideCallback, updateChoice } from "../../../common/utilsJoyride";
import { auth, firestore, functions, storage } from "../../../firebaseConfig";
import i18n from "../../../i18n";
import { loadPaywall } from "../../../loadPaywall";
import { mapsActions } from "../../maps/store/actions";
import ClickPopover from "../../popover/ClickPopover";
import SelectionPopover from "../../popover/SelectionPopover";
import { getTTS } from "../../popover/common/ttsServices";
import { Coloring, DocumentFontSettings, Highlight, UserSettings, fromBackendFontNameToCssFontFamily, settingsServices } from "../../settings/store/services";
import { TTSSettings } from "../../tts/store/reducers";
import { ttsServices } from "../../tts/store/services";
import FileViewerHeader from "../components/FileViewerHeader";
import TtsPlayer from "../components/TtsPlayer";
import { Document, DocumentElement } from "../store/reducers";
import { filesServices, makeId } from "../store/services";
import { calcolaToptts, handleTTSButtonPress, handleTTSLogic, handleTTSOptionChange, viewSummary } from "../utils/utils";
import "./FileViewer.css";
import { Organization } from "../../user/store/reducers";
import { argumentServices } from "../../sessions/store/services";
import { Summary } from "../../summaries/store/reducers";
import { summaryServices } from "../../summaries/store/services";
import { Argument, FileData } from "../../sessions/store/reducers";
import { checkStatusBar } from "../../sessions/utils/utils";

export let arraySummaryPage: any[] = [];

type ParagraphUpdate = {
	oldText: string;
	newText: string;
};

type Paragraph = {
	index: number;
	text: string | undefined;
	keywords:string|undefined;
};

type Props = {
	history: any;
	match: any;
	showTutorial: boolean;
	disabledButtons: string[];
	organizationName: string | null;
	orgData: Organization | null;
	disableMapButton: (uuid: string) => void;
	enableMapButton: (uuid: string) => void;
	summarySelected: (Sstate: boolean) => void;
	keyWordsSelected: (keyState: boolean) => void;
};

type State = {
	firstTitle: boolean;
	isDarkMode: boolean;
	document: Document | null;
	elementsTTS: DocumentElement[] | null;
	documentElements: DocumentElement[] | null;
	summaryDocumentElements: DocumentElement[] | null;
	summaryJson:any ;
	summaryIsClicked: boolean;
	makeSummary: boolean;
	summaryReadyMsg: boolean;
	originalFile: boolean;
	isKeyWordsOpen: boolean;
	typeSumOrKey: string;
	choice: boolean;
	fileUID: string;


	isLoadingFile: boolean;
	highlightings: Highlight[];
	colorings: Coloring[];

	fontSettings: DocumentFontSettings | undefined;
	userSettings: UserSettings | null;
	summaryState: boolean;
	keywordsState: boolean;
	isSummaryOpen: boolean;
	isGeneratingSummary: boolean;
	isGeneratingKeywords: boolean;
	infoMsg: boolean;
	//////
	userUuid: string;
	scrollOffsetY: number;
	unsubscribeOnFilesChanges: Unsubscribe | null;
	language: string | undefined;

	dictionaryIsOpen: boolean;

	showTtsPlayer: boolean;
	ttsParagraphPointer: number | null;
	audioToPlay: string | undefined;
	lastParagraphofPages: boolean;

	showClickPopover: boolean;
	clickedDocumentElement: DocumentElement | null;

	mapButtonDisabled: boolean;
	summButtonDisabled: boolean;
	keygenButtonDisabled: boolean;

	sectionSelectMode: boolean;
	sectionSelectModeSummary: boolean;
	sectionSelectModeMap: boolean;
	selectedSections: string[];

	showMapCreationStartToast: boolean;
	showMapTypeSelector: boolean;
	selectedMapType: "standard" | "keywords" | "";
	mapExists: boolean;
	mapIcon: string;
	isLoadingTTS: boolean;


	paragraphsUpdates: ParagraphUpdate[];
	showUpdateParagraphButton: boolean;

	initialParagraphs: Paragraph[];
	fileEditingMode: boolean;

	showPopoverButton: boolean | null;

	pageCounter: number;
	numberOfPages: number;
	startParagraphIndex: string;
	lastParagraphIndex: string;
	PageButtonDisabled: boolean;
	showMapCreationPopover: boolean;
	disablePlayButton: boolean;
	showModalSelection: boolean;

	showMapTextErrorAlert: boolean;
	disableTTSSkipButtons: boolean;
	disableTTSOptions: boolean;
	generatedAudios: {
		id: number;
		text: string;
		audio: string;
		speechMarks: any;
		settings: UserSettings;
	}[];
	keywordsReadyMsg: boolean;
	modifiedParagraphs: any;
	isLoading: boolean;
	loadingMessage: string;
	downloadUrl: string;
	showPdfReadyPopover: boolean;
	bearerToken: string;
	paywallState: boolean;
	speechMarks: any;
	summary:Summary | null;

	steps: Step[];
	tutorial: boolean;
	stepIndexFileViewer: number;
	tutorialMobile: boolean;
	selectedText: string | null;
	wordCustomImage: string | null;
	firstLoaderMessage: string | null;
	contentRef: any;
	howMuchScrolled: number;
	session:Argument|null;
	file_text:boolean;
	resourceId:string;


};


class SummaryOnlyViwer extends React.Component<Props, State> {
	showPopoverButton: RefObject<any>; // Explicitly declare the ref property

	constructor(props: any) {
		super(props);
		this.showPopoverButton = createRef();
		this.state = {
			firstTitle: false,
			isDarkMode: false,
			document: null,
			elementsTTS: null,
			documentElements: null,
			isLoadingFile: false,
			highlightings: [],
			colorings:[],
			userSettings:null,
			fontSettings: undefined,
			summaryState: false,
			keywordsState: false,
			isSummaryOpen: false,
			isGeneratingSummary: false,
			isGeneratingKeywords: false,
			makeSummary: false,
			infoMsg: false,
			summaryReadyMsg: false,
			summaryDocumentElements: null,
			summaryJson:null,
			summaryIsClicked: false,
			originalFile: false,
			isKeyWordsOpen: false,
			typeSumOrKey: "",
			choice: false,
			fileUID: "",
			/////
			userUuid: "",
			scrollOffsetY: 0,
			unsubscribeOnFilesChanges: null,
			speechMarks: null,

			dictionaryIsOpen: false,

			showTtsPlayer: false,
			ttsParagraphPointer: 0,
			audioToPlay: undefined,
			language: i18n.language,
			lastParagraphofPages: false,

			showClickPopover: false,
			clickedDocumentElement: null,
			mapButtonDisabled: false,
			mapExists: false,
			mapIcon: "",
			isLoadingTTS: false,

			summButtonDisabled: false,
			keygenButtonDisabled: false,

			sectionSelectMode: false,
			sectionSelectModeSummary: false,
			sectionSelectModeMap: false,
			selectedSections: [],

			showMapCreationStartToast: false,
			showMapTypeSelector: false,

			selectedMapType: "",
			paragraphsUpdates: [],

			showUpdateParagraphButton: false,
			initialParagraphs: [],
			fileEditingMode: false,
			showPopoverButton: null,
			pageCounter: 0,
			numberOfPages: 0,
			startParagraphIndex: "0",
			lastParagraphIndex: "0",
			PageButtonDisabled: false,
			showMapCreationPopover: false,
			disablePlayButton: false,
			showMapTextErrorAlert: false,
			disableTTSSkipButtons: false,
			disableTTSOptions: false,
			generatedAudios: [],
			keywordsReadyMsg: false,
			modifiedParagraphs: [],

			showModalSelection: false,
			isLoading: false,
			loadingMessage: "",
			downloadUrl: "",
			showPdfReadyPopover: false,
			bearerToken: "",
			paywallState: false,

			selectedText: null,
			wordCustomImage: null,
			summary:null,
			resourceId:"",

			steps: !isMobile
				? [
					{
						target: "#tts",
						content: (
							<div>
								<p>
									<Trans>Ascolta il testo</Trans>
								</p>
								<video className="tutorialVideo" autoPlay controls controlsList="nodownload">
									<source src="https://reasy-public-tutorials.s3.eu-south-1.amazonaws.com/v1.7.5/9.+La+sintesi+vocale.mp4" type="video/mp4" />
									Your browser does not support the video tag.
								</video>
							</div>
						),
						disableBeacon: true,
						placement: "bottom",
					},
					{
						target: "#conceptualMap",
						content: (
							<div>
								<p>
									<Trans>Crea una mappa concettuale con l'aiuto di Reasy</Trans>
								</p>
								<video className="tutorialVideo" autoPlay controls controlsList="nodownload">
									<source src="https://reasy-public-tutorials.s3.eu-south-1.amazonaws.com/v1.7.5/13.+Generazione+mappa+concettuale.mp4" type="video/mp4" />
									Your browser does not support the video tag.
								</video>
							</div>
						),
						disableBeacon: true,
						placement: "bottom",
					},
					{
						target: "#modifyDoc",
						content: (
							<div>
								<p>
									<Trans>Modifica il documento caricato</Trans>
								</p>
								<video className="tutorialVideo" autoPlay controls controlsList="nodownload">
									<source src="https://reasy-public-tutorials.s3.eu-south-1.amazonaws.com/v1.7.5/10.+Modifica+del+testo.mp4" type="video/mp4" />
									Your browser does not support the video tag.
								</video>
							</div>
						),
						disableBeacon: true,
						placement: "bottom",
					},
					{
						target: "#summary",
						content: (
							<div>
								<p>
									<Trans>Crea un riassunto con l'aiuto di Reasy</Trans>
								</p>
								<video className="tutorialVideo" autoPlay controls controlsList="nodownload">
									<source src="https://reasy-public-tutorials.s3.eu-south-1.amazonaws.com/v1.7.5/11.+Genera+riassunto.mp4" type="video/mp4" />
									Your browser does not support the video tag.
								</video>
							</div>
						),
						disableBeacon: true,
						placement: "bottom",
					},
					{
						target: "#keywords",
						content: (
							<div>
								<p>
									<Trans>Estrapola le parole chiave con l'aiuto di Reasy</Trans>
								</p>
								<video className="tutorialVideo" autoPlay controls controlsList="nodownload">
									<source src="https://reasy-public-tutorials.s3.eu-south-1.amazonaws.com/v1.7.5/12.Genera+parole+chiave.mp4" type="video/mp4" />
									Your browser does not support the video tag.
								</video>
							</div>
						),
						disableBeacon: true,
						placement: "bottom",
					},
					{
						target: ".divZoom",
						content: (
							<div>
								<p>
									<Trans>Controlli dimensione del documento</Trans>
								</p>
							</div>
						),
						disableBeacon: true,
						placement: "bottom",
					},
					{
						target: "#zoomIn",
						content: (
							<div>
								<p>
									<Trans>Aumenta la dimensione del documento</Trans>
								</p>
							</div>
						),
						disableBeacon: true,
						placement: "bottom",
					},
					{
						target: "#zoomOut",
						content: (
							<div>
								<p>
									<Trans>Diminuisci la dimensione del documento</Trans>
								</p>
							</div>
						),
						disableBeacon: true,
						placement: "bottom",
					},
					{
						target: ".divNextPrevious",
						content: (
							<div>
								<p>
									<Trans>Scorri le pagine del documento</Trans>
								</p>
							</div>
						),
						disableBeacon: true,
						placement: "bottom",
					},
					{
						target: ".kebabMenu",
						content: (
							<div>
								<p>
									<Trans>Scarica il documento in pdf</Trans>
								</p>
							</div>
						),
						disableBeacon: true,
						placement: "bottom",
					},
				]
				: [
					{
						target: "#div1",
						content: (
							<div>
								<p>
									<Trans>Crea una mappa concettuale con l'aiuto di Reasy</Trans>
								</p>
								<video className="tutorialVideo" autoPlay controls controlsList="nodownload">
									<source src="https://reasy-public-tutorials.s3.eu-south-1.amazonaws.com/v1.7.5/13.+Generazione+mappa+concettuale.mp4" type="video/mp4" />
									Your browser does not support the video tag.
								</video>
							</div>
						),
						disableBeacon: true,
						placement: "bottom",
					},

					{
						target: "#div2",
						content: (
							<div>
								<p>
									<Trans>Estrapola le parole chiave con l'aiuto di Reasy</Trans>
								</p>
								<video className="tutorialVideo" autoPlay controls controlsList="nodownload">
									<source src="https://reasy-public-tutorials.s3.eu-south-1.amazonaws.com/v1.7.5/12.Genera+parole+chiave.mp4" type="video/mp4" />
									Your browser does not support the video tag.
								</video>
								ì								</div>
						),
						disableBeacon: true,
						placement: "bottom",
					},
					{
						target: "#div3",
						content: (
							<div>
								<p>
									<Trans>Crea un riassunto con l'aiuto di Reasy</Trans>
								</p>
								<video className="tutorialVideo" autoPlay controls controlsList="nodownload">
									<source src="https://reasy-public-tutorials.s3.eu-south-1.amazonaws.com/v1.7.5/11.+Genera+riassunto.mp4" type="video/mp4" />
									Your browser does not support the video tag.
								</video>
								ì								</div>
						),
						disableBeacon: true,
						placement: "bottom",
					},
					{
						target: "#div4",
						content: (
							<div>
								<p>
									<Trans>Ascolta il testo</Trans>
								</p>
								<video className="tutorialVideo" autoPlay controls controlsList="nodownload">
									<source src="https://reasy-public-tutorials.s3.eu-south-1.amazonaws.com/v1.7.5/9.+La+sintesi+vocale.mp4" type="video/mp4" />
									Your browser does not support the video tag.
								</video>
								ì								</div>
						),
						disableBeacon: true,
						placement: "bottom",
					},
					{
						target: "#div5",
						content: (
							<div>
								<p>
									<Trans>Modifica il documento caricato</Trans>
								</p>
								<video className="tutorialVideo" autoPlay controls controlsList="nodownload">
									<source src="https://reasy-public-tutorials.s3.eu-south-1.amazonaws.com/v1.7.5/10.+Modifica+del+testo.mp4" type="video/mp4" />
									Your browser does not support the video tag.
								</video>
								ì								</div>
						),
						disableBeacon: true,
						placement: "bottom",
					},
					{
						target: ".kebabMenu",
						content: (
							<div>
								<p>
									<Trans>Scarica il documento in pdf</Trans>
								</p>
								ì								</div>
						),
						disableBeacon: true,
						placement: "bottom",
					},
				],
			tutorial: false,
			stepIndexFileViewer: 0,
			tutorialMobile: false,
			firstLoaderMessage: "",
			contentRef: null,
			howMuchScrolled: 0,
			session:null,
			file_text:false,


		};
		
	}

	selectionPopoverRef = createRef();
	clickPopoverRef = createRef();

	numberWsUnderline = 1;

	componentWillUnmount() {
		document.onselectionchange = function () { };
		arraySummaryPage = [];
		let audio = document.getElementById("audioElement");
		audio?.remove();
	}

	componentDidMount() {
		this.setState({ contentRef: createRef<HTMLIonContentElement>(), howMuchScrolled: 0 ,session:this.props.history.location.state?.session})

		if (isMobile) {
			document.getElementById("textgrid")?.classList.add("textgridmobile");
		} else if (!isMobile) {
			document.getElementById("textgrid")?.classList.add("textgridnotmobile");
		}
		document.getElementById("fileViewerTextDivContainer")!.hidden = true;
		// ////console.log('[FileViewer] File id:', this.props.match.params.fileId); // TO REMOVE

		auth.onAuthStateChanged(async (userData) => {
			if (userData) {
				// console.log("UUID: ", userData.uid)
				auth.currentUser?.getIdToken().then(async (token) => {
					this.setState({ bearerToken: token },()=>{
						let colorings:Coloring[]=[]
						let userSettings: UserSettings | null = null
						settingsServices.getColourings(userData.uid,this.state.bearerToken).then((response)=>{
							colorings=response
							settingsServices.getUserSettings(userData.uid,this.state.bearerToken).then((response)=>{
								userSettings=response
								this.setState({colorings:colorings,userSettings:userSettings},()=>this.areSummaryOrKeywords())
							})
							
						})
						
						

					});

					if (window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches) {
						this.setState({ isDarkMode: true });
					}

					const _this = this;
					if (!isMobile) {
						var selectionTimer: any = null;
						document.onselectionchange = function (e) {
							if (!_this.state.sectionSelectMode && !_this.state.fileEditingMode) {
								if (selectionTimer) {
									clearTimeout(selectionTimer);
								}
								selectionTimer = setTimeout(function () {
									_this.getTextSelection();
									selectionTimer = null;
								}, 500); // Wait 1s before calling getTextSelection()
							}
						};
					}
					this.setState({ userUuid: userData.uid });
					if (this.props.match.params.fileId) {
						this.setState({ isLoadingFile: true, fileUID: this.props.match.params.fileId });

						// filesServices
						// 	.getMainFileDataSummary(this.props.match.params.fileId)
						// 	.then(async (document) => {
						// 		// console.log("ASDADASDADA", auth.currentUser?.uid)
						// 		//console.log("AAAAA");

						// 		if (auth.currentUser?.uid !== document.owner) {
						// 			this.setState({ firstLoaderMessage: "Non sei proprietario di questo documento" });
						// 			// console.log("ASDADASDADA", document.owner)
						// 			return;
						// 		}

						// 		this.setState({
						// 			document: document,
						// 			isLoadingFile: false,
						// 			firstLoaderMessage: "Attendi giusto qualche secondo, stiamo processando il tuo documento...",
						// 		});
						// 		try {
						// 			// //console.log("[File Viewer] Started getting preferences");
						// 			const highlightings = await settingsServices.getHighlightings(userData.uid);
						// 			this.setState({
						// 				highlightings: highlightings,
						// 				//processedText: this.state.documentElements ? processText(this.state.documentElements, highlightings) : "",
						// 			});

						// 			const viewingSettings = await settingsServices.getDocumentViewingSettings(userData.uid);
						// 			this.setState({ fontSettings: viewingSettings });
						// 		} catch (e) {
						// 			console.error("[FileViewer] error getting highlighting preferences or viewing settings:", e);
						// 		}
						// 		if (document.name == undefined) {
						// 			this.setState({ firstTitle: true }, () => this.areSummaryOrKeywords())
						// 		} else {
						// 			this.areSummaryOrKeywords();
						// 		}
						// 		//console.log(this.state.elementsTTS);
						// 	})
						// 	.catch((err1) => {
						// 		console.error(`[FileViewer] get file error: ${JSON.stringify(err1)}`);
						// 		this.setState({
						// 			isLoadingFile: false,
						// 		});
						// 	});
						// argumentServices.getSummary(this.props.history.location.state.resourceId,token)

					}
					const paywallShowOrHide = await loadPaywall(false);
					this.setState({ paywallState: paywallShowOrHide });
				});
			} else {
				this.setState({ userUuid: "" });
			}
		});
	}
	areSummaryOrKeywords = async () => {
		let sessionContent:Summary |null=null
		let fileContent:FileData |null=null
		let content ={
			created_at:0,
				content: "",
				created_by:"",
				id:"",
		}
		if(this.props.history.location.state?.file_text === undefined ){
			const pathname = window.location.pathname;
			const resourceIdFromUrl = pathname.includes('summary/') 
				? pathname.split('summary/')[1] 
				: null;
			let resourceId=resourceIdFromUrl || this.props.history.location.state?.resourceId;
			this.setState({resourceId:resourceId})
			sessionContent=(this.props.history.location.state?.keywords || resourceIdFromUrl) ? await argumentServices.getKeywordsFromCollection(this.state.bearerToken,resourceId)
						:await summaryServices.getSummary(this.props.history.location.state?.resourceId,this.state.bearerToken)
		}
		else{
			this.setState({file_text:this.props.history.location.state.file_text})
			fileContent=await argumentServices.getUserFile(this.state.bearerToken,this.state.userUuid,this.props.history.location.state.file_id)
			content={
				created_at:fileContent!.uploaded_at,
				content: fileContent!.ocr_result?fileContent!.ocr_result :"",
				created_by:"",
				id:fileContent!.id,
			}
		}
		
		this.setState({summary:this.props.history.location.state?.file_text ? content:sessionContent},()=> viewSummary.call(this))

	};
	customImageFromGSUrltoUrl(gsUrl: string) {
		return new Promise<string>((resolve, reject) => {
			getDownloadURL(storageRef(storage, gsUrl))
				.then((url) => {
					this.setState({ wordCustomImage: url /* isLoadingImages: false, */ });
					resolve(url);
				})
				.catch((err) => {
					////console.log('[fromGSUrltoUrl] service error:', err);
					reject(err);
				});
		});
	}

	uploadCustomImage = (media: File | null | undefined, alreadyExists: boolean, text: string) => {
		if (media && this.state.userUuid && media.type.includes("image/")) {
			let selectedText = text;
			uploadBytes(storageRef(storage, `i/${this.state.userUuid}/${selectedText.trim()}/`), media)
				.then((data) => {
					//console.log("[Utils] upload success:", data.ref);
					this.customImageFromGSUrltoUrl(data.ref.fullPath).then(async (url) => {
						if (!alreadyExists) {
							console.log("NEW URL PUBLIC: ", url);
							await setDoc(doc(firestore, `i/${this.state.userUuid}/i/${makeId(16)}`), {
								w: selectedText,
								i: data.metadata.fullPath,
							})
								.then((response) => {
									console.log("[File Viewer] setting custom image doc response:", response);
									(this.selectionPopoverRef.current as any).addCustomImage(url);
									(this.clickPopoverRef.current as any).addCustomImage(url);
									this.setState({ wordCustomImage: url });
									// (nodes.filter(node => node.id === this.state.selectedNodeId)[0] as any).image = url
									// setNodes(nodes)
								})
								.catch((e) => {
									console.error("[File Viewer] error setting custom image doc:", e);
								});
						}
						// else {
						//     if (this.state.originalMedia.url === '') {
						//         //console.log("setting original media to ", currentStep.imageGsUrl);
						//         if (this.state.currentStep.imageGsUrl !== null) {
						//             this.setState({
						//                 originalMedia: {
						//                     type: 'image', url: currentStep.imageGsUrl
						//                 }
						//             })
						//         }
						//         else {
						//             this.setState({
						//                 originalMedia: {
						//                     type: 'video', url: currentStep.videoGsUrl
						//                 }
						//             })
						//         }
						//     }
						//     currentStep.videoGsUrl = null;
						//     this.state.uploadedMediaIds.push(data.ref.name)
						//     currentStep.imageGsUrl = url
						//     task.steps[task.steps.indexOf(this.state.currentStep)].imageGsUrl = url
						//     this.setState({ task: task, currentStep: currentStep })
						// }
					});
				})
				.catch((err) => {
					console.log("[MindMap] error uploading custom image", err);
				});
		}
	};
	async deleteCustomImage(url: string, text: string) {
		console.log("Delete image: ", url);
		if (url !== "" && url !== null) {
			//REMOVE EXISTING IMAGE
			let imageRef = await storageRef(storage, url);
			console.log("Found ref: ", imageRef);
			await deleteObject(imageRef)
				.then(async (response) => {
					console.log("Successfully removed custom image from storage: ", response);
					//also remove from firestore db
					const imageQuery = query(collection(firestore, `i/${this.state.userUuid}/i`), where("w", "==", text.toLowerCase().trim()));
					const querySnapshot = await getDocs(imageQuery);
					if (!querySnapshot.empty) {
						querySnapshot.forEach(async (doc) => {
							await deleteDoc(doc.ref)
								.then((response) => {
									(this.selectionPopoverRef.current as any).removeDeletedImage(url);
									(this.clickPopoverRef.current as any).removeDeletedImage(url);

									console.log("succesfully removed custom image firestore doc");
								})
								.catch((error) => {
									console.log("Error during custom image doc deletion: ", error);
								});
						});
					}
				})
				.catch((error) => {
					console.log("Error removing custom image from storage: ", error);
				});
		} else {
			document.getElementById("utilsFileInput")?.click();
		}
	}
	timer: any = null;
	debounce(func: any, delay: any) {
		let timeoutId: any;
		let _this = this;
		return function (...args: any) {
			clearTimeout(timeoutId);

			timeoutId = setTimeout(() => {
				func.apply(_this, args);
			}, delay);
		};
	}
	highlight(text: string, paragraph: any, documentElements: DocumentElement[], colorings: Coloring[]) {
		// Sort the highlightings array by the length of the target in descending order
		colorings.sort((a, b) => b.text_pattern.length - a.text_pattern.length);

		// Create a regular expression pattern for matching the highlight targets
		const highlightPattern = new RegExp(colorings.map((h) => h.text_pattern.toLowerCase()).join("|"), "gi");

		// Replace matching text with span elements for highlighting
		const highlightedText = text.replace(highlightPattern, (match) => {
			const highlighting = colorings.find((h) => h.text_pattern.toLowerCase() === match.toLowerCase());
			return `<span style="color:${highlighting?.color}">${match}</span>`;
		});

		// Set the paragraph's innerHTML to the highlighted text
		paragraph.innerHTML = highlightedText;

		return paragraph;
	}
	changeExistingSpans() {
		let currentParagraphIndex = this.state.ttsParagraphPointer;
		if (this.state.speechMarks) {
			let paragraphElement = document.getElementById(currentParagraphIndex!.toString());

			let existingSpans = paragraphElement?.childNodes;

			if (existingSpans && /^word-\d+-0-.+$/.test((existingSpans[0] as HTMLElement)?.id)) {
				/* PARAGRAPH HAS ALREADY BEEN FORMATTED*/
				return;
			}

			let currentIndex = 0;
			let text = this.state.isSummaryOpen
				? this.state.summaryDocumentElements![this.state.ttsParagraphPointer!].text.trim()
				: this.state.documentElements![this.state.ttsParagraphPointer!].text.trim();
			if (paragraphElement) {
				paragraphElement.innerHTML = "";
				this.state.speechMarks.forEach((speechMark: any, index: any) => {
					let smValue = /^word-\d+-\d+-.+$/.test(speechMark.value) ? speechMark.value.split("-")[3] : speechMark.value;
					// speechMark.value = speechMark.value.replace('\u00a0', ' ')
					// Find the start position of the current speechMark in the original text
					const start = text.indexOf(smValue, currentIndex);
					const end = start + smValue.length;

					// Create spans for the text before and within the current speechMark
					if (!isMobile && start > currentIndex) {
						let beforeSpan = document.createElement("span");
						beforeSpan = this.highlight(text.substring(currentIndex, start), beforeSpan, this.state.documentElements!, this.state.colorings!);
						// beforeSpan.textContent = text.substring(currentIndex, start);
						beforeSpan.id = `wsUnderline${this.numberWsUnderline++}`;

						paragraphElement!.appendChild(beforeSpan);
						//console.log(`BeforeSpan: ${beforeSpan.textContent}`);
					}

					let speechMarkSpan = document.createElement("span");
					const spanId = `word-${this.state.ttsParagraphPointer}-${index}-${smValue.replace(/[^\w\s\u00C0-\u00FF']|'\B|\B'/g, "")}`;
					speechMarkSpan = this.highlight(smValue.replace("\u00a0", " "), speechMarkSpan, this.state.documentElements!, this.state.colorings!);
					speechMarkSpan.id = spanId;
					// speechMarkSpan.textContent = speechMark.value.replace('\u00a0', ' ');
					paragraphElement!.appendChild(speechMarkSpan);
					isMobile && speechMarkSpan.appendChild(document.createTextNode(" "));
					//console.log(`SpeechMarkSpan: ${speechMarkSpan.textContent}, ID: ${spanId}`);
					// speechMarkSpan.appendChild(document.createTextNode(' '));

					// Update currentIndex to the end of the current speechMark
					currentIndex = end;
				});
				// Append the remaining text after the last speechMark
				if (currentIndex < text.length) {
					let afterSpan = document.createElement("span");
					afterSpan = this.highlight(text.substring(currentIndex), afterSpan, this.state.documentElements!, this.state.colorings!);
					afterSpan.id = `wsUnderline${this.numberWsUnderline++}`;
					// afterSpan.textContent = text.substring(currentIndex);
					paragraphElement!.appendChild(afterSpan);
				}
			}
		}
	}
	timeupdateListener: any = null;

	getKaraoke(documentElements: DocumentElement[]) {
		let speechMarksWithSpaces: any[] = [];
		let globalWordCounter = 0;
		let currentWordIndex = 0; // Tieni traccia dell'indice della parola corrente
		let audio = document.getElementById("audioElement") as HTMLAudioElement;
		let timeOfWord;
		let newSM: any[] = [];
		//console.log(("INIZIO: ", JSON.stringify(this.state.speechMarks));
		if (this.state.speechMarks) {
			this.state.speechMarks.forEach((sm: any, j: any) => {
				if (/^word-\d+-\d+-.+$/.test(sm.value)) {
					newSM.push(sm);
				} else {
					let normalizedSpeechMark = sm;
					normalizedSpeechMark.value = normalizedSpeechMark.value.replace(/[^\w\s\u00C0-\u00FF']|'\B|\B'/g, "");
					normalizedSpeechMark.value = `word-${this.state.ttsParagraphPointer}-${j}-${normalizedSpeechMark.value}`;
					newSM.push(normalizedSpeechMark);
				}
			});
		}
		//console.log(("FINISCO: ", this.state.speechMarks);

		//REMOVE ALL HIGHLIGHTINGS FROM OTHER PARAGRAPHS
		for (let i = 0; i < document.getElementsByTagName("p").length; i++) {
			const element = document.getElementsByTagName("p")[i];
			if (element.id !== this.state.ttsParagraphPointer!.toString()) {
				element.childNodes.forEach((node: any) => {
					if (node.nodeType === 1 && node.classList.contains("paragraphinReading")) {
						node.classList.remove("paragraphinReading");
					}
				});
			}
		}

		const removeEventListenerCallback = () => { };

		if (audio && newSM && newSM.length > 0) {
			let callbackFunc = () => this.updateAudio(audio, newSM, currentWordIndex);

			if (this.timeupdateListener) {
				audio.removeEventListener("timeupdate", this.timeupdateListener); // Remove existing listener
				this.timeupdateListener = null; // Reset listener flag

				audio.addEventListener("timeupdate", removeEventListenerCallback); // Add new listener with a unique callback

				// Re-add the listener with the updated callback function
				audio.addEventListener("timeupdate", callbackFunc);
				this.timeupdateListener = callbackFunc; // Update listener flag with the new callback
			} else {
				audio.addEventListener("timeupdate", callbackFunc);
				this.timeupdateListener = callbackFunc;
			}
		}
	}
	updateAudio(audio: any, newSM: any[], currentWordIndex: number): any {
		//console.log((document.getElementById('audioElement'));

		const currentTime = audio.currentTime * 1000; // Converti il tempo in millisecondi
		// Trova la parola corrente in base al tempo di riproduzione
		// let currentWordIndex = 0;
		while (currentWordIndex < newSM.length) {
			let timeOfWord = this.getTimeOfWord(currentWordIndex, newSM);
			// Aggiungi un margine temporale (ad esempio, 50 ms prima dell'inizio della parola)
			const margin = 0;
			// Estrai la parola associata all'ID dello span
			let currentWord = newSM[currentWordIndex].value;
			// Aggiorna la gestione del tempo per considerare un intervallo temporale
			if (currentTime + margin >= timeOfWord) {
				// Confronta direttamente con il contenuto degli speech marks

				let matchingSpeechMark = newSM.find((mark: any) => mark.value === currentWord);

				newSM.forEach((sm: any, i: any) => {
					document.getElementById(sm.value)?.classList.remove("paragraphinReading");
				});
				//console.log((matchingSpeechMark.value);

				// Se trovi una corrispondenza, puoi fare qualcosa con l'informazione (ad esempio, evidenziare)
				if (matchingSpeechMark) {
					document.getElementById(matchingSpeechMark.value)?.classList.add("paragraphinReading");
				}
				currentWordIndex++;
			} else {
				break;
			}
		}
		// Gestione degli errori per evitare problemi quando currentWordIndex supera la lunghezza dell'array
		// currentWordIndex = Math.min(currentWordIndex, wordsArray.length - 1);
	}
	handleParagraphChange(event: any, index: number) {
		console.log("FUNZIONA");
		const value = event.currentTarget.innerText;
		//Avoid triggering the save when clicking "ESC" or "ALT" ...
		const allowedKeys = ["Backspace", "Delete", "Enter", "Unidentified"];
		// console.log(event);

		const key = event.key;

		console.log(key);
		const isAllowedKey = key.length === 0 || allowedKeys.includes(key);
		if (!isAllowedKey) return;

		let modifiedParagraphs = this.state.modifiedParagraphs;
		let alreadyEdited = modifiedParagraphs.filter((p: any) => p.id === index).length > 0;

		if (alreadyEdited) {
			// Se il paragrafo è già stato modificato, aggiorna il testo
			let editedParagraph = modifiedParagraphs.filter((p: any) => p.id === index)[0];
			modifiedParagraphs[modifiedParagraphs.indexOf(editedParagraph)].text = value;
		} else {
			// Altrimenti, aggiungi il nuovo paragrafo modificato
			modifiedParagraphs.push({ id: index, text: value });
		}
		this.setState({ modifiedParagraphs: modifiedParagraphs });
		this.handleSave();
	}
	getTimeOfWord(index: number, mergedSpeechMarks: any) {
		if (index < mergedSpeechMarks.length) {
			// Aggiungi questo controllo
			return mergedSpeechMarks[index].time;
		} else {
			// Ritorna un valore predefinito o gestisci il caso in modo appropriato
			return 0;
		}
	}
	handleSave = this.debounce(async () => {
		// console.log("generated audios ", this.state.generatedAudios);
		const batch = writeBatch(firestore);
		this.state.modifiedParagraphs.forEach((modifiedParagraph: any) => {
			console.log("SAVING");
			// console.log(modifiedParagraph);
			let generatedAudios = this.state.generatedAudios;
			let genAudiosFilter = generatedAudios.filter((audio) => audio.id === modifiedParagraph.id);
			if (genAudiosFilter.length > 0) {
				// console.log("E' STATO MODIFICATO UN PARAGRAFO CON AUDIO");
				generatedAudios.splice(genAudiosFilter.indexOf(genAudiosFilter[0]), 1);
				this.setState({ generatedAudios: generatedAudios }, () => {
					// console.log("REMOVED AUDIO -> ", this.state.generatedAudios);
				});
			}
			let docEls = this.state.summaryDocumentElements;
			let paragraphUuid: string | undefined;

			paragraphUuid = this.state.summaryDocumentElements?.filter((el) => el.index === modifiedParagraph.id)[0].uuid;
			docEls?.forEach((docEl) => {
				if (docEl.index === modifiedParagraph.id) {
					docEl.text = modifiedParagraph.text;
				}
			});

			this.setState({ summaryDocumentElements: docEls }, () => {
				// console.log("modified doc els: ", this.state.summaryDocumentElements);
			});
			const docRef = doc(firestore, `s/${this.state.document!.uuid}/s/${paragraphUuid}`);
			// console.log("PAROLE CHIAVE", this.state.isKeyWordsOpen);
			if (!this.state.isKeyWordsOpen) {
				batch.update(docRef, {
					d: this.state.document!.uuid,
					p: modifiedParagraph.text,
				});
			} else {
				modifiedParagraph.text = modifiedParagraph.text.replace(/\n\n|\n/g, ",");
				batch.update(docRef, {
					d: this.state.document!.uuid,
					k: modifiedParagraph.text,
				});
			}
		});
		await batch.commit();

		//resets array to avoid sending the same data every time
		this.setState({ modifiedParagraphs: [] });
	}, 2000);

	// handleParagraphFocusOut(paragraph: HTMLParagraphElement, documentElements: DocumentElement[], i: number) {
	//     // let index = i

	//     //INDEX IS ON PARAGRAPH.ID
	//     // let index = Number(documentElements[i].index)
	//     return
	//     let index = Number(paragraph.id)

	//     // console.log("paragraph: ", paragraph);
	//     let tempInitialParagraphs = this.state.initialParagraphs;
	//     console.log("temp initial paragraphs: ", tempInitialParagraphs);

	//     let selectedParagraph = tempInitialParagraphs.filter(p => p.index === index)[0]
	//     // console.log("selectedParagraph 1: ", selectedParagraph);
	//     // console.log("selectedParagraph 2: ", paragraph);
	//     this.state.initialParagraphs.splice(this.state.initialParagraphs.indexOf(selectedParagraph), 1)
	//     tempInitialParagraphs.push({
	//         index: index,
	//         text: paragraph.innerText
	//     })
	//     // console.log("tempInitialParagraphs: ", tempInitialParagraphs);
	//     this.setState({ initialParagraphs: tempInitialParagraphs }, () => {
	//         // console.log(this.state.initialParagraphs);
	//     })

	//     //UPDATE DB
	//     /* const docRef = this.state.isSummaryOpen ? doc(firestore, `s/${this.state.document!.uuid}/s/${documentElements[i].uuid}`)
	//         : this.state.isKeyWordsOpen ? doc(firestore, `s/${this.state.document!.uuid}/s/${documentElements[i].uuid}`)
	//             : doc(firestore, `f/${this.state.document!.uuid}/d/${documentElements[i].uuid}`)
	//     // console.log("PARAGRAPH ID ", documentElements[i].uuid)
	//     if (this.state.isKeyWordsOpen) {
	//         let keyWordUpdate = paragraph.innerText.replace(/\n\n|\n/g, ',')
	//         updateDoc(docRef, {
	//             d: this.state.document!.uuid,
	//             i: 0, //INDEX is saved inside paragraph id
	//             k: keyWordUpdate,
	//         })
	//     } else {
	//         updateDoc(docRef, {
	//             d: this.state.document!.uuid,
	//             i: index, //INDEX is saved inside paragraph id
	//             p: paragraph.innerText,
	//         })
	//     } */
	// }

	// getpages(pages: any, highlightings: any, userSettings: any) {
	// 	let charcounter = 0;
	// 	let pagecounter = 0;

	// 	for (let i = 0; i < pages.length; i++) {
	// 		//console.log(('caratteri paragrafo', pages[i].innerText.length);
	// 		if (pages[i].innerText.length + charcounter < 4000) {
	// 			//console.log((pages[i])
	// 			arraySummaryPage[pagecounter].appendChild(pages[i]);
	// 			charcounter = charcounter + pages[i].innerText.length;
	// 			//console.log((charcounter)
	// 		} else {
	// 			pagecounter++;
	// 			charcounter = 0;
	// 			arraySummaryPage[pagecounter] = document.createElement("div");
	// 			arraySummaryPage[pagecounter].classList.add("fileViewerTextSelectable");
	// 			if (userSettings) {
	// 				arraySummaryPage[pagecounter].style.fontFamily = fromBackendFontNameToCssFontFamily(userSettings.font_name);
	// 				arraySummaryPage[pagecounter].style.fontSize = `${userSettings.font_size}px`;
	// 				arraySummaryPage[pagecounter].style.lineHeight = `${100 + userSettings.line_height}%`;
	// 			}
	// 			arraySummaryPage[pagecounter].appendChild(pages[i]);
	// 		}
	// 	}
	// 	this.setState({ originalFile: true });
	// }



	getTextSelection() {
		if (window.getSelection) {
			const gotSelection = window.getSelection();
			try {
				if (gotSelection) {
					// OR
					// const lngDetector = new (require('languagedetect'));
					if (gotSelection.rangeCount > 0 && gotSelection.toString().trim() !== "") {
						
						console.log("asdas", gotSelection.toString());

						// //console.log(franc(gotSelection.toString(), { only: ['ita', 'fra', 'spa', 'deu'] })); // TO REMOVE

						(this.selectionPopoverRef.current as any).changeSelectedText(gotSelection.toString());
						(this.selectionPopoverRef.current as any).setSelection(gotSelection, gotSelection.toString(), true);
						// (this.selectionPopoverRef.current as any).showPopover(true);
						this.setState({ dictionaryIsOpen: true });
					} else {
						this.setState({ dictionaryIsOpen: false });
						//empty dictionary meanings arraySummaryPage
						//ciaone

						(this.selectionPopoverRef.current as any).eraseDictionaryMeanings();

						// (this.showPopoverButton.current as any).showPopoverButton(false);
						(this.selectionPopoverRef.current as any).changeSelectedText(null);
						(this.selectionPopoverRef.current as any).setSelection(null, null, false);
					}
				}
			} catch (err) {
				console.error(err);
			}
		} else {
			(this.showPopoverButton.current as any).showPopoverButton(false);
			(this.selectionPopoverRef.current as any).changeSelectedText(null);
			(this.selectionPopoverRef.current as any).setSelection(null);
		}
		// //console.log((this.showPopoverButton.current as any));
	}
	async removeParagraph(paragraph: any) {
		this.setState({
			isLoading: true,
			loadingMessage:
				i18n.language === "it"
					? "Cancello il paragrafo..."
					: i18n.language === "en"
						? "Deleting the paragraph..."
						: i18n.language === "es"
							? "Borrando el párrafo"
							: i18n.language === "fr"
								? "Suppression du paragraphe"
								: i18n.language === "ca"
									? "Esborro el paràgraf..."
									: "den Absatz löschen",
		});
		let elToRemove = this.state.summaryDocumentElements!.filter((el) => el.index === Number(paragraph.id))[0];
		let indexToRemove = elToRemove.index;
		let tempElements = this.state.summaryDocumentElements;
		if (tempElements?.indexOf(elToRemove) === tempElements!.length - 1) {
			//LAST INDEX IS REMOVED, DONT DO ANYTHING
		} else if (tempElements?.indexOf(elToRemove) === 0) {
			//FIRST INDEX IS REMOVED, SHIFT ALL PARAGRAPH INDEXES BY -1
			tempElements?.forEach((el) => {
				if (el.index > indexToRemove) {
					el.index--;
				}
			});
		} else {
			//INDEX IN THE MIDDLE IS REMOVED, SHIFT ALL NEXT PARAGRAPH INDEXES BY -1
			tempElements?.forEach((el) => {
				if (el.index > indexToRemove) {
					el.index--;
				}
			});
		}
		if (this.state.ttsParagraphPointer === indexToRemove) this.setState({ ttsParagraphPointer: 0 });
		//console.log("EL TO REMOVE: ", elToRemove.index);

		tempElements?.splice(tempElements!.indexOf(elToRemove), 1);
		//console.log(tempElements);

		tempElements!.forEach((el) => {
			let docRef = doc(firestore, `s/${this.state.document!.uuid}/s/${el.uuid}`);
			updateDoc(docRef, {
				i: el.index,
			});
		});
		let docRef = doc(firestore, `s/${this.state.document!.uuid}/s/${elToRemove.uuid}`);
		await deleteDoc(docRef).then(() => {
			this.setState({ documentElements: tempElements, isLoading: false, loadingMessage: "" }, () => {
				document.getElementById(paragraph.id)!.parentElement!.remove();
				let paragraphArr = document.getElementsByClassName("fileViewerParagraph");
				for (let i = 0; i < paragraphArr.length; i++) {
					const paragraph = paragraphArr[i] as HTMLElement;
					paragraph.id = tempElements![i].index.toString();
				}
				// this.processText(this.state.documentElements!, this.state.highlightings, this.state.fontSettings)
			});
		});
	}
	async addNewParagraph(paragraph: any) {
		let previousEl: any;
		let previousIndex: any;
		if (paragraph) {
			previousEl = this.state.summaryDocumentElements!.filter((el) => el.index === Number(paragraph.id))[0];
			previousIndex = previousEl.index;
		} else {
			previousIndex = -1;
		}

		let tempElements = this.state.summaryDocumentElements;
		if (tempElements?.indexOf(previousEl!) === tempElements!.length - 1) {
			//LAST INDEX, JUST ADD A NEW PARAGRAPH
		} else if (tempElements?.indexOf(previousEl!) === 0 || paragraph === null) {
			//FIRST INDEX, SHIFT ALL PARAGRAPH INDEXES BY +1
			tempElements?.forEach((el) => {
				if (el.index > previousIndex) {
					el.index++;
				}
			});
			this.setState({ ttsParagraphPointer: this.state.ttsParagraphPointer! + 1 });
		} else {
			//INDEX IN THE MIDDLE IS REMOVED, SHIFT ALL NEXT PARAGRAPH INDEXES BY +1
			tempElements?.forEach((el) => {
				if (el.index > previousIndex) {
					el.index++;
				}
			});
			this.setState({ ttsParagraphPointer: this.state.ttsParagraphPointer! + 1 });
		}
		tempElements!.push({
			documentId: "test",
			index: previousIndex + 1,
			text:
				i18n.language === "en"
					? "New paragraph"
					: i18n.language === "es"
						? "Nuevo párrafo"
						: i18n.language === "fr"
							? "Nouveau paragraphe"
							: i18n.language === "it"
								? "Nuovo paragrafo"
								: i18n.language === "ca"
									? "Nou paràgraf"
									: "neuer Absatz",
			uuid: "random_uuid",
			keyWord: "",
		});
		let collectionRef = collection(firestore, `s/${this.state.document!.uuid}/s`);

		let newDBParagraph = await addDoc(collectionRef, {
			d: this.state.document!.uuid,
			i: previousIndex + 1,
			p:
				i18n.language === "en"
					? "New paragraph"
					: i18n.language === "es"
						? "Nuevo párrafo"
						: i18n.language === "fr"
							? "Nouveau paragraphe"
							: i18n.language === "it"
								? "Nuovo paragrafo"
								: i18n.language === "ca"
									? "Nou paràgraf"
									: "neuer Absatz",
		});
		tempElements!.forEach((el) => {
			if (el.index > previousIndex) {
				let docRef = doc(firestore, `s/${this.state.document!.uuid}/s/${el.uuid}`);
				if (el.index === previousIndex + 1) {
					el.documentId = this.state.document!.uuid;
					el.text =
						i18n.language === "en"
							? "New paragraph"
							: i18n.language === "es"
								? "Nuevo párrafo"
								: i18n.language === "fr"
									? "Nouveau paragraphe"
									: i18n.language === "it"
										? "Nuovo paragrafo"
										: i18n.language === "ca"
											? "Nou paràgraf"
											: "neuer Absatz";
					el.uuid = newDBParagraph.id;
					//we already set the index on the db when adding the element
					return;
				}
				updateDoc(docRef, {
					i: el.index,
				});
			}
		});
		// //console.log("UPDATED ELEMENTS ", tempElements);
		tempElements!.sort((a: any, b: any) => a.index - b.index);
		//console.log(tempElements);

		this.setState({ documentElements: tempElements }, () => {
			let previousParagraph;
			let newParagraph: any;
			if (paragraph) {
				previousParagraph = document.getElementById(paragraph.id)!.parentElement;
				newParagraph = document.getElementById(paragraph.id)!.cloneNode(true) as HTMLParagraphElement;
			} else {
				newParagraph = document.getElementById("0")!.cloneNode(true) as HTMLParagraphElement;
				document.getElementById("0")?.parentElement?.insertAdjacentElement("beforebegin", newParagraph);
			}
			newParagraph.innerText =
				i18n.language === "en"
					? "New paragraph"
					: i18n.language === "es"
						? "Nuevo párrafo"
						: i18n.language === "fr"
							? "Nouveau paragraphe"
							: i18n.language === "it"
								? "Nuovo paragrafo"
								: i18n.language === "ca"
									? "Nou paràgraf"
									: "neuer Absatz";

			previousParagraph?.insertAdjacentElement("afterend", newParagraph);
			newParagraph.id = (previousIndex + 1).toString();
			// newParagraph.addEventListener('focusout', () => this.handleParagraphFocusOut(newParagraph, tempElements!, Number(newParagraph.id)));
			newParagraph.addEventListener("keyup", (event: any) => this.handleParagraphChange(event, Number(newParagraph.id)));
			newParagraph.onclick = () => {
				// if (!isMobile)
				//     newParagraph.onkeyup = (event: any) => {
				//         this.handleParagraphChange(event, Number(newParagraph.id))
				//     }

				if (this.state.fileEditingMode) {
					// paragraph.contentEditable = 'true';
				} else if (this.state.sectionSelectMode) {
					newParagraph.contentEditable = "false";
					let selectedSections = this.state.selectedSections;
					// //console.log(selectedSections);
					let checkBox = document.getElementById(`check-${newParagraph.id}`);
					let tickIcon = document.createElement("ion-icon");
					tickIcon.icon = checkmark;
					tickIcon.className = "tickIcon";
					if (selectedSections.includes(newParagraph.innerText) && newParagraph.style.backgroundColor) {
						newParagraph.style.color = "unset";
						newParagraph.style.backgroundColor = "";
						if (checkBox) {
							checkBox.style.backgroundColor = "";
							checkBox.style.width = "30px"; //this will recreate the margin between checkbox and paragraph
							checkBox.style.border = "1px solid";
							checkBox.style.borderRadius = "6px";
							checkBox.removeChild(checkBox.firstElementChild!);
						}
						selectedSections.splice(selectedSections.indexOf(newParagraph.innerText), 1);
					} else {
						if (!this.state.isDarkMode) newParagraph.style.color = "#fff";
						if (selectedSections.includes(newParagraph.innerText)) {
							this.setState({ showMapTextErrorAlert: true });
							return;
						}
						if (
							(selectedSections.length === 15 && this.state.sectionSelectModeMap === true) ||
							(selectedSections.length === 15 && this.state.sectionSelectModeSummary === true)
						) {
							return;
						}
						if (checkBox) {
							if (!this.state.isDarkMode) {
								//change paragraph text to white
								//change tick icon to white
								newParagraph.style.color = "#fff";
								tickIcon.color = "light";
							}
							checkBox.style.backgroundColor = "#3626A7";
							checkBox.style.border = "0px";
							newParagraph.style.borderRadius = "0px 6px 6px 6px";
							checkBox.style.borderRadius = "6px 0px 0px 6px";
							checkBox.style.width = "40px"; //this will fill the margin between checkbox and paragraph
							checkBox.appendChild(tickIcon);
						}
						selectedSections.push(newParagraph.innerText);
						newParagraph.style.backgroundColor = "#3626A7";
					}
					this.setState({ selectedSections: selectedSections });
				} else {
					if (isMobile)
						this.setState({
							showClickPopover: true,
							clickedDocumentElement: tempElements![Number(newParagraph.id)],
						});
				}
			};

			this.addEditingButtons(newParagraph);
			let paragraphArr = document.getElementsByClassName("fileViewerParagraph");
			//console.log(paragraphArr);

			for (let i = 0; i < paragraphArr.length; i++) {
				const paragraph = paragraphArr[i] as HTMLElement;
				paragraph.id = i.toString();
			}
		});
	}
	addEditingButtons(el: any) {
		let wrapper = document.createElement("div");
		wrapper.id = "paragraphWrapper";

		let removeIcon = document.createElement("ion-icon");
		removeIcon.src = trashBin;
		removeIcon.className = "removeImage";
		removeIcon.slot = "icon-only";

		let removeBtn = document.createElement("ion-button");
		removeBtn.id = "removeBtn";
		removeBtn.className = "btn-fileviewer btn-background";
		removeBtn.shape = "round";
		removeBtn.addEventListener("click", () => this.removeParagraph(el));
		removeBtn.appendChild(removeIcon);

		wrapper.appendChild(removeBtn);
		el.parentNode?.insertBefore(wrapper, el);
		wrapper.appendChild(el);

		let addIcon = document.createElement("ion-icon");
		addIcon.src = addOutline;
		addIcon.className = "addImage";
		addIcon.slot = "icon-only";

		let addButton = document.createElement("ion-button");
		addButton.id = "addBtn";
		addButton.className = "btn-fileviewer btn-background";
		addButton.shape = "round";
		addButton.addEventListener("click", () => this.addNewParagraph(el));
		addButton.appendChild(addIcon);

		let joinIcon = document.createElement("ion-icon");
		joinIcon.src = arrowDown;
		joinIcon.className = "addImage";
		joinIcon.slot = "icon-only";

		wrapper.appendChild(addButton);
	}

	editingModeClicked() {
		this.setState({ fileEditingMode: !this.state.fileEditingMode, sectionSelectMode: false, selectedSections: [], selectedMapType: "" });
		// (document.getElementById('expandableMenuCheckbox') as HTMLInputElement).checked = false
		//REMOVE SELECTION AND THE ANALYZE BUTTON
		(this.showPopoverButton.current as any).showPopoverButton(false);
		(this.selectionPopoverRef.current as any).changeSelectedText(null);
		(this.selectionPopoverRef.current as any).setSelection(null);

		if (!document.getElementById("addFirstParagraphBtn")) {
			let addInitialParagraphBtn = document.createElement("ion-button");
			addInitialParagraphBtn.id = "addFirstParagraphBtn";
			addInitialParagraphBtn.textContent =
				i18n.language === "en"
					? "Add paragraph"
					: i18n.language === "es"
						? "Añadir párrafo"
						: i18n.language === "fr"
							? "Ajouter un paragraphe"
							: i18n.language === "it"
								? "Aggiungi paragrafo"
								: i18n.language === "ca"
									? "Afegeix paràgraf"
									: "Absatz hinzufügen";
			addInitialParagraphBtn.className = "addFirstParagraph";
			addInitialParagraphBtn.onclick = () => {
				this.addNewParagraph(null);
			};
			document
				.getElementById("fileViewerTextDivContainer")
				?.insertBefore(addInitialParagraphBtn, document.getElementById("fileViewerTextDivContainer")!.firstChild);
		}

		let paragraphs = this.state.isKeyWordsOpen ? document.getElementsByClassName("keyWordsContainer") : document.getElementsByClassName("fileViewerParagraph");
		for (let i = 0; i < paragraphs.length; i++) {
			let el = paragraphs[i] as HTMLElement;
			el.contentEditable = el.contentEditable === "true" ? "false" : "true";
			el.style.backgroundColor = "";

			if (el.contentEditable === "true") {
				this.addEditingButtons(el);
			} else {
				document.getElementById("paragraphWrapper")?.replaceWith(el);
				document.getElementById("addFirstParagraphBtn")?.remove();
			}
		}
	}

	popoverButtonClicked() {
		if (this.state.sectionSelectMode) return;
		(this.showPopoverButton.current as any).showPopoverButton(false);
		(this.selectionPopoverRef.current as any).setSelection(window.getSelection()!.toString());
		(this.selectionPopoverRef.current as any).searchImages();
		(this.selectionPopoverRef.current as any).showPopover(true);
	}

	async mapButtonClicked() {
		if (this.state.document && this.state.document.uuid) {
			await getDoc(doc(firestore, `m/${this.state.document?.uuid}/`)).then((documentSnapshot) => {
				if (documentSnapshot.exists()) {
					this.props.history.push({
						pathname: "/maps",
						state: {
							docTitle: this.state.document?.name,
							documentData: documentSnapshot.data(),
							docId: this.state.document!.uuid,
							paragraphs: this.state.documentElements,
						},
					});
				} else {
					this.setState({ sectionSelectMode: true, sectionSelectModeMap: true /* showMapCreationConfirm: true */ });
					let paragraphs = document.getElementsByTagName("p");
					for (let i = 0; i < paragraphs.length; i++) {
						const p = paragraphs[i];
						let checkBox = document.createElement("div");
						checkBox.className = "squareSelector";
						checkBox.id = "check-" + p.id;
						p.style.marginLeft = "40px";
						checkBox.onclick = p.onclick;
						p.parentNode!.insertBefore(checkBox, p);
					}
				}
			});
		}
	}
	async deleteDrawing(url: string, text: string) {
		//console.log("Delete image: ", url);
		if (url !== "" && url !== null) {
			//REMOVE EXISTING DRAWING
			let imageRef = await storageRef(storage, url);
			//console.log("Found ref: ", imageRef);
			await deleteObject(imageRef)
				.then(async (response) => {
					//console.log("Successfully removed custom image from storage: ", response);
					//also remove from firestore db
					const imageQuery = query(collection(firestore, `d/${auth.currentUser?.uid}/d`), where("w", "==", text.toLowerCase().trim()));
					const querySnapshot = await getDocs(imageQuery);
					if (!querySnapshot.empty) {
						querySnapshot.forEach(async (doc) => {
							await deleteDoc(doc.ref)
								.then((response) => {
									(this.selectionPopoverRef.current as any).setState({ drawingUrl: null });
									(this.clickPopoverRef.current as any).removeDeletedDrawing(url);
								})
								.catch((error) => {
									//console.log("Error during custom image doc deletion: ", error);
								});
						});
					}
				})
				.catch((error) => {
					//console.log("Error removing custom image from storage: ", error);
				});
		} else {
			document.getElementById("utilsFileInput")?.click();
		}
	}


	showFileViewerPlayer(show: boolean) {
		if (!show) {
			this.numberWsUnderline = 1;
			this.setState({ generatedAudios: [], speechMarks: null, originalFile: false }, () => {
				viewSummary.call(this);
			});
		}
		this.setState({ showTtsPlayer: show });
		if (this.state.userUuid && !this.state.showTtsPlayer) {
			let docElements: DocumentElement[] | null;
			if (this.state.isSummaryOpen) {
				//console.log("SUMMARU")
				if (!isMobile) calcolaToptts.call(this);
				docElements = this.state.summaryDocumentElements;
			} else docElements = this.state.documentElements;
		}
	}

	
	PageMinus() {
		let appoggio = this.state.pageCounter - 1;
		if (!this.state.showTtsPlayer) {
			let A = document.getElementsByClassName("paragraphinReading");
			for (let i = 0; i < A.length; i++) {
				A[i].classList.remove("paragraphinReading");
			}
		}
		this.setState({ pageCounter: appoggio });
		let firstparagraphid = arraySummaryPage[appoggio].firstChild.id;
		this.setState({ startParagraphIndex: firstparagraphid });

		let lastparagraphid = arraySummaryPage[appoggio].lastChild.id;
		this.setState({ lastParagraphIndex: lastparagraphid });

		this.setState({ ttsParagraphPointer: Number(firstparagraphid) });

		//console.log('pagecounter' + this.state.pageCounter, 'primo' + this.state.startParagraphIndex, 'ultimo' + this.state.lastParagraphIndex, 'ttspointer' + this.state.ttsParagraphPointer);

		(document.getElementById("fileViewerTextDivContainer") as HTMLDivElement)?.replaceChildren(arraySummaryPage[appoggio]);
	}
	PagePlus() {
		let appoggio = this.state.pageCounter + 1;
		//console.log(appoggio);

		if (this.state.showTtsPlayer) {
			let A = document.getElementsByClassName("paragraphinReading");
			for (let i = 0; i < A.length; i++) {
				A[i].classList.remove("paragraphinReading");
			}
		}
		this.setState({ pageCounter: appoggio });

		let firstparagraphid = arraySummaryPage[appoggio].firstChild.id;

		this.setState({ startParagraphIndex: firstparagraphid });

		let lastparagraphid = arraySummaryPage[appoggio].lastChild.id;
		this.setState({ lastParagraphIndex: lastparagraphid });

		this.setState({ ttsParagraphPointer: firstparagraphid });

		//console.log('pagecounter' + this.state.pageCounter, 'primo' + this.state.startParagraphIndex, 'ultimo' + this.state.lastParagraphIndex, 'ttspointer' + this.state.ttsParagraphPointer);
		//console.log('adding', String(firstparagraphid));
		document.getElementById(String(firstparagraphid))?.classList.add("paragraphinReading");

		(document.getElementById("fileViewerTextDivContainer") as HTMLDivElement)?.replaceChildren(arraySummaryPage[appoggio]);
	}

	createConceptualMaps = async () => {
		let documentParagraphs = document.getElementsByClassName("fileViewerParagraph");
		let checkBoxes = document.querySelectorAll('[id^="check-"]');
		let text = "";
		// let bearerToken
		if (this.state.sectionSelectModeMap) {
			this.state.selectedSections.forEach((section) => {
				text += " " + section;
			});
		} else {
			this.state.documentElements?.forEach((docEl) => {
				text = text + " " + docEl.text;
			});
		}
		if (text.length > 2950) {
			i18n.language === "it"
				? alert("Il testo selezionato è troppo lungo")
				: i18n.language === "en"
					? alert("The selected text is too long")
					: i18n.language === "es"
						? alert("El texto seleccionado es demasiado largo")
						: i18n.language === "fr"
							? alert("Le texte sélectionné est trop long")
							: i18n.language === "ca"
								? alert("El text seleccionat és massa llarg")
								: alert("Der ausgewählte Text ist zu lang");

			for (let i = 0; i < documentParagraphs.length; i++) {
				let el = documentParagraphs[i] as HTMLElement;
				el.style.backgroundColor = "";
			}
			this.setState({ sectionSelectMode: false, sectionSelectModeMap: false, selectedSections: [] });
			return;
		}
		checkBoxes.forEach((el) => el.remove());
		for (let i = 0; i < documentParagraphs.length; i++) {
			let el = documentParagraphs[i] as HTMLElement;
			el.style.backgroundColor = "";
			el.style.color = "var(--txt-color)";
		}
		auth.currentUser
			?.getIdToken()
			.then((token) => {
				// bearerToken = token

				this.setState({ selectedSections: [], sectionSelectMode: false, sectionSelectModeMap: false, showMapCreationStartToast: true });
				this.props.disableMapButton(this.state.document!.uuid);
				this.setState({ mapIcon: timeOutline });
				// //console.log("WHOLE TEXT:\n", text)
				filesServices.getConceptualMap(text, token, this.state.document!.uuid, this.state.userUuid).then((response) => {
					this.setState({ mapButtonDisabled: false });
					if (response == "success") {
						this.setState({ mapIcon: MapsButtonLogo });
						////console.log("SET DOC COMPLETED")
						// setTimeout(async () => {
						getDoc(doc(firestore, `m/${this.state.document?.uuid}/`)).then((documentSnapshot) => {
							////console.log("GET DOC COMPLETED")
							this.props.enableMapButton(this.state.document!.uuid);
							this.setState({ showMapCreationStartToast: false });
							let toast = document.createElement("ion-toast");
							toast.message =
								i18n.language === "it"
									? "La tua mappa è pronta!"
									: i18n.language === "en"
										? "Your map is ready!"
										: i18n.language === "es"
											? "Su mapa está listo"
											: i18n.language === "fr"
												? "Votre carte est prête"
												: i18n.language === "ca"
													? "La teva mapa està llesta!"
													: "Ihre Karte ist fertig!";

							toast.color = "success";
							toast.buttons = [
								{
									text:
										i18n.language === "it"
											? "Vai"
											: i18n.language === "en"
												? "Go"
												: i18n.language === "es"
													? "Ir"
													: i18n.language === "fr"
														? "Aller"
														: i18n.language === "ca"
															? "Ves"
															: "Gehen",
									handler: () => {
										if (window.location.pathname === "/maps") {
											this.props.history.push("/");
											this.props.history.push({
												pathname: "/maps",
												state: {
													docTitle: this.state.document?.name,
													documentData: documentSnapshot.data(),
													docId: this.state.document!.uuid,
													paragraphs: this.state.documentElements,
												},
											});
										} else {
											this.props.history.push({
												pathname: "/maps",
												state: {
													docTitle: this.state.document?.name,
													documentData: documentSnapshot.data(),
													docId: this.state.document!.uuid,
													paragraphs: this.state.documentElements,
												},
											});
										}
									},
								},
								{
									text:
										i18n.language === "it"
											? "Non ora"
											: i18n.language === "en"
												? "Not now"
												: i18n.language === "es"
													? "Ahora no"
													: i18n.language === "fr"
														? "Pas maintenant"
														: i18n.language === "ca"
															? "Ara no"
															: "nicht jetzt",
									handler: () => {
										toast.dismiss();
									},
								},
							];
							toast.position = "bottom";
							document.body.appendChild(toast);
							toast.present();
							// this.props.history.push({
							//     pathname: '/maps',
							//     state: { mapGenResponse: response.data, docId: this.state.document!.uuid, paragraphs: this.state.documentElements }
							// })
						});
						// }, 5000);
					}
				});
				// //console.log('Main concept: ', Object.keys(JSON.parse(json)));
			})
			.catch((e) => {
				this.props.disableMapButton(this.state.document!.uuid);
				// //console.log("Error generating map: ",e);
			});
	};
	splitTextIntoWords(text: string) {
		return text.split(/\s+/);
	}
	handleChkBoxCreation() {
		let documentParagraphs = document.getElementsByClassName("fileViewerParagraph");
		let checkBoxes = document.querySelectorAll('[id^="check-"]');
		checkBoxes.forEach((el) => el.remove());
		for (let i = 0; i < documentParagraphs.length; i++) {
			let el = documentParagraphs[i] as HTMLElement;
			el.style.marginLeft = "unset";
			el.style.backgroundColor = "";
		}
		this.setState({ sectionSelectMode: false, sectionSelectModeSummary: false, sectionSelectModeMap: false, selectedSections: [] });
	}

	render() {
		return (
			<IonPage>
				<Joyride
					steps={this.state.steps}
					run={this.state.tutorial} // Set to true to start the tour automatically
					continuous={true} // Allow continuous navigation through the tour
					showProgress={true} // Display progress indicator
					showSkipButton={true} // Display skip button
					spotlightClicks={true}
					callback={(data) => handleJoyrideCallback.call(this, data, "File Viewer")}
					hideBackButton={false}
					locale={{
						skip: (
							<div
								className="skip"
								onClick={() => {
									updateChoice.call(this, "tutorialFileViewer");
								}}
							>
								<Trans>Salta</Trans>
							</div>
						),
						back: (
							<div className="nextTutorialBtn">
								<Trans>Precedente</Trans>
							</div>
						),
						next: (
							<span>
								<Trans>Avanti</Trans>
							</span>
						),
						last: (
							<div
								className="nextTutorialBtn"
								onClick={() => {
									updateChoice.call(this, "tutorialFileViewer");
								}}
							>
								<Trans>Ultimo</Trans>
							</div>
						),
					}}
					styles={{
						buttonNext: {
							fontSize: "12px",
							outline: "none",
						},
					}}
				/>
				{/* {!isMobile && (
					<IonIcon
						className="helpIcon"
						onClick={() => {
							this.setState({ tutorial: true }, () => {
								sendEvent({
									"user_id": this.state.userUuid,
									"event_type": "Tutorial opened",
									"event_properties": {
										"user_org": this.props.organizationName !== null ? this.props.organizationName : "Private User",
										"page": "File Viewer",
									},
									"language": i18n.language,
									"platform": isPlatform("ios") ? "ios" : isPlatform("android") ? "android" : "desktop",
									"app_version": appVersion,
									"time": Date.now(),
								});
							});
						}}
						icon={helpOutline}
					></IonIcon>
				)} */}
				{this.state.document && (
					<FileViewerHeader
						history={this.props.history}
						tutorialClicked={() => {
							this.setState({ tutorial: true }, () => {
								sendEvent({
									"user_id": this.state.userUuid,
									"event_type": "Tutorial opened",
									"event_properties": {
										"user_org": this.props.organizationName !== null ? this.props.organizationName : "Private User",
										"page": "File Viewer",
									},
									"language": i18n.language,
									"platform": isPlatform("ios") ? "ios" : isPlatform("android") ? "android" : "desktop",
									"app_version": appVersion,
									"time": Date.now(),
								});
							});
						}}
						isEditingMode={this.state.fileEditingMode}
						mapButtonDisabled={this.props.disabledButtons?.includes(this.state.document.uuid)}
						ref={this.showPopoverButton as React.LegacyRef<FileViewerHeader>}
						document={this.state.document}
						documentElements={this.state.summaryDocumentElements ? this.state.summaryDocumentElements : []}
						isLoadingFile={this.state.isLoadingFile}
						orgData={this.props.orgData}
						showPlayer={this.state.showTtsPlayer}
						summarySelected={this.state.isSummaryOpen}
						fromSumKey={this.state.isSummaryOpen || this.state.isKeyWordsOpen}
						keyWordsSelected={this.state.isKeyWordsOpen}
						pdfReady={(url: string) => {
							this.setState({ downloadUrl: url, showPdfReadyPopover: true });
						}}
						backButtonDisabled={false}
						summaryDeleted={(choice, onlySumKey) => {
							let argID = this.props.history.location.state ? this.props.history.location.state.argumentID.uuid : false;
							if (choice === "sum") {
								this.setState({ summaryState: false, isSummaryOpen: false });
								if (argID)
									this.props.history.push(`/sessions`)
								else
									this.props.history.push(`/files/`)
							} else {
								this.setState({ keywordsState: false, isKeyWordsOpen: false, summaryState: true, isSummaryOpen: true });
								if (argID)
									this.props.history.push(`/sessions`)
								else
									this.props.history.push(`/files/`)
							}
						}}
						backButtonBehaviour={() => {
							if (this.state.sectionSelectMode) {
								this.setState({ sectionSelectMode: false, sectionSelectModeSummary: false, sectionSelectModeMap: false, selectedSections: [] });
								let documentParagraphs = document.getElementsByClassName("fileViewerParagraph");
								let checkBoxes = document.querySelectorAll('[id^="check-"]');
								checkBoxes.forEach((el) => el.remove());
								for (let i = 0; i < documentParagraphs.length; i++) {
									let el = documentParagraphs[i] as HTMLElement;
									el.style.backgroundColor = "";
									el.style.marginLeft = "unset";
									el.style.color = "var(--txt-color)";
								}
							} else {
								if (this.state.document?.uuid.startsWith('SUMMARYPDF') || this.state.document?.uuid.startsWith('KEYWORDSPDF')) {
									this.props.history.goBack()
								}
								else
									this.props.history.push("/");
							}
						}}
						fileUID={this.state.fileUID}
						argumentID={this.props.history.location.state ? this.props.history.location.state.argumentID.uuid : null}
					/>
				)}
				                        {(isMobile && (isPlatform('capacitor') && isIOS)) && <IonToolbar className="toolbarNotch" style={{ height: checkStatusBar() }} ></IonToolbar>}

				<IonContent
					ref={this.state.contentRef}
					className="fileViewerContent"
					fullscreen
					scrollEvents={true}
					/* onIonScroll={e => this.handleScroll(e)}
					onIonScrollStart={e => this.scrollStart()} */
					onIonScroll={(e) => {
						(this.selectionPopoverRef.current as any).setScrollOffsetY(e.detail.scrollTop);
						this.setState({howMuchScrolled:e.detail.scrollTop});
					}}
				>
					<IonButton className="back-btn"
						onClick={() => {
							if(this.state.session)
								this.props.history.push({
									pathname: this.state.file_text? '/sessions':`/session-details`,
									state: { argument: this.state.session }
								});
							else
								this.props.history.push({
									pathname: '/sessions',
								});
						}}
					>
						<IonIcon className="back-btn-icon" icon={chevronBack} />
					</IonButton>

					{!isMobile && this.state.summaryDocumentElements && this.state.summaryDocumentElements.length > 0 && (
						<div className="actionButtons" hidden={this.state.sectionSelectMode}>
							<>
								<div className="sideactionbtn">
									{/* <IonButton onClick={() => { //console.log('pagecounter' + this.state.pageCounter, 'primo' + this.state.startParagraphIndex, 'ultimo' + this.state.lastParagraphIndex, 'ttspointer' + this.state.ttsParagraphPointer) }}></IonButton> */}
									<IonButton
										id="tts"
										className="btn-fileviewer"
										color="whitebuttons"
										slot="icon-only"
										hidden={this.state.fileEditingMode || this.state.isKeyWordsOpen}
										disabled={this.state.isLoadingTTS}
										onClick={() => {
											if (!isMobile) calcolaToptts.call(this);

											// if (this.state.paywallState) loadPaywall(true);
											// else {
												let elementsToSynthetize: any = null;
												if (this.state.isSummaryOpen && this.state.summaryDocumentElements && this.state.summaryDocumentElements?.length > 0)
													elementsToSynthetize = this.state.summaryDocumentElements;
												else elementsToSynthetize = this.state.documentElements;
												if (
													this.state.generatedAudios.filter(
														(el) =>
															!this.state.isSummaryOpen &&
															el.text === this.state.documentElements![this.state.ttsParagraphPointer!].text &&
															el.settings.speech_rate === this.state.generatedAudios[0].settings.speech_rate &&
															el.settings.speech_gender === this.state.generatedAudios[0].settings.speech_gender
													).length === 0 &&
													this.state.generatedAudios.filter(
														(el) => this.state.summaryDocumentElements && el.text === this.state.summaryDocumentElements![this.state.ttsParagraphPointer!].text
													).length === 0
												) {
													this.setState({
														disablePlayButton: true,
														disableTTSOptions: true,
														disableTTSSkipButtons: true,
													});
													//console.log("ENTRO NELL IF");
													settingsServices.getUserSettings(this.state.userUuid,this.state.bearerToken).then((ttsSettings) => {
														getTTS(elementsToSynthetize, elementsToSynthetize[this.state.ttsParagraphPointer!].text, ttsSettings)
															.then((response) => {
																// sendEvent({
																// 	"user_id": this.state.userUuid,
																// 	"event_type": "TTS pressed",
																// 	"event_properties": {
																// 		"user_org": this.props.organizationName !== null ? this.props.organizationName : "Private User",
																// 		"document_uuid": this.state.document?.uuid,
																// 		"document_name": this.state.document?.name,
																// 	},
																// 	"language": i18n.language,
																// 	"platform": isPlatform("ios") ? "ios" : isPlatform("android") ? "android" : "desktop",
																// 	"app_version": appVersion,
																// 	"time": Date.now(),
																// });
																this.setState({ speechMarks: response.speech_marks });
																//console.log('CIAONE: ', response.length > 1000)
																//console.log('SPEECH MARKS AAA: ', response)
																let tempAudios = this.state.generatedAudios;
																tempAudios.push({
																	id: elementsToSynthetize![0].index,
																	text: elementsToSynthetize![this.state.ttsParagraphPointer!].text,
																	audio: response.audio,
																	speechMarks: response.speech_marks,
																	settings: ttsSettings,
																});
																this.setState(
																	{
																		audioToPlay: response.audio,
																		speechMarks: response.speech_marks,
																		generatedAudios: tempAudios,
																		disablePlayButton: false,
																		disableTTSOptions: false,
																		disableTTSSkipButtons: false,
																	},
																	() => {
																		this.changeExistingSpans();
																		this.getKaraoke(this.state.documentElements!);
																	}
																);
															})
															.catch((err) => {
																// console.error("[FileViewer] error getting tts:", err);
															});
													});
												} else {
													if (!this.state.showTtsPlayer) {
														// sendEvent({
														// 	"user_id": this.state.userUuid,
														// 	"event_type": "TTS pressed",
														// 	"event_properties": {
														// 		"user_org": this.props.organizationName !== null ? this.props.organizationName : "Private User",
														// 		"document_uuid": this.state.document?.uuid,
														// 		"document_name": this.state.document?.name,
														// 	},
														// 	"language": i18n.language,
														// 	"platform": isPlatform("ios") ? "ios" : isPlatform("android") ? "android" : "desktop",
														// 	"app_version": appVersion,
														// 	"time": Date.now(),
														// });

														// this.getKaraoke(this.state.documentElements!)

														let Audio = document.getElementById("audioElement") as HTMLAudioElement;
														console.log(this.state.audioToPlay);
														if (this.state.audioToPlay !== undefined) {
															Audio.src = this.state.generatedAudios.filter(
																(el) => el.text === elementsToSynthetize![this.state.ttsParagraphPointer!].text
															)[0].audio;
															Audio.play();
														} else {
															this.setState({
																disablePlayButton: true,
																disableTTSOptions: true,
																disableTTSSkipButtons: true,
															});
															settingsServices.getUserSettings(this.state.userUuid,this.state.bearerToken).then((ttsSettings) => {
																getTTS(elementsToSynthetize, elementsToSynthetize[this.state.ttsParagraphPointer!].text, ttsSettings)
																	.then((response) => {
																		console.log(response);
																		this.setState({ speechMarks: response.speech_marks });
																		//console.log('CIAONE: ', response.length > 1000)
																		//console.log('SPEECH MARKS AAA: ', response)
																		let tempAudios = this.state.generatedAudios;
																		tempAudios.push({
																			id: elementsToSynthetize![0].index,
																			text: elementsToSynthetize![this.state.ttsParagraphPointer!].text,
																			audio: response.audio,
																			speechMarks: response.speech_marks,
																			settings: ttsSettings,
																		});
																		this.setState(
																			{
																				audioToPlay: response.audio,
																				speechMarks: response.speech_marks,
																				generatedAudios: tempAudios,
																				disablePlayButton: false,
																				disableTTSOptions: false,
																				disableTTSSkipButtons: false,
																			},
																			() => {
																				this.changeExistingSpans();
																				this.getKaraoke(this.state.documentElements!);
																			}
																		);
																	})
																	.catch((err) => {
																		console.error("[FileViewer] error getting tts:", err);
																	});
															});
														}
													} else {
														let Audio = document.getElementById("audioElement") as HTMLAudioElement;
														Audio.pause();
														// this.removeTTSHighlighting();
													}
												}
												//put text in a variable for the TTS so it play correctly for summary or normal file
												if (this.state.isSummaryOpen) this.setState({ elementsTTS: this.state.summaryDocumentElements });
												else this.setState({ elementsTTS: this.state.documentElements });

												document.getElementById("textgrid")!.classList.add("spostaTesto");

												// console.log("gionitan", this.state.ttsParagraphPointer);
												if (this.state.isSummaryOpen) {
													this.setState({ elementsTTS: this.state.summaryDocumentElements });
												} else this.setState({ elementsTTS: this.state.documentElements });

												if (!this.state.showTtsPlayer) {
													//put text in a variable for the TTS so it play correctly for summary or normal file

													document.getElementById("textgrid")!.classList.add("spostaTesto");

													// document.getElementById((String(this.state.ttsParagraphPointer)))!.classList.add("paragraphinReading");
												} else {
													let Audio = document.getElementById("audioElement") as HTMLAudioElement;
													Audio.pause();
													document.getElementById("textgrid")!.classList.remove("spostaTesto");
													// this.removeTTSHighlighting();
													/* for (let i = 0; i < document.getElementsByClassName('fileViewerParagraph').length; i++) {
														// (document.getElementsByClassName('fileViewerParagraph')[i] as HTMLElement).classList.remove("paragraphinReading");
													} */
												}
												this.showFileViewerPlayer(!this.state.showTtsPlayer);
											}
										// }
									}
										onMouseEnter={() => {
											document.getElementById("ttslabel")!.style.visibility = "visible";
											document.getElementById("ttslabel")!.style.opacity = "1";
										}}
										onMouseLeave={() => {
											document.getElementById("ttslabel")!.style.visibility = "hidden";
											document.getElementById("ttslabel")!.style.opacity = "0";
										}}
									>
										<IonIcon icon={!this.state.showTtsPlayer ? voiceOff : voiceOn} style={{ paddingInlineStart: "0px", paddingInlineEnd: "0px" }}></IonIcon>
									</IonButton>
									<div id="ttslabel">
										<Trans>Sintesi vocale</Trans>
									</div>
								</div>

								<div className="sideactionbtn">
									{/* <IonButton
										id="conceptualMap"
										className="btn-fileviewer"
										color="whitebuttons"
										style={{ pointerEvents: this.state.mapButtonDisabled ? "none" : "auto" }}
										hidden={true}
										onClick={() => {
											let fromButton = true;
											if (this.state.paywallState) loadPaywall(fromButton);
											else this.mapButtonClicked();
										}}
										onMouseEnter={() => {
											document.getElementById("mapLabel")!.style.visibility = "visible";
											document.getElementById("mapLabel")!.style.opacity = "1";
										}}
										onMouseLeave={() => {
											document.getElementById("mapLabel")!.style.visibility = "hidden";
											document.getElementById("mapLabel")!.style.opacity = "0";
										}}
									>
										<IonIcon size="large" slot="icon-only" icon={this.state.mapIcon} />
										<IonSpinner hidden={!this.state.mapButtonDisabled} className="spinner" name="crescent" />
									</IonButton>
									<div id="mapLabel">
										<Trans>Mappa concettuale</Trans>
									</div> */}
								</div>

								<div className="sideactionbtn" hidden>
									
									<IonButton
										id="modifyDoc"
										className="btn-fileviewer"
										color="whitebuttons"
										onClick={() => {
											let fromButton = true;
											if (this.state.paywallState) loadPaywall(fromButton);
											else {
												this.editingModeClicked();
												if (this.state.showTtsPlayer) {
													document.getElementById("textgrid")!.classList.remove("spostaTesto");
													for (let i = 0; i < document.getElementsByClassName("fileViewerParagraph").length; i++) {
														(document.getElementsByClassName("fileViewerParagraph")[i] as HTMLElement).classList.remove("paragraphinReading");
													}
													this.showFileViewerPlayer(!this.state.showTtsPlayer);
												}
											}
										}}
										onMouseEnter={() => {
											document.getElementById("ModifyLabel")!.style.visibility = "visible";
											document.getElementById("ModifyLabel")!.style.opacity = "1";
										}}
										onMouseLeave={() => {
											document.getElementById("ModifyLabel")!.style.visibility = "hidden";
											document.getElementById("ModifyLabel")!.style.opacity = "0";
										}}
									>
										<IonIcon icon={this.state.fileEditingMode ? editTextOff : ediText} slot="icon-only" />
									</IonButton>
									<div id="ModifyLabel">
										<Trans>Modifica documento</Trans>
									</div>
								</div>

								{/* <div className='sideactionbtn'>
                                    <IonButton id="summary" className='btn-fileviewer' color='whitebuttons'
                                        hidden={this.state.fileEditingMode}
                                        onMouseEnter={() => {
                                            document.getElementById('SummaryLabel')!.style.visibility = 'visible';
                                            document.getElementById('SummaryLabel')!.style.opacity = '1';
                                        }}
                                        onMouseLeave={() => {
                                            document.getElementById('SummaryLabel')!.style.visibility = 'hidden';
                                            document.getElementById('SummaryLabel')!.style.opacity = '0';
                                        }}
                                        style={{ pointerEvents: this.state.summButtonDisabled ? 'none' : 'auto' }}

                                        onClick={() => {

                                            let fromButton = true
                                            if (this.state.paywallState)
                                                loadPaywall(fromButton)
                                            else {
                                                if (this.state.isGeneratingSummary || (this.state.isGeneratingKeywords && !this.state.summaryState)) {
                                                    return
                                                }
                                                //Deactivates tts when changing views
                                                if (this.state.showTtsPlayer) {
                                                    document.getElementById('textgrid')!.classList.remove("spostaTesto");
                                                    for (let i = 0; i < document.getElementsByClassName('fileViewerParagraph').length; i++) {
                                                        (document.getElementsByClassName('fileViewerParagraph')[i] as HTMLElement).classList.remove("paragraphinReading");
                                                    }
                                                    this.showFileViewerPlayer(!this.state.showTtsPlayer);
                                                }
                                                if (!this.state.isSummaryOpen) {
                                                    if (this.state.summaryState) {
                                                        this.setState({ isSummaryOpen: true, isKeyWordsOpen: false });
                                                        document.getElementById("pageScrollControlls")!.hidden = true
                                                        this.viewSummary()
                                                    }
                                                    else {
                                                        this.setState({ isKeyWordsOpen: false })
                                                        this.viewFile()
                                                        document.getElementById("pageScrollControlls")!.hidden = false
                                                        this.setState({ summaryIsClicked: true, typeSumOrKey: "summary", choice: false })
                                                    }
                                                } else {
                                                    this.viewFile()
                                                    document.getElementById("pageScrollControlls")!.hidden = false
                                                    this.setState({ isSummaryOpen: false })
                                                }
                                            }
                                        }
                                        }>
                                        <IonIcon icon={this.state.isGeneratingSummary || (this.state.isGeneratingKeywords && !this.state.summaryState) ? timeOutline : genSumm} slot='icon-only' />
                                        <IonSpinner hidden={!this.state.summButtonDisabled} className='spinner' name='crescent' />

                                    </IonButton>
                                    <div id='SummaryLabel' ><Trans>Genera riassunto</Trans></div>
                                </div> */}
								<div className="sideactionbtn" hidden>
									
									<IonButton
										id="keywords"
										className="btn-fileviewer"
										color="whitebuttons"
										hidden={this.state.fileEditingMode || (this.state.isKeyWordsOpen && !this.state.summaryState) || this.state.document?.uuid.startsWith('SUMMARYPDF')}
										onMouseEnter={() => {
											document.getElementById("keyWordsLabel")!.style.visibility = "visible";
											document.getElementById("keyWordsLabel")!.style.opacity = "1";
										}}
										onMouseLeave={() => {
											document.getElementById("keyWordsLabel")!.style.visibility = "hidden";
											document.getElementById("keyWordsLabel")!.style.opacity = "0";
										}}
										style={{ pointerEvents: this.state.keygenButtonDisabled ? "none" : "auto" }}
										onClick={() => {
											let fromButton = true;
											if (this.state.paywallState) loadPaywall(fromButton);
											else {
												if (this.state.isGeneratingKeywords || (this.state.isGeneratingSummary && !this.state.keywordsState)) return;
												//Deactivates tts when changing views
												if (this.state.showTtsPlayer) {
													document.getElementById("textgrid")!.classList.remove("spostaTesto");
													for (let i = 0; i < document.getElementsByClassName("fileViewerParagraph").length; i++) {
														(document.getElementsByClassName("fileViewerParagraph")[i] as HTMLElement).classList.remove("paragraphinReading");
													}
													this.showFileViewerPlayer(!this.state.showTtsPlayer);
												}
												if (this.state.isSummaryOpen) {
													this.setState({ isSummaryOpen: true }); /* ALSO APPLY ON MOBILE */
													viewSummary.call(this);
												}
												if (!this.state.isKeyWordsOpen) {
													if (this.state.keywordsState) {
														// console.log("ciao");
														this.setState({ isKeyWordsOpen: true, isSummaryOpen: false });
														document.getElementById("pageScrollControlls")!.hidden = true;
														viewSummary.call(this);
													} else this.setState({ summaryIsClicked: true, typeSumOrKey: "keywords", choice: true });
												} else {
													viewSummary.call(this);
													this.setState({ isKeyWordsOpen: false, isSummaryOpen: true });
												}
											}
										}}
									>
										<IonIcon
											icon={(this.state.isGeneratingSummary && !this.state.keywordsState) || this.state.isGeneratingKeywords ? timeOutline : keyWord}
											slot="icon-only"
										/>
										<IonSpinner hidden={!this.state.keygenButtonDisabled} className="spinner" name="crescent" />
									</IonButton>
									<div id="keyWordsLabel">
										<Trans>Genera parole chiave</Trans>
									</div>
								</div>
							</>
						</div>
					)}
					{/* POPOVER SUMMARY */}
					<IonPopover
						isOpen={this.state.summaryIsClicked}
						className="summaryPopover"
						onDidDismiss={() => {
							this.setState({ summaryIsClicked: false });
						}}
					>
						<div className="summaryPopoverDiv">
							<IonText className="sumPopupTxt">{!this.state.choice ? <Trans>Crea riassunto!</Trans> : <Trans>Crea parole chiave!</Trans>}</IonText>
							<div className="summaryPopoverBtns">
								<IonButton fill="outline" className="annullaSummary"
									onClick={() => {
										let paragraphs = document.getElementsByTagName("p");

										this.setState({ summaryIsClicked: false })
										for (let i = 0; i < paragraphs.length; i++) {
											let el = paragraphs[i] as HTMLElement;
											el.style.marginLeft = "unset";
											el.style.backgroundColor = "";
											el.style.color = "var(--txt-color)";
										}

									}}>
									<Trans>Annulla</Trans>
								</IonButton>

								<IonButton
									className="doSummary"
									onClick={() => {
										this.setState({ sectionSelectMode: true, sectionSelectModeSummary: true, fileEditingMode: false, summaryIsClicked: false });
										let paragraphs = document.getElementsByTagName("p");
										for (let i = 0; i < paragraphs.length; i++) {
											const p = paragraphs[i];
											let checkBox = document.createElement("div");
											checkBox.className = "squareSelector";
											checkBox.id = "check-" + p.id;
											p.style.marginLeft = "40px";
											checkBox.onclick = p.onclick;
											p.parentNode!.insertBefore(checkBox, p);
										}
									}}
								>
									<Trans>Prosegui</Trans>
								</IonButton>
							</div>
						</div>
					</IonPopover>
					{/* PDF DOWNLOAD POPOVER */}
					<IonPopover
						className={"pdfReadyPopover"}
						isOpen={this.state.downloadUrl !== "" && this.state.showPdfReadyPopover}
						onDidDismiss={() => {
							this.setState({ showPdfReadyPopover: false });
						}}
					>
						<div className="pdfReadyPopoverDiv">
							<p>
								<Trans>Il tuo PDF è pronto</Trans>
							</p>
							<IonButton
								onClick={() => {
									//call the cloud function to remove the generated file from storage
									const deleteGeneratedPdf = httpsCallable(functions, "deleteGeneratedPdf-deleteGeneratedPdf");
									deleteGeneratedPdf({
										documentName: this.state.document?.name,
										userUuid: this.state.userUuid,
									})
										.then((response: any) => {
											if (response.ok) {
												console.log("Successfully deleted file with uuid: ", this.state.document?.uuid);
											} else if (response.error) {
												//show error and return
												console.log(response.error);
												return;
											}
										})
										.catch((e) => {
											console.log("ERROR ", e);
										});
									window.open(this.state.downloadUrl, "_system");
									this.setState({ downloadUrl: "" });
								}}
							>
								<Trans>Scarica il PDF</Trans>
							</IonButton>
						</div>
					</IonPopover>
					{/* POPOVER SUMMARY MESSAGE*/}
					<IonModal
						isOpen={this.state.infoMsg}
						className="summaryPopoverOk"
						onDidDismiss={() => {
							this.setState({ infoMsg: false }); /* console.log("dismiss") */
						}}
					>
						<div className="summaryPopoverOkDiv">
							<IonIcon className="summaryLoadingIcon" icon={loading}></IonIcon>
							<div className="summaryPopoverOkTxt">
								<IonText>
									{!this.state.choice ? <Trans>Stiamo preparando il tuo riassunto!</Trans> : <Trans>Stiamo preparando le tue parole chiave!</Trans>}
								</IonText>{" "}
								{/* TO LOCALIZE THIS */}
								<IonText>
									<Trans>Ti avviseremo appena sarà pronto</Trans>
								</IonText>
							</div>
							<IonButton className="doSummary"
								onClick={() => {
									this.setState({ infoMsg: false })
									let documentParagraphs = document.getElementsByTagName("p");
									let checkBoxes = document.querySelectorAll('[id^="check-"]');
									checkBoxes.forEach((el) => el.remove());
									for (let i = 0; i < documentParagraphs.length; i++) {
										let el = documentParagraphs[i] as HTMLElement;
										el.style.backgroundColor = "";
										el.style.marginLeft = "unset";
										el.style.color = "var(--txt-color)";
									}

								}}>
								<Trans>Ho capito</Trans>
							</IonButton>
						</div>
					</IonModal>
					{/* POPOVER SUMMARY READY MESSAGE*/}
					<IonModal
						backdropDismiss={false}
						isOpen={this.state.summaryReadyMsg && !this.state.sectionSelectMode}
						className="summaryPopoverOk"
						onDidDismiss={() => {
							this.setState({ summaryReadyMsg: false }); /* console.log("dismiss") */
						}}
					>
						<div className="summaryPopoverOkDiv">
							<IonIcon className="summaryReadyIcon" icon={reasyLogo}></IonIcon>
							<div className="summaryPopoverOkTxt">
								<IonText>
									<Trans>Il tuo riassunto è pronto!</Trans>
								</IonText>
							</div>
							<IonButton
								className="doSummary"
								onClick={() => {
									this.setState({ isSummaryOpen: true, isKeyWordsOpen: false });
									viewSummary.call(this);
									if (!isMobile) document.getElementById("pageScrollControlls")!.hidden = true;
									/* if (this.state.isSummaryOpen) {
									if (this.state.summaryState) {
										this.viewSummary()
										document.getElementById("pageScrollControlls")!.hidden = true
										this.setState({ isSummaryOpen: true, isKeyWordsOpen: false })
									}
									else this.setState({ summaryIsClicked: true })
								}
								else if (this.state.isKeyWordsOpen) {
									if (this.state.keywordsState) {
										this.viewSummary()
										document.getElementById("pageScrollControlls")!.hidden = true
										this.setState({ isKeyWordsOpen: true, isSummaryOpen: false })
									}
								}
								else {
									this.viewFile()
									this.setState({ isSummaryOpen: false })
									document.getElementById("pageScrollControlls")!.hidden = false
    
								} */
									this.setState({ summaryReadyMsg: false });
								}}
							>
								<Trans>Prosegui</Trans>
							</IonButton>
						</div>
					</IonModal>
					<IonPopover
						backdropDismiss
						isOpen={this.state.keywordsReadyMsg && !this.state.sectionSelectMode}
						className="summaryPopoverOk"
						onDidDismiss={() => {
							this.setState({ keywordsReadyMsg: false }); /* console.log("dismiss")  */
						}}
					>
						<div className="summaryPopoverOkDiv">
							<IonIcon className="summaryReadyIcon" icon={reasyLogo}></IonIcon>
							<div className="summaryPopoverOkTxt">
								<IonText>
									<Trans>Le parole chiave sono pronte!</Trans>
								</IonText>
							</div>
							<IonButton
								className="doSummary"
								onClick={() => {
									console.log(this.state.isKeyWordsOpen)
									if (!this.state.isKeyWordsOpen) {
										if (this.state.keywordsState) {
											this.setState({ isKeyWordsOpen: true, isSummaryOpen: false }, () => viewSummary.call(this));
										}
									} else {
										viewSummary.call(this);
										this.setState({ isSummaryOpen: false });
									}
									this.setState({ keywordsReadyMsg: false });
								}}
							>
								<Trans>Prosegui</Trans>
							</IonButton>
						</div>
					</IonPopover>

					{!isMobile && this.state.summaryDocumentElements && this.state.summaryDocumentElements.length > 0 && (
						<div className="divNextPrevious" id="pageScrollControlls" hidden={this.state.isKeyWordsOpen || this.state.isSummaryOpen} >
							<IonButtons className="zoomButtons">
								<div style={{ marginLeft: "10px", marginTop: "3px", fontSize: "14px" }}>
									{this.state.pageCounter + 1}/{this.state.numberOfPages}
								</div>
								<IonButton
									id="indietro"
									disabled={(this.state.pageCounter === 0 ? true : false) || this.state.PageButtonDisabled}
									onClick={() => {
										this.setState({ PageButtonDisabled: true });
										let wasEditing = this.state.fileEditingMode;
										if (this.state.fileEditingMode) {
											this.editingModeClicked();
										}

										setTimeout(() => {
											this.setState({ PageButtonDisabled: false });
										}, 1000);
										let appoggio = this.state.pageCounter - 1;
										if (this.state.showTtsPlayer) {
											let A = document.getElementsByClassName("paragraphinReading");
											for (let i = 0; i < A.length; i++) {
												A[i].classList.remove("paragraphinReading");
											}
										}
										this.setState({ pageCounter: appoggio });

										let firstparagraphid = arraySummaryPage[appoggio].firstChild.id;
										this.setState({ startParagraphIndex: firstparagraphid });

										let lastparagraphid = arraySummaryPage[appoggio].lastChild.id;
										this.setState({ lastParagraphIndex: lastparagraphid });

										this.setState({ ttsParagraphPointer: Number(firstparagraphid) });

										//console.log('pagecounter' + this.state.pageCounter, 'primo' + this.state.startParagraphIndex, 'ultimo' + this.state.lastParagraphIndex, 'ttspointer' + this.state.ttsParagraphPointer);

										this.setState({ audioToPlay: "" });
										if (this.state.ttsParagraphPointer !== null) {
											calcolaToptts.call(this);
										}
										(document.getElementById("fileViewerTextDivContainer") as HTMLDivElement)?.replaceChildren(arraySummaryPage[appoggio]);
										if (wasEditing) {
											this.editingModeClicked();
											this.setState({ fileEditingMode: true });
										}

										if (this.state.userUuid) {
											settingsServices.getUserSettings(this.state.userUuid,this.state.bearerToken).then((ttsSettings) => {
												if (this.state.showTtsPlayer) {
													//console.log(document.getElementById(String(this.state.ttsParagraphPointer)));
													document.getElementById(String(this.state.ttsParagraphPointer))?.classList.add("paragraphinReading");
												}
												if (
													this.state.summaryDocumentElements !== null &&
													this.state.ttsParagraphPointer !== null &&
													this.state.ttsParagraphPointer >= Number(this.state.startParagraphIndex) &&
													this.state.ttsParagraphPointer <= Number(this.state.lastParagraphIndex)
												) {
													//console.log(this.state.documentElements[this.state.ttsParagraphPointer].text);
													getTTS([], this.state.summaryDocumentElements[this.state.ttsParagraphPointer].text, ttsSettings)
														.then((audio: any) => {
															this.setState({ audioToPlay: audio.audio });
															let Audio = document.getElementById("audioElement") as HTMLAudioElement;
															Audio.pause();
														})
														.catch((err: any) => {
															console.error("[FileViewer] error getting tts:", err);
														});
												}
												return ttsSettings;
											});
											this.state.contentRef.current?.scrollToTop(500);
										}
									}}
								>
									<IonIcon icon={ArrowUp} size="medium"></IonIcon>
								</IonButton>

								<IonButton
									id="avanti"
									disabled={(this.state.pageCounter + 1 === this.state.numberOfPages ? true : false) || this.state.PageButtonDisabled}
									onClick={() => {
										this.setState({ PageButtonDisabled: true });
										let wasEditing = this.state.fileEditingMode;
										if (this.state.fileEditingMode) {
											this.editingModeClicked();
										}

										setTimeout(() => {
											this.setState({ PageButtonDisabled: false });
										}, 1000);
										let appoggio = this.state.pageCounter + 1;

										if (this.state.showTtsPlayer) {
											let A = document.getElementsByClassName("paragraphinReading");

											for (let i = 0; i < A.length; i++) {
												A[i].classList.remove("paragraphinReading");
											}
										}

										this.setState({ pageCounter: appoggio });

										let firstparagraphid = arraySummaryPage[appoggio].firstChild.id;
										this.setState({ startParagraphIndex: firstparagraphid });

										let lastparagraphid = arraySummaryPage[appoggio].lastChild.id;
										this.setState({ lastParagraphIndex: lastparagraphid });

										this.setState({ ttsParagraphPointer: Number(firstparagraphid) });

										if (this.state.showTtsPlayer) {
											this.setState({ audioToPlay: "" });
											if (this.state.ttsParagraphPointer !== null) {
												calcolaToptts.call(this);
											}
										}
										(document.getElementById("fileViewerTextDivContainer") as HTMLDivElement)?.replaceChildren(arraySummaryPage[appoggio]);
										if (wasEditing) {
											this.editingModeClicked();
											this.setState({ fileEditingMode: true });
										}

										if (this.state.userUuid) {
											settingsServices.getUserSettings(this.state.userUuid,this.state.bearerToken).then((ttsSettings) => {
												if (this.state.showTtsPlayer) {
													// console.log(document.getElementById(String(this.state.ttsParagraphPointer)));
													document.getElementById(String(this.state.ttsParagraphPointer))?.classList.add("paragraphinReading");
												}
												if (
													this.state.summaryDocumentElements !== null &&
													this.state.ttsParagraphPointer !== null &&
													this.state.ttsParagraphPointer >= Number(this.state.startParagraphIndex) &&
													this.state.ttsParagraphPointer <= Number(this.state.lastParagraphIndex)
												) {
													// console.log(this.state.documentElements[this.state.ttsParagraphPointer].text);
													getTTS([], this.state.summaryDocumentElements[this.state.ttsParagraphPointer].text, ttsSettings)
														.then((audio: any) => {
															console.log;
															this.setState({ audioToPlay: audio.audio });
															let Audio = document.getElementById("audioElement") as HTMLAudioElement;
															Audio.pause();
														})
														.catch((err: any) => {
															console.error("[FileViewer] error getting tts:", err);
														});
												}
												return ttsSettings;
											});
											this.state.contentRef.current?.scrollToTop(500);
										}
									}}
								>
									<IonIcon icon={ArrowDown} size="medium"></IonIcon>
								</IonButton>
							</IonButtons>
						</div>
					)}

					{!isMobile && this.state.summaryDocumentElements && this.state.summaryDocumentElements.length > 0 && (
						<div className="divZoom" hidden={this.state.fileEditingMode || this.state.sectionSelectMode}>
							<IonButtons className="zoomButtons">
								<IonButton
									id="zoomIn"
									onClick={() => {
										let currentZoom = parseFloat((document.getElementById("textgrid") as HTMLElement).style.scale) || 1;
										if (currentZoom < 1.6) {
											(document.getElementById("textgrid") as HTMLElement).style.scale = String(currentZoom + 0.1);
										}
									}}
								>
									<IonIcon icon={ZoomIN} />
								</IonButton>

								<IonButton
									id="zoomOut"
									onClick={() => {
										let currentZoom = parseFloat((document.getElementById("textgrid") as HTMLElement).style.scale) || 1;
										if (currentZoom > 0.6) {
											(document.getElementById("textgrid") as HTMLElement).style.scale = String(currentZoom - 0.1);
										}
									}}
								>
									<IonIcon icon={ZoomOut} />
								</IonButton>
							</IonButtons>
						</div>
					)}
					{this.state.sectionSelectModeMap && (
						<div
							hidden={!this.state.sectionSelectMode || isMobile}
							style={{ position: "fixed", top: "150px", left: "15px", minWidth: "100px", maxWidth: "250px", fontSize: "30px", color: "var(--txt-color)" }}
						>
							<b>
								<Trans>Clicca le sezioni che vuoi trasformare in mappa concettuale...</Trans>
							</b>
						</div>
					)}
					{this.state.sectionSelectModeSummary && (
						<div
							hidden={!this.state.sectionSelectMode || isMobile}
							style={{ position: "fixed", top: "150px", left: "15px", minWidth: "100px", maxWidth: "250px", fontSize: "30px", color: "var(--txt-color)" }}
						>
							{!this.state.choice && <Trans>Clicca le sezioni che vuoi trasformare in riassunto...</Trans>}
							{this.state.choice && <Trans>Clicca le sezioni da cui estrarre le parole chiave...</Trans>}
						</div>
					)}
					<div hidden={!this.state.sectionSelectModeMap || isMobile} className="fileViewerSelectedSectionsDiv" style={{ zIndex: "1000" }}>
						{this.state.selectedSections.length} {this.state.selectedSections.length === 1 ? <Trans>Sezione</Trans> : <Trans>Sezioni</Trans>}
						<IonButton
							className="annullaMappa"
							fill="outline"
							shape="round"
							onClick={() => {
								let documentParagraphs = document.getElementsByClassName("fileViewerParagraph");
								let checkBoxes = document.querySelectorAll('[id^="check-"]');
								checkBoxes.forEach((el) => el.remove());
								for (let i = 0; i < documentParagraphs.length; i++) {
									let el = documentParagraphs[i] as HTMLElement;
									el.style.marginLeft = "unset";
									el.style.backgroundColor = "";
									el.style.color = "var(--txt-color)";
								}
								this.setState({ sectionSelectMode: false, sectionSelectModeMap: false, selectedSections: [] });
							}}
						>
							<Trans>Annulla</Trans>
						</IonButton>
						<IonButton
							className="createMapButton"
							disabled={this.state.selectedSections.length < 2}
							onClick={async () => {
								this.createConceptualMaps();
								this.setState({ mapButtonDisabled: true });
							}}
						>
							{/* WEB */}
							<Trans>Crea Mappa</Trans>
						</IonButton>
					</div>
					<div hidden={!this.state.sectionSelectModeSummary || isMobile} className="fileViewerSelectedSectionsDiv" style={{ zIndex: "1000" }}>
						{this.state.selectedSections.length} {this.state.selectedSections.length === 1 ? <Trans>Sezione</Trans> : <Trans>Sezioni</Trans>}
						<IonButton
							className="annullaMappa"
							shape="round"
							fill="outline"
							onClick={() => {
								let documentParagraphs = document.getElementsByTagName("p");
								let checkBoxes = document.querySelectorAll('[id^="check-"]');
								checkBoxes.forEach((el) => el.remove());
								for (let i = 0; i < documentParagraphs.length; i++) {
									let el = documentParagraphs[i] as HTMLElement;
									el.style.backgroundColor = "";
									el.style.marginLeft = "unset";
									el.style.color = "var(--txt-color)";
								}
								this.setState({ sectionSelectMode: false, sectionSelectModeSummary: false, selectedSections: [] });
							}}
						>
							<Trans>Annulla</Trans>
						</IonButton>
						{/* <IonButton
							className="createMapButton"
							disabled={this.state.selectedSections.length < 1}
							onClick={() => {
								this.setState({ sectionSelectModeSummary: false, sectionSelectMode: false, infoMsg: true });
								let documentParagraphs = document.getElementsByClassName("fileViewerParagraph");
								// let totalSize = 0;

								for (let i = 0; i < documentParagraphs.length; i++) {
									const divSize = ((documentParagraphs[i] as HTMLParagraphElement).innerText.length * 2) / 1024; // calculate size in kb
									// totalSize += divSize;
								}
								// console.log(`Total size: ${totalSize.toFixed(2)} kb`);
								for (let i = 0; i < documentParagraphs.length; i++) {
									let el = documentParagraphs[i] as HTMLElement;
									el.style.backgroundColor = "";
									el.style.color = "";
								}
								let checkBoxes = document.querySelectorAll('[id^="check-"]');
								checkBoxes.forEach((el) => el.remove());
								auth.currentUser?.getIdToken().then(async (token) => {
									let bearerToken = token;
									let text = this.state.selectedSections.join(" ");
									//OLD FUNCTION TO GET SUMMARY USING SPACY THROUGH CLOUD FUNCTION
									// const createStandardMap = httpsCallable(functions, "Summary", { timeout: 600000 });
									// createStandardMap({
									//     text: text,
									//     ln: "it",
									//     docId: this.state.document!.uuid,
									//     auth: bearerToken
									// })
									if (this.state.choice) this.setState({ isGeneratingKeywords: true, keygenButtonDisabled: true });
									else this.setState({ isGeneratingSummary: true, summButtonDisabled: true });
									filesServices
										.getSummary(text, bearerToken, this.state.typeSumOrKey, this.state.document!.uuid, this.state.userUuid)
										.then(async (response) => {
											if (response) {
												this.areSummaryOrKeywords();
												if (this.state.choice) this.setState({ isGeneratingKeywords: false, keygenButtonDisabled: false });
												else this.setState({ isGeneratingSummary: false, summButtonDisabled: false });
												if (this.state.typeSumOrKey === "summary") this.setState({ isSummaryOpen: true, summaryReadyMsg: true });
												else this.setState({ isKeyWordsOpen: true, keywordsReadyMsg: true });

												this.setState({ infoMsg: false });
												//console.log("success")

											} else {
												//console.log("error:il summary non è andato a buon fine");
											}
										})
										.catch((code) => {
											//ERROR STATUS CODE 500
											//HANDLE ERROR
											//console.log("[SUMMARY] error status code ", code);
										});
									this.setState({ selectedSections: [] }); // DO NOT REMOVE!!!
								});
							}}
						>
							{!this.state.choice && (
								<IonText>
									<Trans>Genera riassunto</Trans>
								</IonText>
							)}
							{this.state.choice && (
								<IonText>
									<Trans>Genera parole chiave</Trans>
								</IonText>
							)}
						</IonButton> */}
					</div>
					{(!this.state.summaryDocumentElements || this.state.summaryDocumentElements.length === 0) && (
						<div className="fileViewerProcessingAnimationDiv">
							<Lottie
								isClickToPauseDisabled={true}
								options={{
									loop: true,
									autoplay: true,
									animationData: processingAnimation,
									rendererSettings: {
										preserveAspectRatio: "xMidYMid slice",
									},
								}}
								height={200}
								width={200}
							/>
							<p className="fileViewerProcessingAnimationParagraph">
								<Trans>{this.state.firstLoaderMessage}</Trans>
							</p>
						</div>
					)}

					{!this.state.showPopoverButton && isMobile && <IonButton hidden={!this.state.showPopoverButton}></IonButton>}

					<IonGrid
						className="fileViewerTextGrid"
						id="textgrid"
						onMouseUp={() => {
							try {
								// this.popoverButtonClicked();
							} catch (error) { }
						}}
					>
						<IonCol className="containerMobile">
							<div
								id="fileViewerTextDivContainer"
								style={{
									position: "absolute",
									width: "100%",
								}}
							></div>
						</IonCol>
					</IonGrid>

					<div style={{ width: "400px", position: "absolute", top: "40px", left: "63%" }}>
						<SelectionPopover
							history={this.props.history}
							customImageUrl={this.state.wordCustomImage}
							uploadImageClicked={(text) => {
								document.getElementById("viewerImageUpload")?.click();
								this.setState({ selectedText: text });
							}}
							deleteCustomImage={(url, text) => {
								this.deleteCustomImage(url, text);
							}}
							removeDeletedDrawing={(url, text) => {
								this.deleteDrawing(url, text);
							}}
							ref={this.selectionPopoverRef as React.LegacyRef<SelectionPopover>}
						/>
					</div>
					<input
						style={{ position: "absolute", top: "-100px" }}
						id="viewerImageUpload"
						type={"file"}
						accept="image/png,image/gif,image/jpeg"
						onChange={(e) => {
							console.log(e);
							if (this.state.selectedText) this.uploadCustomImage((e.nativeEvent.target as HTMLInputElement).files?.item(0), false, this.state.selectedText);
							e.target.value = "";
						}}
					/>

					<IonToast
						id="generatingMapToast"
						onDidDismiss={() => this.setState({ showMapCreationStartToast: false })}
						isOpen={this.state.showMapCreationStartToast}
						duration={3000}
						message={
							i18n.language === "it"
								? "Generazione mappa in corso..."
								: i18n.language === "es"
									? "Generación de mapa en curso..."
									: i18n.language === "fr"
										? "Génération de carte en cours..."
										: i18n.language === "en"
											? "Map generation in progress..."
											: i18n.language === "ca"
												? "Generació del mapa en curs..."
												: "Kartenerstellung läuft..."
						}
					/>

					<TtsPlayer
						dictionaryOpen={this.state.dictionaryIsOpen}
						scrolled={this.state.howMuchScrolled}
						showPlayer={this.state.showTtsPlayer}
						audioToPlay={this.state.audioToPlay}
						isFirstParagraph={this.state.ttsParagraphPointer === 0}
						isLastParagraph={Number(this.state.ttsParagraphPointer) === Number(this.state.lastParagraphIndex) && this.state.lastParagraphofPages}
						disablePlayButton={this.state.disablePlayButton}
						disableTTSSkipButtons={this.state.disableTTSSkipButtons}
						disableOptions={this.state.disableTTSOptions}
						closeDictionary={() => {
							(this.selectionPopoverRef.current as any).showPopover(false);
							this.setState({ dictionaryIsOpen: false });
						}}
						onBackwardPressed={() => {
							if (!isMobile)
								if (
									Number(this.state.ttsParagraphPointer) === Number(this.state.startParagraphIndex) &&
									arraySummaryPage.length > 1 &&
									Number(this.state.ttsParagraphPointer) !== 0
								) {
									this.setState(
										{ speechMarks: "" }
									)
									this.PageMinus();
								}
							handleTTSButtonPress.call(this, "backward");
						}}
						onForwardPressed={() => {
							if (!isMobile) {
								console.log(arraySummaryPage)
								if (Number(this.state.lastParagraphIndex) === Number(arraySummaryPage[arraySummaryPage.length - 1].lastChild.id)) this.setState({ lastParagraphofPages: true });
								if (Number(this.state.ttsParagraphPointer) === Number(this.state.lastParagraphIndex) && arraySummaryPage.length > 1) {
									this.setState(
										{ speechMarks: "" }
									)
									this.PagePlus();
								}
							}
							handleTTSButtonPress.call(this, "forward");
						}}
						onRateChange={(Rate) => {
							handleTTSOptionChange.call(this, "Rate", Rate);
						}}
						onVoiceChange={(Voice) => {
							handleTTSOptionChange.call(this, "Voice", Voice);
						}}
						onPlaybackEnded={() => {
							if (this.state.elementsTTS && this.state.elementsTTS.length > 0) {
								this.setState({ disableTTSSkipButtons: true, disablePlayButton: true, disableTTSOptions: true });
								const currentPointer = this.state.ttsParagraphPointer || 0;

								if (this.state.elementsTTS.length > currentPointer + 1) {
									this.setState({ ttsParagraphPointer: currentPointer + 1 }, () => {
										if (this.state.userUuid) {
											settingsServices.getUserSettings(this.state.userUuid,this.state.bearerToken).then((ttsSettings) => {
												if (this.state.elementsTTS && this.state.ttsParagraphPointer !== null) {
													if (!isMobile) {
														if (Number(this.state.ttsParagraphPointer) <= Number(this.state.lastParagraphIndex)) {
															handleTTSLogic.call(this, ttsSettings);
														} else {
															this.PagePlus();
															handleTTSLogic.call(this, ttsSettings);
														}
													} else if (isMobile) {
														if (this.state.ttsParagraphPointer < this.state.elementsTTS.length) {
															// Additional logic for mobile
														}
														handleTTSLogic.call(this, ttsSettings);
													}
												}
											});
										}
									});
								} else {
									this.setState({ disableTTSSkipButtons: false, disablePlayButton: false, disableTTSOptions: false });
								}
							} else {
								this.setState({ ttsParagraphPointer: null });
							}
						}}
					/>
					{/*  <IonFooter className='fileViewerMobileFooter' hidden={!isMobile || !this.state.sectionSelectMode}>
                        <IonToolbar > */}
					{/*  <div
                                    // hidden={!isMobile && !this.state.sectionSelectMode}
                                    className='fileViewerSelectedSectionsDiv'
                                > */}
					{/*  <p style={{ marginLeft: '15px' }} slot='start'>
                                {this.state.selectedSections.length} {this.state.selectedSections.length === 1 ? <Trans>Sezione</Trans> : <Trans>Sezioni</Trans>}
                            </p> */}
					{isMobile && this.state.sectionSelectMode && (
						<div className="createMapButtonDiv">
							<IonButton
								slot="end"
								hidden={!this.state.sectionSelectModeMap}
								disabled={this.state.selectedSections.length < 2}
								className="createMapButton"
								onClick={async () => {
									this.createConceptualMaps();
								}}
							>
								{/* MOBILE */}
								<Trans>Crea Mappa</Trans>
							</IonButton>
							{/* <IonButton
                                slot='end'
                                fill='outline'
                                className='annullaMappa'
                                style={{ borderRadius: '15px' }}
                                onClick={() => {
                                    let documentParagraphs = document.getElementsByClassName('fileViewerParagraph')
                                    for (let i = 0; i < documentParagraphs.length; i++) {
                                        let el = documentParagraphs[i] as HTMLElement;
                                        el.style.backgroundColor = ''
                                        if (!this.state.isDarkMode)
                                            el.style.color = 'unset'
                                    }
                                    this.setState({ selectedMapType: '', sectionSelectMode: false, selectedSections: [], sectionSelectModeMap: false, sectionSelectModeSummary: false })
                                }}>
                                <Trans>Annulla</Trans>
                            </IonButton> */}
						</div>
					)}
					{/* </div> */}
					<div className="mapButtonMobileDiv">
						{/* <IonButton
							className="createMapButton"
							disabled={this.state.selectedSections.length < 1}
							hidden={!this.state.sectionSelectModeSummary || !isMobile}
							onClick={() => {
								this.setState({ sectionSelectModeSummary: false, sectionSelectMode: false, infoMsg: true });
								let documentParagraphs = document.getElementsByClassName("fileViewerParagraph");
								let totalSize = 0;
								let checkBoxes = document.querySelectorAll('[id^="check-"]');
								checkBoxes.forEach((el) => el.remove());
								for (let i = 0; i < documentParagraphs.length; i++) {
									let el = documentParagraphs[i] as HTMLElement;
									el.style.backgroundColor = "";
									el.style.marginLeft = "unset";
									el.style.color = "var(--txt-color)";
								}

								for (let i = 0; i < documentParagraphs.length; i++) {
									const divSize = ((documentParagraphs[i] as HTMLParagraphElement).innerText.length * 2) / 1024; // calculate size in kb
									totalSize += divSize;
								}
								//console.log(`Total size: ${totalSize.toFixed(2)} kb`);
								for (let i = 0; i < documentParagraphs.length; i++) {
									let el = documentParagraphs[i] as HTMLElement;
									el.style.backgroundColor = "";
									el.style.color = "";
									if (!this.state.isDarkMode) el.style.color = "unset";
								}
								auth.currentUser?.getIdToken().then(async (token) => {
									let bearerToken = token;
									let text = this.state.selectedSections.join(" ");
									// const createStandardMap = httpsCallable(functions, "Summary", { timeout: 600000 });
									// createStandardMap({
									//     text: text,
									//     ln: "it",
									//     docId: this.state.document!.uuid,
									//     auth: bearerToken
									// })
									if (this.state.choice) {
										this.setState({ isGeneratingKeywords: true });
									} else this.setState({ isGeneratingSummary: true });
									filesServices
										.getSummary(text, bearerToken, this.state.typeSumOrKey, this.state.document!.uuid, this.state.userUuid)
										.then(async (response) => {
											// //console.log("aaaaaa", response)
											if (response) {
												if (this.state.choice) {
													this.setState({ isGeneratingKeywords: false });
												} else this.setState({ isGeneratingSummary: false });
												this.areSummaryOrKeywords();

												if (this.state.typeSumOrKey === "summary") this.setState({ isSummaryOpen: true, summaryReadyMsg: true });
												else this.setState({ isKeyWordsOpen: true, keywordsReadyMsg: true });

												this.setState({ infoMsg: false });
												// //console.log("success")
											} else {
												//console.log("error:il summary non è andato a buon fine")
											}
											setTimeout(() => {
												//console.log("GETSUMMARY SUMMARY OPEN: ", this.state.isSummaryOpen);
											}, 500);
										})
										.catch((code) => {
											//ERROR STATUS CODE 500
											//HANDLE ERROR
											//console.log("[SUMMARY] error status code ", code);
										});
									this.setState({ selectedSections: [] }); // DO NOT REMOVE!!!
								});
							}}
						>
							{!this.state.choice && (
								<IonText>
									<Trans>Genera riassunto</Trans>
								</IonText>
							)}
							{this.state.choice && (
								<IonText>
									<Trans>Genera parole chiave</Trans>
								</IonText>
							)}
						</IonButton> */}
					</div>
					{/* </IonToolbar>
                    </IonFooter> */}
					<IonAlert
						isOpen={this.state.showMapTextErrorAlert}
						onDidDismiss={() => this.setState({ showMapTextErrorAlert: false })}
						message={
							i18n.language === "en"
								? "This text is already selected in the map you want to create."
								: i18n.language === "es"
									? "Este texto ya está seleccionado en el mapa que desea crear."
									: i18n.language === "fr"
										? "Ce texte est déjà sélectionné dans la carte que vous voulez créer."
										: i18n.language === "de"
											? "Dieser Text ist in der Karte, die Sie erstellen möchten, bereits ausgewählt"
											: i18n.language === "ca"
												? "Aquest text ja està seleccionat al mapa que vols crear"
												: "Questo testo è già selezionato nella mappa che vuoi creare"
						}
						buttons={[
							{
								text:
									i18n.language === "en"
										? "Understood"
										: i18n.language === "es"
											? "Entiendo"
											: i18n.language === "fr"
												? "J'ai compris"
												: i18n.language === "de"
													? "Ich verstehe"
													: i18n.language === "ca"
														? "Entès"
														: "Ho capito",
								handler: () => {
									this.setState({ showMapTextErrorAlert: false });
								},
							},
						]}
					/>
					<IonPopover
						mode="md"
						isOpen={this.state.showMapCreationPopover}
						className={"mapInstructionsPopover"}
					/* buttons={[
						{
							cssClass: 'primary',
							text: i18n.language === 'en' ? 'I understand' :
								i18n.language === 'es' ? 'Entiendo' :
									i18n.language === 'fr' ? "J'ai compris" :
										"Ho capito",
							handler: () => {
								this.setState({ showMapCreationPopover: false })
							}
						},
					]} */
					>
						<div className="mapInstructionsPopoverDiv">
							<IonImg style={{ marginBottom: "35px" }} src={reasyLogo} />
							{i18n.language === "en"
								? "Select the parts you want to turn into a concept map..."
								: i18n.language === "es"
									? "Selecciona las partes que quieres convertir en un mapa conceptual..."
									: i18n.language === "fr"
										? "Sélectionnez les parties que vous souhaitez transformer en carte conceptuelle..."
										: i18n.language === "de"
											? "Wählen Sie die Teile aus, die Sie in eine Konzeptkarte umwandeln möchten ..."
											: i18n.language === "ca"
												? "Selecciona les parts que vols convertir en un mapa conceptual..."
												: "Seleziona le parti che vuoi trasformare in mappa concettuale..."}
							<IonButton style={{ marginTop: "35px" }} onClick={() => this.setState({ showMapCreationPopover: false })}>
								{i18n.language === "en"
									? "I understand"
									: i18n.language === "es"
										? "Entiendo"
										: i18n.language === "fr"
											? "J'ai compris"
											: i18n.language === "de"
												? "Ich verstehe"
												: i18n.language === "ca"
													? "Entès"
													: "Ho capito"}
							</IonButton>
						</div>
					</IonPopover>
					<ClickPopover
						ref={this.clickPopoverRef as React.LegacyRef<ClickPopover>}
						isOpen={this.state.showClickPopover}
						keyWordStatus={this.state.isKeyWordsOpen}
						documentElement={this.state.clickedDocumentElement}
						textContent={undefined}
						userSettings={this.state.userSettings}
						history={this.props.history}
						uploadImageClicked={(text: string | null) => {
							document.getElementById("viewerImageUpload")?.click();
							this.setState({ selectedText: text });
						}}
						deleteCustomImage={(url: string, text: string) => {
							this.deleteCustomImage(url, text);
						}}
						removeDeletedDrawing={(url, text) => {
							this.deleteDrawing(url, text);
						}}
						onDidDismiss={() => {
							this.setState({
								showClickPopover: false,
								clickedDocumentElement: null,
							});
						}}
					/>
				</IonContent>

				{(isTablet || isMobile) && (
					<IonFab horizontal="end" vertical="bottom" className="selectionIonFabMainTM" hidden={this.state.isKeyWordsOpen?true:false}>
						<div
							onClick={() => {
								console.log(this.state.fileEditingMode);
								if (this.state.fileEditingMode) {
									this.handleSave();
									this.editingModeClicked();
								} else this.setState({ showModalSelection: true });
							}}
						>
							<IonIcon className={isMobile ? "menuIconM" : "menuIconT"} icon={this.state.fileEditingMode ? checkmarkCircle : reasyRoundedLogo} />
						</div>
					</IonFab>
				)}
				<IonModal
					hidden={this.state.isKeyWordsOpen? true:false}
					mode="ios"
					className={"ViewerModalSelectionFunctionality"}
					isOpen={this.state.showModalSelection}
					onDidPresent={() => {
						if (this.state.tutorialMobile)
							this.setState({ tutorial: true }, () => {
								sendEvent({
									"user_id": this.state.userUuid,
									"event_type": "Tutorial opened",
									"event_properties": {
										"user_org": this.props.organizationName !== null ? this.props.organizationName : "Private User",
										"page": "File Viewer",
									},
									"language": i18n.language,
									"platform": isPlatform("ios") ? "ios" : isPlatform("android") ? "android" : "desktop",
									"app_version": appVersion,
									"time": Date.now(),
								});
							});
					}}
					onDidDismiss={() => this.setState({ showModalSelection: false })}
				>
					<IonItem lines="none" slot="end" className={"ViewerModalSelectionFunctionalityTitle"}>
						<IonLabel>
							<Trans>Funzioni</Trans>
						</IonLabel>
						<IonIcon
							className="ViewerModalSelectionFunctionalityIcon"
							icon={closeOutline}
							onClick={() => {
								this.setState({ showModalSelection: false });
							}}
						></IonIcon>
					</IonItem>
					<div className="functionModalGrid">
						<IonItem
							hidden
							id="div4"
							lines="none"
							className={"selectionItemFunctionality"}
							onClick={async () => {
								let fromButton = true;
								if (this.state.paywallState) loadPaywall(fromButton);
								else {
									if (this.state.isKeyWordsOpen) viewSummary.call(this);
									this.setState({
										showModalSelection: false,
										sectionSelectModeSummary: false,
										sectionSelectMode: false,
										isKeyWordsOpen: false,
										showTtsPlayer: false,
									});
									this.handleChkBoxCreation();
									for (let i = 0; i < document.getElementsByClassName("fileViewerParagraph").length; i++) {
										(document.getElementsByClassName("fileViewerParagraph")[i] as HTMLElement).classList.remove("paragraphinReading");
									}
									if (this.state.fileEditingMode) {
										this.setState({ fileEditingMode: false });
										this.editingModeClicked();
									}
									if (this.state.document && this.state.document.uuid) {
										await getDoc(doc(firestore, `m/${this.state.document?.uuid}/`)).then((documentSnapshot) => {
											if (documentSnapshot.exists()) {
												this.props.history.push({
													pathname: "/maps",
													state: {
														docTitle: this.state.document?.name,
														documentData: documentSnapshot.data(),
														docId: this.state.document!.uuid,
														paragraphs: this.state.documentElements,
													},
												});
											} else {
												this.setState({
													sectionSelectMode: true,
													sectionSelectModeMap: true,
													showMapCreationPopover: true /*  showMapCreationConfirm: true */,
												});
												let paragraphs = document.getElementsByTagName("p");
												for (let i = 0; i < paragraphs.length; i++) {
													const p = paragraphs[i];
													let checkBox = document.createElement("div");
													checkBox.className = "squareSelector";
													checkBox.id = "check-" + p.id;
													p.style.marginLeft = "40px";
													checkBox.onclick = p.onclick;
													p.parentNode!.insertBefore(checkBox, p);
												}
											}
										});
									}
								}
							}}
						>
							<IonIcon icon={MapsButtonLogo} className={"selectionIconFunctionality"}></IonIcon>
							<IonLabel className="selectionLabelModalFunctionality">
								<Trans>Mappa</Trans>
							</IonLabel>
						</IonItem>

						<IonItem
						hidden
							id="div2"
							lines="none"
							className={"selectionItemFunctionality"}
							onClick={() => {
								let fromButton = true;
								if (this.state.paywallState) loadPaywall(fromButton);
								else {
									this.setState({
										showModalSelection: false,
										sectionSelectModeMap: false,
										sectionSelectModeSummary: false,
										sectionSelectMode: false,
										selectedSections: [],
										selectedMapType: "",
									});
									if (this.state.fileEditingMode) {
										this.setState({ fileEditingMode: false });
										this.editingModeClicked();
									}

									if ((this.state.isGeneratingSummary && !this.state.keywordsState) || this.state.isGeneratingKeywords) {
										return;
									}
									if (!this.state.isKeyWordsOpen) {
										if (this.state.keywordsState) {
											this.setState({ isKeyWordsOpen: true, isSummaryOpen: false });
											viewSummary.call(this);
										} else {
											if (this.state.isSummaryOpen) {
												this.setState({ isSummaryOpen: false }); /* ALSO APPLY ON MOBILE */
												viewSummary.call(this);
											}
											this.setState({ summaryIsClicked: true, typeSumOrKey: "kewords", choice: true });
										}
									} else {
										viewSummary.call(this);
										this.setState({ isKeyWordsOpen: false });
									}
								}
							}}
						>
							<IonIcon icon={keyWord} className={"selectionIconFunctionality"}></IonIcon>
							<IonLabel className={"selectionLabelModalFunctionality"}>
								<Trans>Parole chiave</Trans>
							</IonLabel>
						</IonItem>

						<IonItem
							hidden
							id="div5"
							lines="none"
							className="selectionItemFunctionality"
							onClick={() => {
								let fromButton = true;
								if (this.state.paywallState) loadPaywall(fromButton);
								else {
									this.setState({
										showModalSelection: false,
										sectionSelectModeMap: false,
										sectionSelectMode: false,
										selectedSections: [],
										selectedMapType: "",
									});
									if (this.state.fileEditingMode) {
										this.setState({ fileEditingMode: false });
										this.editingModeClicked();
									}
									if (this.state.isGeneratingSummary || (this.state.isGeneratingKeywords && !this.state.summaryState)) {
										return;
									}
									if (this.state.showTtsPlayer) {
										document.getElementById("textgrid")!.classList.remove("spostaTesto");
										for (let i = 0; i < document.getElementsByClassName("fileViewerParagraph").length; i++) {
											(document.getElementsByClassName("fileViewerParagraph")[i] as HTMLElement).classList.remove("paragraphinReading");
										}
										this.showFileViewerPlayer(!this.state.showTtsPlayer);
									}
									if (!this.state.isSummaryOpen) {
										if (this.state.summaryState) {
											this.setState({ isSummaryOpen: true, isKeyWordsOpen: false });
											viewSummary.call(this);
										} else {
											this.setState({ isKeyWordsOpen: false });
											viewSummary.call(this);
											this.setState({ summaryIsClicked: true, typeSumOrKey: "summary", choice: false });
										}
									} else {
										viewSummary.call(this);
										this.setState({ isSummaryOpen: false });
									}
								}
							}}
						>
							<IonIcon icon={genSumm} className="selectionIconFunctionality"></IonIcon>
							<IonLabel className="selectionLabelModalFunctionality">
								<Trans>Riassunto</Trans>
							</IonLabel>
						</IonItem>

						<IonItem
							id="div1"
							lines="none"
							className="selectionItemFunctionality"
							onClick={() => {
								if (this.state.paywallState) loadPaywall(true);
								else {
									this.setState({ showModalSelection: false });
									this.handleChkBoxCreation();
									if (this.state.fileEditingMode) {
										this.setState({ fileEditingMode: false });
										this.editingModeClicked();
									}
									let elementsToSynthetize: any = null;
									if (this.state.isSummaryOpen && this.state.summaryDocumentElements && this.state.summaryDocumentElements?.length > 0)
										elementsToSynthetize = this.state.summaryDocumentElements;
									else elementsToSynthetize = this.state.documentElements;
									if (
										this.state.generatedAudios.filter(
											(el) =>
												!this.state.isSummaryOpen &&
												el.text === this.state.documentElements![this.state.ttsParagraphPointer!].text &&
												el.settings.speech_rate === this.state.generatedAudios[0].settings.speech_rate &&
												el.settings.speech_gender === this.state.generatedAudios[0].settings.speech_gender
										).length === 0 &&
										this.state.generatedAudios.filter(
											(el) => this.state.summaryDocumentElements && el.text === this.state.summaryDocumentElements![this.state.ttsParagraphPointer!].text
										).length === 0
									) {
										this.setState({
											disablePlayButton: true,
											disableTTSOptions: true,
											disableTTSSkipButtons: true,
										});
										settingsServices.getUserSettings(this.state.userUuid,this.state.bearerToken).then((ttsSettings) => {
											getTTS(elementsToSynthetize, elementsToSynthetize[this.state.ttsParagraphPointer!].text, ttsSettings)
												.then((audio) => {
													sendEvent({
														"user_id": this.state.userUuid,
														"event_type": "TTS pressed",
														"event_properties": {
															"user_org": this.props.organizationName !== null ? this.props.organizationName : "Private User",
															"document_uuid": this.state.document?.uuid,
															"document_name": this.state.document?.name,
														},
														"language": i18n.language,
														"platform": isPlatform("ios") ? "ios" : isPlatform("android") ? "android" : "desktop",
														"app_version": appVersion,
														"time": Date.now(),
													});
													let tempAudios = this.state.generatedAudios;
													tempAudios.push({
														id: elementsToSynthetize[0].index,
														text: elementsToSynthetize[this.state.ttsParagraphPointer!].text,
														audio: audio.audio,
														speechMarks: audio.speech_marks,
														settings: ttsSettings,
													});
													this.setState(
														{
															audioToPlay: audio.audio as string,
															speechMarks: audio.speech_marks,
															generatedAudios: tempAudios,
															disablePlayButton: false,
															disableTTSOptions: false,
															disableTTSSkipButtons: false,
														},
														() => {
															this.changeExistingSpans();

															this.getKaraoke(this.state.documentElements!);
														}
													);
												})
												.catch((err) => {
													console.error("[FileViewer] error getting tts:", err);
												});
										});
									} else {
										if (!this.state.showTtsPlayer) {
											sendEvent({
												"user_id": this.state.userUuid,
												"event_type": "TTS pressed",
												"event_properties": {
													"user_org": this.props.organizationName !== null ? this.props.organizationName : "Private User",
													"document_uuid": this.state.document?.uuid,
													"document_name": this.state.document?.name,
												},
												"language": i18n.language,
												"platform": isPlatform("ios") ? "ios" : isPlatform("android") ? "android" : "desktop",
												"app_version": appVersion,
												"time": Date.now(),
											});
											this.getKaraoke(this.state.documentElements!);
											let Audio = document.getElementById("audioElement") as HTMLAudioElement;
											Audio.src = this.state.generatedAudios.filter((el) => el.text === elementsToSynthetize![this.state.ttsParagraphPointer!].text)[0].audio;
											Audio.play();
										} else {
											let Audio = document.getElementById("audioElement") as HTMLAudioElement;
											Audio.pause();
											// this.removeTTSHighlighting();
										}
									}
									//put text in a variable for the TTS so it play correctly for summary or normal file
									if (this.state.isSummaryOpen) this.setState({ elementsTTS: this.state.summaryDocumentElements });
									else this.setState({ elementsTTS: this.state.documentElements });

									//console.log(("gionitan", this.state.ttsParagraphPointer);
									if (this.state.isSummaryOpen) {
										this.setState({ elementsTTS: this.state.summaryDocumentElements });
									} else this.setState({ elementsTTS: this.state.documentElements });

									if (!this.state.showTtsPlayer) {
										//put text in a variable for the TTS so it play correctly for summary or normal file
										// document.getElementById((String(this.state.ttsParagraphPointer)))!.classList.add("paragraphinReading");
									} else {
										let Audio = document.getElementById("audioElement") as HTMLAudioElement;
										Audio.pause();
										document.getElementById("textgrid")!.classList.remove("spostaTesto");
										// this.removeTTSHighlighting();
									}
									this.showFileViewerPlayer(!this.state.showTtsPlayer);
								}
							}}
						>
							<IonIcon icon={volumeMediumOutline} className="selectionIconFunctionality"></IonIcon>
							<IonLabel className="selectionLabelModalFunctionality">
								<Trans>Sintesi vocale</Trans>
							</IonLabel>
						</IonItem>

						<IonItem
						hidden
							id="div3"
							lines="none"
							className={"selectionItemFunctionality"}
							onClick={() => {
								let fromButton = true;
								if (this.state.paywallState) loadPaywall(fromButton);
								else {
									this.setState({
										showModalSelection: false,
										sectionSelectModeMap: false,
										sectionSelectModeSummary: false,
										sectionSelectMode: false,
										selectedSections: [],
										selectedMapType: "",
									});
									this.handleChkBoxCreation();
									this.editingModeClicked();

									if (this.state.showTtsPlayer) {
										document.getElementById("textgrid")!.classList.remove("spostaTesto");
										for (let i = 0; i < document.getElementsByClassName("fileViewerParagraph").length; i++) {
											(document.getElementsByClassName("fileViewerParagraph")[i] as HTMLElement).classList.remove("paragraphinReading");
										}
										this.showFileViewerPlayer(!this.state.showTtsPlayer);
									}
								}
							}}
						>
							<IonIcon icon={ediText} className={"selectionIconFunctionality"}></IonIcon>
							<IonLabel className={"selectionLabelModalFunctionality"}>
								<Trans>Modifica</Trans>
							</IonLabel>
						</IonItem>
					</div>
					<IonIcon
						className="helpIconModal"
						style={{ fontSize: '25px' }}
						onClick={() => {
							this.setState({ tutorial: true }, () => {
								sendEvent({
									"user_id": this.state.userUuid,
									"event_type": "Tutorial opened",
									"event_properties": {
										"user_org": this.props.organizationName !== null ? this.props.organizationName : "Private User",
										"page": "File Viewer",
									},
									"language": i18n.language,
									"platform": isPlatform("ios") ? "ios" : isPlatform("android") ? "android" : "desktop",
									"app_version": appVersion,
									"time": Date.now(),
								});
							});
						}}
						icon={helpCircleOutline}
					></IonIcon>
				</IonModal>

				<IonLoading isOpen={this.state.isLoading} message={this.state.loadingMessage} />
				<IonAlert
					isOpen={this.state.showMapTypeSelector}
					cssClass="custom-alert"
					onDidDismiss={() => this.setState({ showMapTypeSelector: false })}
					inputs={[
						{
							name: `mapType`,
							id: "btnstandard",
							type: "radio",
							label: `Standard ${this.state.selectedMapType === "standard" ? "- Selezionato" : ""}`,
							value: "1",
							checked: true,
						},
						{
							name: `mapType`,
							id: "btnKeywords",
							type: "radio",
							label:
								i18n.language === "it"
									? `Parole chiave ${this.state.selectedMapType === "keywords" ? "- Selezionato" : ""}`
									: i18n.language === "en"
										? `Keywords ${this.state.selectedMapType === "keywords" ? "- Selected" : ""}`
										: i18n.language === "es"
											? `Palabras clave ${this.state.selectedMapType === "keywords" ? "- Seleccionado" : ""}`
											: i18n.language === "fr"
												? `Mots-clés ${this.state.selectedMapType === "keywords" ? "- Sélectionné" : ""}`
												: i18n.language === "ca"
													? `Paraules clau ${this.state.selectedMapType === "keywords" ? "- Seleccionat" : ""}`
													: `Stichwort ${this.state.selectedMapType === "keywords" ? "- Ausgewählt" : ""}`,
							value: "2",
						},
					]}
					buttons={[
						{
							text: "Annulla",
							role: "cancel",
							cssClass: "secondary",
							handler: () => {
								//console.log('Confirm Cancel');
							},
						},
						{
							text: "Prosegui",
							handler: () => {
								let fromButton = true;
								if (this.state.paywallState) loadPaywall(fromButton);
								else {
									if ((document.getElementById("btnstandard") as any).ariaChecked) {
										this.setState({ sectionSelectMode: true, sectionSelectModeMap: true, selectedMapType: "standard", fileEditingMode: false });
										////console.log(this.state.sectionSelectMode);
										let paragraphs = document.getElementsByClassName("fileViewerParagraph");
										for (let i = 0; i < paragraphs.length; i++) {
											let el = paragraphs[i] as HTMLElement;
											el.contentEditable = "false";
											el.style.backgroundColor = "";
										}
									} else if ((document.getElementById("btnKeywords") as any).ariaChecked) {
										this.setState({ sectionSelectMode: true, sectionSelectModeMap: true, selectedMapType: "keywords", fileEditingMode: false });
										let paragraphs = document.getElementsByClassName("fileViewerParagraph");
										for (let i = 0; i < paragraphs.length; i++) {
											let el = paragraphs[i] as HTMLElement;
											el.contentEditable = "false";
											el.style.backgroundColor = "";
										}
									}
								}
							},
						},
					]}
				/>
			</IonPage>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {
		showTutorial: state.tutorials.showFileViewerTutorial ? true : false,
		disabledButtons: state.maps.disabledButtons,
		orgData: state.user.organization,
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		disableMapButton: (uuid: string) => {
			dispatch(mapsActions.disableMapButton(uuid));
		},
		enableMapButton: (uuid: string) => {
			dispatch(mapsActions.enableMapButton(uuid));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SummaryOnlyViwer);
