import { DocumentData, DocumentReference, addDoc, collection, doc, getDoc, getDocs, onSnapshot, query, updateDoc, where } from "firebase/firestore";
import { checkmark, timeOutline } from "ionicons/icons";
import { isMobile } from "react-device-detect";
import { auth, firestore, functions } from "../../../firebaseConfig";
import i18n from "../../../i18n";
import { getTTS } from "../../popover/common/ttsServices";
import { Coloring, DocumentFontSettings, fromBackendFontNameToCssFontFamily, Highlight, settingsServices, UserSettings } from "../../settings/store/services";
import { ttsServices } from "../../tts/store/services";
import { Paragraph, array } from "../pages/FileViewer";
import { Document, DocumentElement } from "../store/reducers";
import { filesServices, setLocalStorageItem } from "../store/services";
import MapsButtonLogo from "../../../assets/icons/map.svg"; //!
import { TTSSettings } from "../../tts/store/reducers";
import { sendEvent } from "../../../common/amplitudeUtils";
import { isPlatform } from "@ionic/react";
import { appVersion } from "../../../appVersion";
import { toastController, popoverController } from "@ionic/core";
import { httpsCallable } from "firebase/functions";
import html2pdf from "html2pdf.js";
import { arraySummaryPage } from "../pages/SummaryOnlyViewer";
import { Block, JsonData, Page,Paragraph as paragraphNewStructure} from "./interfacesUtils";



export function generatePdf(_this: any, documentInfo: any) {
	let contentNodes = (document.getElementsByClassName("fileViewerTextSelectable")[0] as HTMLDivElement).childNodes;
	let documentData: any[] = [];
	if (isMobile) {
		if (!_this.props.summarySelected && !_this.props.keyWordsSelected) {
			//generate pdf with props.documentElements
			documentData = _this.props.documentElements;
		} else if (_this.props.summarySelected) {
			contentNodes.forEach(
				(node) => documentData.push(node.textContent)
				// console.log(node.textContent)
			);
		} else if (_this.props.keyWordsSelected) {
			contentNodes.forEach((node, i) => {
				// console.log(node.textContent);
				if (i === 0) documentData.push(node.textContent);
				if ((node as HTMLElement).className === "keyWordsContainer") {
					(node as HTMLElement).childNodes.forEach((child) => {
						documentData.push(child.textContent);
					});
				}
			});
		}
		console.log("DOCUMENT DATA: ", documentData);


		_this.setState({
			isLoading: true,
			loadingMessage:
				i18n.language === "it"
					? "Generazione del file PDF in corso..."
					: i18n.language === "en"
						? "Generating PDF file in progress..."
						: i18n.language === "es"
							? "Generando archivo PDF en progreso..."
							: i18n.language === "fr"
								? "Génération du fichier PDF en cours..."
								: "PDF-Datei wird erstellt...",
		});
		const generatePdf = httpsCallable(functions, "generatePdf-generatePdf");
		generatePdf({ documentName: documentInfo?.name, documentUuid: documentInfo?.uuid, documentData: documentData, lang: i18n.language })
			.then((res: any) => {
				console.log("[FileViewer] generatePdf cloud function res:", res);
				let downloadUrl = "";
				if (res.data) downloadUrl = res.data.body.url;
				_this.props.pdfReady(downloadUrl);
				_this.setState({ isLoading: false, loadingMessage: "", downloadUrl: downloadUrl });
				// this.props.history.push("/cart/quote-sent")
			})
			.catch((err) => {
				console.error("[FileViewer] calling generatePdf cloud function:", err);
				// this.setState({ isLoadingSendingQuote: false });
			});
	} else {
		// console.log("alemno qua ci arrivi?")
		let docToDownload = document.getElementsByClassName("fileViewerTextSelectable")[0] as HTMLDivElement;
		let oldColor = docToDownload.style.color;
		console.log("doc COLOR: ", docToDownload.style);

		let oldBg = docToDownload.style.background;
		if (docToDownload.style.color != "#000") {
			docToDownload.style.color = "#000";
			docToDownload.style.background = "#fff";
		}
		//Remove the fade in animation to avoid the pdf to be blurry
		document.getElementsByClassName('animated-element')[0].classList.remove('animated-element');
		// Convert HTML content to PDF using html2pdf
		html2pdf()
			.set({ margin: [10, 10, 10, 10], useCORS: true, pagebreak: { mode: "avoid-all" } })
			.from(docToDownload)
			.save(documentInfo?.name)
			.then(() => {
				if (docToDownload.style.color == "rgb(0, 0, 0)") {
					docToDownload.style.color = oldColor;
					docToDownload.style.background = oldBg;
					document.getElementsByClassName('animated-element')[0].classList.add('animated-element');

				}
				_this.setState({ isKebabMenu: false });
			});
	}
}

export function areSummaryOrKeywords(this: any) {
	//Sets the state of summary
	const documentDataQuerySummary = query(collection(firestore, `s/${this.props.match.params.fileId}/s`), where("p", "!=", ""));
	getDocs(documentDataQuerySummary)
		.then((querySnapshot) => {
			if (!querySnapshot.empty) {
				this.setState({ summaryState: true });
			} else {
				this.setState({ summaryState: false });
				//console.log((false);
			}
		})
		.catch((error) => {
			console.error(error);
		});
	//Sets the state of KeyWords
	const documentDataQueryKeywords = query(collection(firestore, `s/${this.props.match.params.fileId}/s`), where("k", "!=", ""));
	//console.log((documentDataQueryKeywords)
	getDocs(documentDataQueryKeywords)
		.then((querySnapshot) => {
			if (!querySnapshot.empty) {
				this.setState({ keywordsState: true });
			} else {
				this.setState({ keywordsState: false });
				//console.log((false);
			}
		})
		.catch((error) => {
			// console.error(error);
		});
}

export async function processText(this: any, documentElements: DocumentElement[], colorings: Coloring[], userSettings?: UserSettings) {
	let globalWordCounter = 0;
	let speechMarksWithSpaces: any[] = [];
	//console.log(("\n\n\------------------------------\n\n\nPROCESS TEXT\n\n\n------------------------------\n\n\n");
	let keyWordPresent = false;
	let pages: HTMLParagraphElement[] = [];
	const divSection = document.createElement("div");
	divSection.classList.add("fileViewerTextSelectable");
	const textSection = document.createElement("div");
	// textSection.classList.add("fileViewerTextSelectable");

	let tempParagraphs: Paragraph[] = [];
	// console.log(documentElements,this.state.isKeyWordsOpen)
	documentElements.forEach(async (el) => {
		let tempParagraph: Paragraph = { index: el.index, text:  el.text, keywords: el.keyWord };
		tempParagraphs.push(tempParagraph);
	});
	// console.log(tempParagraphs)
	this.setState({ initialParagraphs: tempParagraphs, isLoading: false });
	const originalSM = this.state.speechMarks;

	if (userSettings) {
		divSection.style.fontFamily = fromBackendFontNameToCssFontFamily(userSettings.font_name?userSettings.font_name:"arial");
		divSection.style.fontSize = `${userSettings.font_size}px`;
		divSection.style.lineHeight = `${100 + (userSettings.line_height?userSettings.line_height:10)}%`;
	}
	let summTitleInserted = false;
	let currentIndex = 0;
	const batchSize = 100; // Adjust the batch size according to your needs

	function processBatch(this: any, pages: HTMLParagraphElement[]) {
		requestAnimationFrame(async () => {
			const observer = new IntersectionObserver(
				(entries, observer) => {
					entries.forEach((entry) => {
						let paragraph = entry.target as HTMLElement;

						if (entry.isIntersecting) {
							loadParagraph.call(this, paragraph); // Load or process paragraph content here
							observer.unobserve(paragraph); // Optionally unobserve after loading
						}
					});
				},
				{
					root: null, // Use the viewport as the root
					rootMargin: "0px",
					threshold: 0.1, // Trigger when 10% of the element is visible
				}
			);

			const endIndex = Math.min(currentIndex + batchSize, documentElements.length);
			for (; currentIndex < endIndex; currentIndex++) {
				const elementData = documentElements[currentIndex];
				// console.log(elementData.text)

				if (elementData.text === "") continue;

				let paragraph = document.createElement("p");
				paragraph.id = elementData.index.toString();
				paragraph.className = "fileViewerParagraph";
				if (this.state.speechMarks && this.state.speechMarks.length > 0) {
					let text = this.state.isKeyWordsOpen ? documentElements[currentIndex].keyWord || "" : documentElements[currentIndex].text;
					const words = this.splitTextIntoWords(text);

					//console.log("SM: ", this.state.speechMarks);
					if (this.state.ttsParagraphPointer === Number(paragraph.id) && this.state.speechMarks) {
						let currentIndex = 0; // Keep track of the current position in the originalText
						// let text = paragraph!.innerText

						this.state.speechMarks.forEach((speechMark: any, index: any) => {
							// Find the start position of the current speechMark in the original text
							let smValue = /^word-\d+-\d+-.+$/.test(speechMark.value) ? speechMark.value.split("-")[3] : speechMark.value;
							const start = text.indexOf(smValue, currentIndex);
							const end = start + smValue.length;
							//console.log("FOR this SM: ", speechMark, " got this: ", smValue);

							// Create spans for the text before and within the current speechMark
							if (start > currentIndex) {
								let beforeSpan = document.createElement("span");
								beforeSpan = this.highlight(text.substring(currentIndex, start), beforeSpan, this.state.documentElements!, this.state.colorings!);
								//console.log("BEFORE SPAN: ", beforeSpan.innerHTML);
								// beforeSpan.textContent = text.substring(currentIndex, start);
								paragraph.appendChild(beforeSpan);
							}
							let speechMarkSpan = document.createElement("span");
							// const spanId = `word-${this.state.ttsParagraphPointer}-${index}-${smValue.replace(/[^\w\s\u00C0-\u00FF']|(?<=\w)'(?=\w)/g, '')}`;
							speechMarkSpan = this.highlight(smValue.replace("\u00a0", " "), speechMarkSpan, this.state.documentElements!, this.state.colorings!);
							// speechMarkSpan.id = spanId;
							//console.log("SM SPAN: ", speechMarkSpan.innerHTML);
							// speechMarkSpan.textContent = speechMark.value.replace('\u00a0', ' ');
							paragraph.appendChild(speechMarkSpan);
							// isMobile && speechMarkSpan.appendChild(document.createTextNode(' '));

							// Update currentIndex to the end of the current speechMark
							currentIndex = end;
						});

						// Append the remaining text after the last speechMark
						if (currentIndex < text.length) {
							//console.log(currentIndex, "<", text.length);
							let afterSpan = document.createElement("span");
							afterSpan = this.highlight(text.substring(currentIndex), afterSpan, this.state.documentElements!, this.state.colorings!);
							//console.log("AFTER SPAN: ", afterSpan.innerHTML);
							// afterSpan.textContent = text.substring(currentIndex);
							paragraph.appendChild(afterSpan);
						}
					} else {
						// Add words as span elements
						words.forEach((word: any, index: any) => {
							if (word === "") return;
							let wordSpan = document.createElement("span");
							wordSpan = this.highlight(word, wordSpan, documentElements, colorings);
							// if (/^[^a-zA-Z0-9]+$/.test(word))
							//     //console.log("ADIJASHDUYSAND: ", word);
							const normalizedWord = word.toLowerCase().replace(/[^\w\s\u00C0-\u00FF']|'\B|\B'/g, "") + "-" + this.numberWsUnderline++; // remove all special characters
							wordSpan.id = "word-" + (normalizedWord ? normalizedWord : this.numberWsUnderline++);

							if (index < words.length - 1) {
								wordSpan.appendChild(document.createTextNode(" ")); //  Add whitespace between words
							}
							paragraph.appendChild(wordSpan);
							globalWordCounter += 1;
						});
					}

					// pages.push(paragraph);
				} else {
					let text = this.state.isKeyWordsOpen ? documentElements[currentIndex].keyWord || "" : documentElements[currentIndex].text;
					const words = this.splitTextIntoWords(text);
					words.forEach((word: any, index: any) => {
						let wordSpan = document.createElement("span");
						wordSpan = this.highlight(word, wordSpan, documentElements, colorings);
						// if (/^[^a-zA-Z0-9]+$/.test(word))
						//     //console.log("ADIJASHDUYSAND: ", word);
						const normalizedWord = word.toLowerCase().replace(/[^\w\s\u00C0-\u00FF']|'\B|\B'/g, "") + "-" + this.numberWsUnderline++; // remove all special characters

						wordSpan.id = "word-" + (normalizedWord.length == 0 ? this.numberWsUnderline++ : normalizedWord);

						if (index < words.length - 1) {
							wordSpan.appendChild(document.createTextNode(" ")); //  Add whitespace between words
						}
						paragraph.appendChild(wordSpan);
						globalWordCounter += 1;
					});
					//if summary button is clicked and there are keywords, show them
					if (this.state.isKeyWordsOpen && !keyWordPresent && documentElements[0].keyWord !== undefined) {
						let counter = 0;
						let keywords = document.createElement("p");
						let keywordsTitle = document.createElement("p");
						let keyWordSeparate = documentElements[0].keyWord.replace(/\n\n|Parole chiave:/g, "").split(/[-_,.]+/);
						keywords.className = "keyWordsContainer";
						//console.log("praa", keywords.className)
						keywords.addEventListener("keyup", (event) => this.handleParagraphChange(event, Number(paragraph.id)));

						const lastItem = keyWordSeparate.slice(-1)[0]; // get the last item
						if (lastItem === "") keyWordSeparate.pop(); // check if there is whitespace at the end of the array and remove it if it is
						if (keyWordSeparate[0] === "") keyWordSeparate.shift(); // check if there is whitespace at the start of the array and remove it if it is

						keyWordSeparate.forEach((element) => {
							let keyWordsSpan = document.createElement("p");
							keyWordsSpan = this.highlight(element, keyWordsSpan, documentElements, colorings);
							keyWordsSpan.className = "keyWordsStyle";
							keyWordsSpan.id = String(counter);
							keywords.id = "0";
							if (userSettings) {
								keyWordsSpan.style.fontFamily = fromBackendFontNameToCssFontFamily(userSettings.font_name?userSettings.font_name:"arial");
								keyWordsSpan.style.fontSize = `${userSettings.font_size}px`;
								keyWordsSpan.style.lineHeight = `${100 + (userSettings.line_height?userSettings.line_height:10)}%`;
							}
							keywords.appendChild(keyWordsSpan);
							counter++;
						});
						keywordsTitle.innerText = i18n.t("Parole chiave");
						keywordsTitle.style.fontSize = "35px";
						// keywords.addEventListener('focusout', () => { this.handleParagraphFocusOut(keywords, documentElements, i); paragraph.style.border = '0px'; })

						divSection.appendChild(keywordsTitle);
						divSection.appendChild(keywords);

						keyWordPresent = true;
						if (isMobile) {
							keywords.onclick = () => {
								// console.log("document elements: ", documentElements);
								// console.log("current Index: ", currentIndex);
								// console.log("documentElements[currentIndex]: ", documentElements[currentIndex]);

								if (!this.state.fileEditingMode) {
									this.setState({
										showClickPopover: true,
										clickedDocumentElement: documentElements[0],
									});
								}
							};
						}
					}
					if (!this.state.isKeyWordsOpen) divSection.appendChild(paragraph);
					// pages.push(paragraph);
				}
				if (!this.state.isSummaryOpen && !this.state.isKeyWordsOpen) {
					//array.length = 0;
					if (!this.state.originalFile) {
						
					}
				} else if (this.state.isSummaryOpen) {
					if (!this.state.originalFile) {
						arraySummaryPage[0] = document.createElement("div");
					}

					if (userSettings && arraySummaryPage[0]) {
						arraySummaryPage[0].style.fontFamily = fromBackendFontNameToCssFontFamily(userSettings.font_name?userSettings.font_name:"arial");
						arraySummaryPage[0].style.fontSize = `${userSettings.font_size}px`;
						arraySummaryPage[0].style.lineHeight = `${100 +(userSettings.line_height?userSettings.line_height:10)}%`;
					}
				}
				observer.observe(paragraph);
				pages.push(paragraph);
			}

			// console.log(currentIndex,documentElements.length)
			console.log(this.state.isSummaryOpen)
			if (currentIndex < documentElements.length) {
				processBatch.call(this, pages); // Recursively process the next batch
				// if(!isMobile)
			} else {
				this.setState({ docLoadedComplete: false });
				if (!isMobile && !this.state.isSummaryOpen && !this.state.isKeyWordsOpen)
					await getpages.call(this, pages, colorings, userSettings).then(() => {
						if (!isMobile && arraySummaryPage[0] && arraySummaryPage[0].lastChild) {
							textSection.innerHTML = "";
							textSection.appendChild(array[0]);
							this.setState({ pageCounter: 0 });
							console.log("[PRIMO SET LASTPARAGRAPHINDEX]", array[0].lastChild.id)
							this.setState({ lastParagraphIndex: array[0].lastChild.id });

						}

					});

				// set the numeber of pages
				if (!this.state.isSummaryOpen && !this.state.isKeyWordsOpen) {
					// //array.length = 0;
					// if (!this.state.originalFile) {
					// 	array[0] = document.createElement("div");
					// 	array[0].classList.add("fileViewerTextSelectable")
					// }

					// if (fontSettings && array[0]) {
					// 	array[0].style.fontFamily = fromBackendFontNameToCssFontFamily(fontSettings.font);
					// 	array[0].style.fontSize = `${fontSettings.fontSize}px`;
					// 	array[0].style.lineHeight = `${100 + fontSettings.lineLeading}%`;
					// }
					//console.log(("PAGES: ", this.state.numberOfPages);
					//console.log(("PAGES: ", array);
					if (!isMobile && array[0] && array[0].lastChild) {
						// textSection.innerHTML="";
						// console.log(textSection,array[0]);
						// textSection.appendChild(array[0]);
						// console.log(textSection)
					} else {
						textSection.classList.add("fileViewerTextSelectable");

						if (array[0] && array[0].lastChild) this.setState({ lastParagraphIndex: array[0].lastChild.id });
						if (userSettings) {
							textSection.style.fontFamily = fromBackendFontNameToCssFontFamily(userSettings.font_name?userSettings.font_name:"arial");
							textSection.style.fontSize = `${userSettings.font_size}px`;
							textSection.style.lineHeight = `${100 + (userSettings.line_height?userSettings.line_height:10)}%`;
						}
						for (let i = 0; i < pages.length; i++) {
							textSection.appendChild(pages[i]);
						}
					}
					setTimeout(() => {
						const container = document.getElementById("fileViewerTextDivContainer") as HTMLDivElement;

						// Create a new divSection (assuming it's already created and configured)
						textSection.classList.add("animated-element"); // Ensure this element has the animation class

						// Apply the animation by replacing children
						container?.replaceChildren(textSection);

						// Optional: Handle animation end event if needed for cleanup or further actions
						textSection.addEventListener("animationend", function () {
							// Actions after animation completes
							// console.log("Animation completed!");
						});
					}, 100);
				} else if (this.state.isSummaryOpen) {
					await getpages.call(this, pages, colorings, userSettings).then(() => {
						console.log("OPENED SUMMARY",arraySummaryPage[0].lastChild.id)

					if (!isMobile && arraySummaryPage[0] && arraySummaryPage[0].lastChild) {
						this.setState({ pageCounter: 0 });
						console.log("[PRIMO SET LASTPARAGRAPHINDEX SUMMARY]", arraySummaryPage[0].lastChild.id)
						this.setState({ lastParagraphIndex: arraySummaryPage[0].lastChild.id });
					}
					if (this.state.numberOfPages > arraySummaryPage.length) this.setState({ numberOfPages: arraySummaryPage.length });
					else this.setState({ numberOfPages: arraySummaryPage.length });
				})
					// if (!this.state.originalFile) {
					// 	arraySummaryPage[0] = document.createElement("div");
					// }

					// if (fontSettings && arraySummaryPage[0]) {
					// 	arraySummaryPage[0].style.fontFamily = fromBackendFontNameToCssFontFamily(fontSettings.font);
					// 	arraySummaryPage[0].style.fontSize = `${fontSettings.fontSize}px`;
					// 	arraySummaryPage[0].style.lineHeight = `${100 + fontSettings.lineLeading}%`;
					// }

					//console.log(("PAGES: ", this.state.numberOfPages);
					//console.log(("PAGES: ", array);
					if (!isMobile && arraySummaryPage[0] && arraySummaryPage[0].lastChild) {
						textSection.appendChild(arraySummaryPage[0]);

						if (this.state.numberOfPages > arraySummaryPage.length) this.setState({ numberOfPages: arraySummaryPage.length });
						else this.setState({ numberOfPages: arraySummaryPage.length });

						this.setState({ pageCounter: 0, lastParagraphIndex: arraySummaryPage[0].lastChild.id });
					} else {

						if (arraySummaryPage[0] && arraySummaryPage[0].lastChild) this.setState({ lastParagraphIndex: arraySummaryPage[0].lastChild.id });
						textSection.classList.add("fileViewerTextSelectable");
						if (userSettings) {
							textSection.style.fontFamily = fromBackendFontNameToCssFontFamily(userSettings.font_name?userSettings.font_name:"arial");
							textSection.style.fontSize = `${userSettings.font_size}px`;
							textSection.style.lineHeight = `${100 + (userSettings.line_height?userSettings.line_height:10)}%`;
						}
						for (let i = 0; i < pages.length; i++) {
							textSection.appendChild(pages[i]);
						}
						// if (this.state.isSummaryOpen && !this.state.originalFile) {
						// 	let Title = document.createElement("p");
						// 	Title.innerText = i18n.t("Riassunto");
						// 	Title.style.fontSize = "35px";
						// 	if (!summTitleInserted) {
						// 		textSection.insertBefore(Title, textSection.firstChild);
						// 		summTitleInserted = true;
						// 	}
						// }
					}
					setTimeout(() => {
						const container = document.getElementById("fileViewerTextDivContainer") as HTMLDivElement;

						// Create a new divSection (assuming it's already created and configured)
						textSection.classList.add("animated-element"); // Ensure this element has the animation class

						// Apply the animation by replacing children
						container?.replaceChildren(textSection);

						// Optional: Handle animation end event if needed for cleanup or further actions
						textSection.addEventListener("animationend", function () {
							// Actions after animation completes
							// console.log("Animation completed!");
						});
					}, 100);
				} else {
					setTimeout(() => {

						const container = document.getElementById("fileViewerTextDivContainer") as HTMLDivElement;

						// Create a new divSection (assuming it's already created and configured)
						divSection.classList.add("animated-element"); // Ensure this element has the animation class

						// Apply the animation by replacing children
						container?.replaceChildren(divSection);

						// Optional: Handle animation end event if needed for cleanup or further actions
						divSection.addEventListener("animationend", function () {
							// Actions after animation completes
							// console.log("Animation completed!");
						});
					}, 100);
				}
				if (document.getElementById("fileViewerTextDivContainer")) document.getElementById("fileViewerTextDivContainer")!.hidden = false;
			}
		});
	}

	processBatch.call(this, pages); // Start processing batches
}

function loadParagraph(this: any, paragraph: any) {
	// This function initializes paragraph interaction and dynamic behaviors when it comes into view.
	// Set contentEditable based on the application state dynamically when the paragraph becomes visible
	paragraph.contentEditable = this.state.fileEditingMode ? "true" : "false";

	// Attach event listeners for interaction-specific behaviors
	paragraph.addEventListener("keyup", (event: any) => this.handleParagraphChange(event, Number(paragraph.id)));
	paragraph.onclick = () => {
		if (!isMobile) {
			paragraph.onkeyup = (event: any) => {
				this.handleParagraphChange(event, Number(paragraph.id));
			};
		}

		// Additional logic for editing mode
		if (this.state.fileEditingMode) {
			this.setState({ processingComplete: true });
			paragraph.contentEditable = "true";

			if (!this.state.isKeyWordsOpen && !paragraph.classList.contains("selected")) {
				paragraph.style.backgroundColor = "";
				paragraph.classList.add("selected");
				this.addEditingButtons(paragraph);
			}
		}

		// Section selection mode logic
		else if (this.state.sectionSelectMode) {
			let checkBox = document.getElementById(`check-${paragraph.id}`);
			let tickIcon = document.createElement("ion-icon");
			tickIcon.icon = checkmark;
			tickIcon.className = "tickIcon";

			paragraph.contentEditable = "false";
			let selectedSections = this.state.selectedSections;

			if (selectedSections.includes(paragraph.innerText) && paragraph.style.backgroundColor) {
				paragraph.style.color = "unset";
				paragraph.style.backgroundColor = "";
				if (checkBox) {
					checkBox.style.backgroundColor = "";
					checkBox.style.width = "30px";
					checkBox.style.border = "1px solid var(--txt-color)";
					checkBox.style.borderRadius = "6px";
					if (checkBox.firstElementChild) {
						checkBox.removeChild(checkBox.firstElementChild);
					}
				}
				selectedSections.splice(selectedSections.indexOf(paragraph.innerText), 1);
			} else {
				if (!this.state.isDarkMode) paragraph.style.color = "#fff";
				if (checkBox) {
					paragraph.style.color = "var(--selection-txt-color)";
					tickIcon.color = "#fff";
					checkBox.style.backgroundColor = "var(--accents-color)";
					checkBox.style.border = "0px";
					checkBox.style.borderRadius = "6px 0px 0px 6px";
					checkBox.style.width = "40px";
					checkBox.appendChild(tickIcon);
				}
				selectedSections.push(paragraph.innerText);
				paragraph.style.backgroundColor = "var(--accents-color)";
				paragraph.style.color = "var(--selection-txt-color)";
			}

			this.setState({ selectedSections: selectedSections });
		} else if (isMobile) {
			let element;
			const index = Number(paragraph.id);
			if (this.state.documentElements && index >= 0 && index < this.state.documentElements.length && !this.state.isSummaryOpen) {
				element = this.state.documentElements[index];
				// Now you can safely use `element` here
			} else {
				console.log("documentElements is null or index is out of bounds");
				// Handle the error or fallback case here
				element = this.state.summaryDocumentElements[index];
			}
			this.setState({
				showClickPopover: true,
				clickedDocumentElement: element,
			});
		}
	};
}
function getpages(this: any, pages: any, colorings: any, userSettings: UserSettings|undefined): Promise<boolean> {
	return new Promise((resolve, reject) => {
		let charcounter = 0;
		let pagecounter = 0;
		const chosenArray = this.state.isSummaryOpen ? arraySummaryPage : array;

		chosenArray[pagecounter] = document.createElement("div");
		chosenArray[pagecounter].classList.add("fileViewerTextSelectable");

		// array[pagecounter].classList.add("fileViewerTextSelectable");
		if (userSettings && chosenArray[0]) {
			chosenArray[pagecounter].style.fontFamily = fromBackendFontNameToCssFontFamily(userSettings.font_name?userSettings.font_name:"arial");
			chosenArray[pagecounter].style.fontSize = `${userSettings.font_size}px`;
			chosenArray[pagecounter].style.lineHeight = `${100 + (userSettings.line_height?userSettings.line_height:10)}%`;
		}
		for (let i = 0; i < pages.length; i++) {
			//console.log(('caratteri paragrafo', pages[i].innerText.length);
			// if (pages[i].innerText.length + charcounter < 4000) {
				// pagecounter++;
				// charcounter = 0;

				//console.log((pages[i])

				chosenArray[pagecounter].appendChild(pages[i]);
				charcounter = charcounter + pages[i].innerText.length;
				//console.log((charcounter)
			// } else {
			// 	pagecounter++;
			// 	charcounter = 0;
			// 	chosenArray[pagecounter] = document.createElement("div");
			// 	chosenArray[pagecounter].classList.add("fileViewerTextSelectable");
			// 	if (userSettings) {
			// 		chosenArray[pagecounter].style.fontFamily = fromBackendFontNameToCssFontFamily(userSettings.font_name?userSettings.font_name:"arial");
			// 		chosenArray[pagecounter].style.fontSize = `${userSettings.font_size}px`;
			// 		chosenArray[pagecounter].style.lineHeight = `${100 + (userSettings.line_height?userSettings.line_height:10)}%`;
			// 	}
			// 	chosenArray[pagecounter].appendChild(pages[i]);
			// }
		}
		resolve(true)
		this.setState({ originalFile: true });
	})
}

export function viewFile(this: any) {
	const fileId = this.props.match.params.fileId;
	const documentDataQuery = query(collection(firestore, `f/${fileId}/d`), where("d", "==", fileId));
	const unsubscribe = onSnapshot(documentDataQuery, (querySnapshot) => {
		const documentElements = filesServices.fromDocumentDataToDocumentElements(querySnapshot);
		this.setState({ documentElements }, () => {
			const firstElement = this.state.documentElements[0] == undefined ? this.state.summaryDocumentElements[0] : this.state.documentElements[0];
			// const filteredAudios = this.state.generatedAudios.filter(
			// 	(el: any) =>
			// 		el.text === firstElement.text &&
			// 		el.settings.prosodyRate === this.state.generatedAudios[0].settings.prosodyRate &&
			// 		el.settings.voiceType === this.state.generatedAudios[0].settings.voiceType
			// );
			// if (filteredAudios.length !== 0) {
			// 	const generatedAudio = filteredAudios[0];
			// 	this.setState(
			// 		{
			// 			isLoading: false,
			// 			isLoadingTTS: false,
			// 			ttsParagraphPointer: 0,
			// 			loadingMessage: "",
			// 			speechMarks: generatedAudio.speechMarks,
			// 			audioToPlay: generatedAudio.audio,
			// 		},
			// 		() => {
			// 			// this.changeExistingSpans();
			// 			this.getKaraoke(documentElements);
			// 		}
			// 	);
			// 	processText.call(this, documentElements, this.state.highlightings, this.state.fontSettings);

			// 	return;
			// }

			if (documentElements && documentElements.length > 0 && this.state.userUuid) {
				if (!this.state.isKeyWordsOpen) {
					// this.setState({
					// 	isLoadingTTS: true,
					// 	isLoading: true,
					// 	loadingMessage:
					// 		i18n.language === "en"
					// 			? "Preparing speech synthesis"
					// 			: i18n.language === "es"
					// 			? "Preparar la síntesis de voz"
					// 			: i18n.language === "fr"
					// 			? "Préparation de la synthèse vocale"
					// 			: i18n.language === "de"
					// 			? "Vorbereitung der Sprachsynthese"
					// 			: i18n.language === "ca"
					// 			? "Preparo la síntesi de veu"
					// 			: "Preparo la sintesi vocale",
					// });
				}
				// ttsServices.getTTSSettings(this.state.userUuid).then((ttsSettings) => {
				// 	getTTS(documentElements, documentElements[0].text, ttsSettings)
				// 		.then((audio) => {
				// 			const tempAudios = this.state.generatedAudios;
				// 			tempAudios.push({
				// 				id: documentElements[0].index,
				// 				text: documentElements[0].text,
				// 				audio: audio.audio,
				// 				speechMarks: audio.speech_marks,
				// 				settings: ttsSettings,
				// 			});
				// 			this.setState(
				// 				{
				// 					audioToPlay: audio.audio as string,
				// 					ttsParagraphPointer: 0,
				// 					speechMarks: audio.speech_marks,
				// 					generatedAudios: tempAudios,
				// 					isLoadingTTS: false,
				// 					isLoading: false,
				// 					loadingMessage: "",
				// 				},
				// 				() => {
				// 					this.changeExistingSpans();
				// 					this.getKaraoke(documentElements);
				// 				}
				// 			);
				// 		})
				// 		.catch((err) => {
				// 			console.error("[FileViewer] error getting tts:", err);
				// 		});
				// });
				processText.call(this, documentElements, this.state.colorings, this.state.userSettings);
			}
			// settingsServices.getUserTutorialChoice(this.state.userUuid, "tFV").then((response) => {
			// 	if (response && isMobile) {
			// 		this.setState({ showModalSelection: true, tutorialMobile: response });
			// 	} else {
			// 		this.setState({ tutorial: response });
			// 	}
			// });
		});
		unsubscribe();
	});
	this.setState({ unsubscribeOnFilesChanges: unsubscribe });
}

export function viewSummary(this: any) {
	// const documentDataQuerySummary = query(collection(firestore, `s/${this.props.match.params.fileId}/s`), where("d", "==", this.props.match.params.fileId));
	// const unsubscribe = onSnapshot(documentDataQuerySummary, (querySnapshot) => {
	// 	this.setState(
	// 		{
	// 			summaryDocumentElements: filesServices.fromDocumentDataToDocumentElements(querySnapshot),
	// 		},
	// 		() => {
	// 			if (!this.state.isKeyWordsOpen) {
	// 				if (this.state.summaryDocumentElements && this.state.summaryDocumentElements.length > 0 && this.state.userUuid) {
	// 					if (this.state.userUuid) {
	// 						if (this.state.summaryDocumentElements && this.state.firstTitle) {
	// 							const fullText = this.state.summaryDocumentElements[0].text;
	// 							const words = fullText.trim().split(/\s+/); // This uses a regular expression to split on one or more spaces
	// 							const firstThreeWords = words.slice(0, 3).join(" ");
	// 							let fileRef = doc(firestore, `s/${this.props.match.params.fileId}/`);
	// 							updateDoc(fileRef, {
	// 								t: firstThreeWords,
	// 							});
	// 						}
	// 						// let loadingMessage =
	// 						// 	i18n.language === "it"
	// 						// 		? "Sto caricando il riassunto..."
	// 						// 		: i18n.language === "en"
	// 						// 		? "I'm loading the summary..."
	// 						// 		: i18n.language === "es"
	// 						// 		? "Estoy cargando el resumen..."
	// 						// 		: i18n.language === "de"
	// 						// 		? "Ich lade die Zusammenfassung..."
	// 						// 		: i18n.language === "ca"
	// 						// 		? "Estic carregant el resum..."
	// 						// 		: "Je charge le résumé...";

	// 						// this.setState({ isLoadingTTS: true, isLoading: true, loadingMessage: loadingMessage });
	// 						// ttsServices.getTTSSettings(this.state.userUuid).then((ttsSettings) => {
	// 						// 	getTTS(this.state.summaryDocumentElements!, this.state.summaryDocumentElements![0].text, ttsSettings)
	// 						// 		.then((audio) => {
	// 						// 			let tempAudios = this.state.generatedAudios;
	// 						// 			tempAudios.push({
	// 						// 				id: this.state.summaryDocumentElements![0].index,
	// 						// 				text: this.state.summaryDocumentElements![0].text,
	// 						// 				audio: audio.audio,
	// 						// 				speechMarks: audio.speech_marks,
	// 						// 				settings: ttsSettings,
	// 						// 			});
	// 						// 			this.setState(
	// 						// 				{
	// 						// 					audioToPlay: audio.audio as string,
	// 						// 					speechMarks: audio.speech_marks,
	// 						// 					generatedAudios: tempAudios,
	// 						// 					isLoadingTTS: false,
	// 						// 					ttsParagraphPointer: 0,
	// 						// 					isLoading: false,
	// 						// 					loadingMessage: "",
	// 						// 				},
	// 						// 				() => {
	// 						// 					this.changeExistingSpans();
	// 						// 					this.getKaraoke(this.state.summaryDocumentElements);
	// 						// 					// this.setState({ lastParagraphIndex: (filesServices.fromDocumentDataToDocumentElements(querySnapshot).length - 1).toString(), })
	// 						// 				}
	// 						// 			);

	// 						// 			// this.changeExistingSpans()
	// 						// 		})
	// 						// 		.catch((err) => {
	// 						// 			console.error("[FileViewer] error getting tts:", err);
	// 						// 		});
	// 						// });
	// 					}
	// 				}
	// 			} else {
	// 				let keywords = filesServices.fromDocumentDataToDocumentElements(querySnapshot)
	// 				const fullText = keywords[0].keyWord;
	// 				if (fullText != null && this.state.firstTitle) {
	// 					const words = fullText.split(","); // This splits the string into an array of words
	// 					const firstWord = words.slice(0, 1).join(" ");
	// 					let fileRef = doc(firestore, `s/${this.props.match.params.fileId}/`);
	// 					updateDoc(fileRef, {
	// 						t: firstWord,
	// 					});
	// 				}
	// 			}
	// 			processText.call(this, filesServices.fromDocumentDataToDocumentElements(querySnapshot), this.state.colorings, this.state.userSettings);
	// 		}
	// 	);
	// 	unsubscribe();
	// });
	// this.setState({ unsubscribeOnFilesChanges: unsubscribe });
	let newParagraphs: string[] = [];
	let keywordsList: string=""
	if(this.state.summary.content && !this.state.file_text && isJson(this.state.summary.content)){

		let jsonCorrected=replaceContentFields(this.state.summary.content);
		console.log(jsonCorrected)
		let finalJson= JSON.parse(jsonCorrected)

		finalJson.pages.forEach((page:Page) => {
			page.paragraphs.forEach((paragraph:paragraphNewStructure) => {
				paragraph.blocks.forEach(block => {
					newParagraphs.push(block.content)         
				});
			});
    		});
		
	}else if(!this.state.summary.keywords){

	let page: string = this.state.file_text || !isJson(this.state.summary.content) ? this.state.summary.content.toString() : this.state.summary.toString();
	page = page.replace(/\n/g, " ");
	let paragraphs: string[] = page.split(".");
	let tempString: string = "";
	let tempCounter: number = 0;

	for (let j = 0; j < paragraphs.length; j++) {
		tempString += paragraphs[j] + ". ";
		tempCounter++;
		if (tempCounter === 3) {
			tempCounter = 0;
			newParagraphs.push(tempString.trim());
			tempString = "";
		}
	}

	if (tempCounter !== 0) {
		newParagraphs.push(tempString.trim());
	}
	
}else{
	console.log("ADADA")
	keywordsList = this.state.summary.keywords
	.map((item: any) => item.keyword.replace(/\n/g, ', '))
	.join(', ')
	.split(', ')
	.filter(Boolean)  // To remove any empty strings that may result from extra commas or newlines
	.join(', ');	
  	newParagraphs.push("placeholder")
	console.log(keywordsList)
}

const documentElements: DocumentElement[] = newParagraphs.map((paragraph, index) => ({
	uuid: this.state.userUuid,
	documentId: this.state.resourceId,
	index: index,
	text: paragraph,
	keyWord:keywordsList,
}));
this.setState({summaryDocumentElements:documentElements, isKeyWordsOpen:keywordsList!=""? true:false,keywordsState:keywordsList!=""? true:false ,isSummaryOpen:keywordsList!=""? false:true},()=>{
	processText.call(this, documentElements, this.state.colorings, this.state.userSettings);

})
}

function isJson(value:any) {
	try {
	  // Attempt to parse the value as JSON
	  JSON.parse(value);
	  return true; // If no error is thrown, the value is valid JSON
	} catch (error) {
	  return false; // If an error is thrown, the value is not valid JSON
	}
  }
function replaceContentFields(jsonString:any) {
	// Use a regular expression to match the content fields and replace characters
	return jsonString.replace(/"content":\s*"([^"]*)"/g, (match:any, p1:any) => {
	  // Replace unescaped special characters within the content field
	  const escapedContent = p1
		.replace(/"/g, '\\"')    // Escape double quotes
		.replace(/\n/g, '\\n')   // Escape newlines
		.replace(/\r/g, '\\r')   // Escape carriage returns
		.replace(/\t/g, '\\t');  // Escape tabs
  
	  // Return the modified content field
	  return `"content": "${escapedContent}"`;
	});
  }

function renderHtml(jsonData:string): void {
    const contentArea = document.getElementById("fileViewerTextDivContainer") as HTMLDivElement;
    let jsonString = jsonData.replace(/'/g, '"');

    // Convert True/False to true/false
    jsonString = jsonString.replace(/True/g, 'true').replace(/False/g, 'false');

    // Parse the string to JSON object
    let finalJson= JSON.parse(jsonString)
	if (!contentArea) {
        console.error('Content area not found');
        return;
    }
    contentArea.innerHTML = ''; // Clear existing content

    finalJson.pages.forEach((page:Page) => {
        const pageDiv = document.createElement('div');
        pageDiv.id = page.id;
        pageDiv.className = 'fileViewerTextSelectable';

        page.paragraphs.forEach((paragraph:paragraphNewStructure) => {
            const paragraphDiv = document.createElement('div');
            paragraphDiv.id = paragraph.id;
            paragraphDiv.className = 'paragraph';

            paragraph.blocks.forEach((block:Block) => {
                const blockElement = document.createElement(
                    block.type === 'header' ? 'h1' :
                    block.type === 'sub_header' ? 'h2' :
                    block.type === 'bp' ? 'li' : // Bullet points
                    'p'
                );
                blockElement.id = block.id;
                blockElement.className = block.type === 'header' ? 'header editable' :
                                         block.type === 'sub_header' ? 'sub_header editable' :
                                         block.type === 'bp' ? 'bullet editable' :
                                         'text editable';
                blockElement.contentEditable = 'true';
                blockElement.innerHTML = block.content;

                // Apply styles
                if (block.style.bold) {
                    blockElement.classList.add('bold');
                }
                if (block.style.italic) {
                    blockElement.classList.add('italic');
                }

                paragraphDiv.appendChild(blockElement);
            });

            pageDiv.appendChild(paragraphDiv);
        });
		contentArea.classList.add("animated-element"); // Ensure this element has the animation class

						// Apply the animation by replacing children
						contentArea?.replaceChildren(pageDiv);

						// Optional: Handle animation end event if needed for cleanup or further actions
						contentArea.addEventListener("animationend", function () {
							// Actions after animation completes
							// console.log("Animation completed!");
						});
console.log(contentArea)
if (document.getElementById("fileViewerTextDivContainer")) document.getElementById("fileViewerTextDivContainer")!.hidden = false;

        contentArea.replaceChildren(pageDiv);
    });
}

export async function createConceptualMaps(this: any) {
	this.setState({ mapButtonDisabled: true });
	let documentParagraphs = document.getElementsByClassName("fileViewerParagraph");
	let checkBoxes = document.querySelectorAll('[id^="check-"]');
	let text = "";
	checkBoxes.forEach((el) => el.remove());
	// let bearerToken
	if (this.state.sectionSelectModeMap) {
		this.state.selectedSections.forEach((section: any) => {
			text += " " + section;
		});
	} else {
		this.state.documentElements?.forEach((docEl: any) => {
			text = text + " " + docEl.text;
		});
	}
	// if (text.length > 2950) {
	// 	i18n.language === "it"
	// 		? alert("Il testo selezionato è troppo lungo")
	// 		: i18n.language === "en"
	// 		? alert("The selected text is too long")
	// 		: i18n.language === "es"
	// 		? alert("El texto seleccionado es demasiado largo")
	// 		: i18n.language === "fr"
	// 		? alert("Le texte sélectionné est trop long")
	// 		: i18n.language === "ca"
	// 		? alert("El text seleccionat és massa llarg")
	// 		: alert("Der ausgewählte Text ist zu lang");

	// 	for (let i = 0; i < documentParagraphs.length; i++) {
	// 		let el = documentParagraphs[i] as HTMLElement;
	// 		el.style.backgroundColor = "";
	// 	}
	// 	this.setState({ sectionSelectMode: false, sectionSelectModeMap: false, selectedSections: [], mapButtonDisabled: false });
	// 	return;
	// }
	for (let i = 0; i < documentParagraphs.length; i++) {
		let el = documentParagraphs[i] as HTMLElement;
		el.style.backgroundColor = "";
		el.style.marginLeft = "unset";
		el.style.color = "var(--txt-color)";
		if (!this.state.isDarkMode) el.style.color = "unset";
	}
	auth.currentUser
		?.getIdToken()
		.then((token: any) => {
			this.setState({ selectedSections: [], sectionSelectMode: false, sectionSelectModeMap: false, showMapCreationStartToast: true });
			this.props.disableMapButton(this.state.document!.uuid);
			this.setState({ mapIcon: timeOutline });
			filesServices.getConceptualMap(text, token, this.state.document!.uuid, this.state.userUuid).then((response) => {
				this.setState({ mapButtonDisabled: false });
				if (response == "success") {
					this.setState({ mapIcon: MapsButtonLogo });
					//console.log(("SET DOC COMPLETED")
					// setTimeout(async () => {
					getDoc(doc(firestore, `m/${this.state.document?.uuid}/`)).then((documentSnapshot) => {
						//console.log(("GET DOC COMPLETED")
						this.props.enableMapButton(this.state.document!.uuid);
						this.setState({ showMapCreationStartToast: false });
						generateConfirmationToast.call(this, false, "");
					});
				} else {
					this.setState({ mapIcon: MapsButtonLogo });
				}
			});
		})
		.catch((e) => {
			this.props.disableMapButton(this.state.document!.uuid);
			//console.log((("Error generating map: ",e);
		});
}

export async function addNewParagraph(this: any, paragraph: any) {
	let previousEl: any;
	let previousIndex: any;
	let tempElements = this.state.isSummaryOpen ? this.state.summaryDocumentElements : this.state.documentElements;
	console.log("TEMP ELEMENTS: ", tempElements);
	console.log("SUMMARY ELEMENTS: ", this.state.summaryDocumentElements);

	if (paragraph) {
		previousEl = tempElements!.filter((el: any) => el.index === Number(paragraph.id))[0];
		previousIndex = previousEl.index;
	} else {
		previousIndex = -1;
	}

	if (tempElements?.indexOf(previousEl!) === tempElements!.length - 1) {
		//LAST INDEX, JUST ADD A NEW PARAGRAPH
	} else if (tempElements?.indexOf(previousEl!) === 0 || paragraph === null) {
		//FIRST INDEX, SHIFT ALL PARAGRAPH INDEXES BY +1
		tempElements?.forEach((el: any) => {
			if (el.index > previousIndex) {
				el.index++;
			}
		});
		this.setState({ ttsParagraphPointer: this.state.ttsParagraphPointer! + 1 });
	} else {
		//INDEX IN THE MIDDLE IS REMOVED, SHIFT ALL NEXT PARAGRAPH INDEXES BY +1
		tempElements?.forEach((el: any) => {
			if (el.index > previousIndex) {
				el.index++;
			}
		});
		this.setState({ ttsParagraphPointer: this.state.ttsParagraphPointer! + 1 });
	}
	tempElements!.push({
		documentId: "test",
		index: previousIndex + 1,
		text:
			i18n.language === "en"
				? "New paragraph"
				: i18n.language === "es"
					? "Nuevo párrafo"
					: i18n.language === "fr"
						? "Nouveau paragraphe"
						: i18n.language === "it"
							? "Nuovo paragrafo"
							: i18n.language === "ca"
								? "Nou paràgraf"
								: "neuer Absatz",
		uuid: "random_uuid",
		keyWord: "",
	});
	let collectionRef = this.state.isSummaryOpen ? collection(firestore, `s/${this.state.document!.uuid}/s/`) : collection(firestore, `f/${this.state.document!.uuid}/d/`);

	let newDBParagraph = await addDoc(collectionRef, {
		d: this.state.document!.uuid,
		i: previousIndex + 1,
		p:
			i18n.language === "en"
				? "New paragraph"
				: i18n.language === "es"
					? "Nuevo párrafo"
					: i18n.language === "fr"
						? "Nouveau paragraphe"
						: i18n.language === "it"
							? "Nuovo paragrafo"
							: i18n.language === "ca"
								? "Nou paràgraf"
								: "neuer Absatz",
	});
	tempElements[tempElements.indexOf(tempElements.filter((el: any) => el.uuid === 'random_uuid')[0])].uuid = newDBParagraph.id
	tempElements!.forEach((el: any) => {
		console.log("SINGLE EL: ", el);
		if (el.index > previousIndex) {
			let docRef = this.state.isSummaryOpen ? doc(firestore, `s/${this.state.document!.uuid}/s/${el.uuid}`) : doc(firestore, `f/${this.state.document!.uuid}/d/${el.uuid}`);
			if (el.index === previousIndex + 1) {
				el.documentId = this.state.document!.uuid;
				el.text =
					i18n.language === "en"
						? "New paragraph"
						: i18n.language === "es"
							? "Nuevo párrafo"
							: i18n.language === "fr"
								? "Nouveau paragraphe"
								: i18n.language === "it"
									? "Nuovo paragrafo"
									: i18n.language === "ca"
										? "Nou paràgraf"
										: "neuer Absatz";
				el.uuid = newDBParagraph.id;
				//we already set the index on the db when adding the element
				return;
			}
			updateDoc(docRef, {
				i: el.index,
			});
		}
	});
	//console.log((("UPDATED ELEMENTS ", tempElements);
	tempElements!.sort((a: any, b: any) => a.index - b.index);
	//console.log((tempElements);

	this.setState(this.state.isSummaryOpen ? { summaryDocumentElements: tempElements } : { documentElements: tempElements }, () => {
		let previousParagraph;
		let newParagraph: any;
		if (paragraph) {
			previousParagraph = document.getElementById(paragraph.id)!.parentElement;
			newParagraph = document.getElementById(paragraph.id)!.cloneNode(true) as HTMLParagraphElement;
		} else {
			newParagraph = document.getElementById("0")!.cloneNode(true) as HTMLParagraphElement;
			document.getElementById("0")?.parentElement?.insertAdjacentElement("beforebegin", newParagraph);
		}
		newParagraph.innerText =
			i18n.language === "en"
				? "New paragraph"
				: i18n.language === "es"
					? "Nuevo párrafo"
					: i18n.language === "fr"
						? "Nouveau paragraphe"
						: i18n.language === "it"
							? "Nuovo paragrafo"
							: i18n.language === "ca"
								? "Nou paràgraf"
								: "neuer Absatz";

		previousParagraph?.insertAdjacentElement("afterend", newParagraph);
		newParagraph.id = (previousIndex + 1).toString();
		// newParagraph.addEventListener('focusout', () => this.handleParagraphFocusOut(newParagraph, tempElements!, Number(newParagraph.id)));
		newParagraph.addEventListener("keyup", (event: any) => this.handleParagraphChange(event, Number(newParagraph.id)));
		newParagraph.onclick = () => {
			// if (!isMobile)
			//     newParagraph.onkeyup = (event: any) => {
			//         this.handleParagraphChange(event, Number(newParagraph.id))
			//     }

			if (this.state.fileEditingMode) {
				// paragraph.contentEditable = 'true';
			} else if (this.state.sectionSelectMode) {
				newParagraph.contentEditable = "false";
				let selectedSections = this.state.selectedSections;
				//console.log(((selectedSections);
				let checkBox = document.getElementById(`check-${newParagraph.id}`);
				let tickIcon = document.createElement("ion-icon");
				tickIcon.icon = checkmark;
				tickIcon.className = "tickIcon";
				if (selectedSections.includes(newParagraph.innerText) && newParagraph.style.backgroundColor) {
					newParagraph.style.color = "unset";
					newParagraph.style.backgroundColor = "";
					if (checkBox) {
						checkBox.style.backgroundColor = "";
						checkBox.style.width = "30px"; //this will recreate the margin between checkbox and paragraph
						checkBox.style.border = "1px solid var(--txt-color)";
						checkBox.style.borderRadius = "6px";
						checkBox.removeChild(checkBox.firstElementChild!);
					}
					selectedSections.splice(selectedSections.indexOf(newParagraph.innerText), 1);
				} else {
					if (!this.state.isDarkMode) newParagraph.style.color = "#fff";
					if (selectedSections.includes(newParagraph.innerText)) {
						this.setState({ showMapTextErrorAlert: true });
						return;
					}
					if (
						(selectedSections.length === 15 && this.state.sectionSelectModeMap === true) ||
						(selectedSections.length === 15 && this.state.sectionSelectModeSummary === true)
					) {
						return;
					}
					if (checkBox) {
						if (!this.state.isDarkMode) {
							//change paragraph text to white
							//change tick icon to white
							newParagraph.style.color = "#fff";
							tickIcon.color = "light";
						}
						checkBox.style.backgroundColor = "#3626A7";
						checkBox.style.border = "0px";
						newParagraph.style.borderRadius = "0px 6px 6px 6px";
						checkBox.style.borderRadius = "6px 0px 0px 6px";
						checkBox.style.width = "40px"; //this will fill the margin between checkbox and paragraph
						checkBox.appendChild(tickIcon);
					}
					selectedSections.push(newParagraph.innerText);
					newParagraph.style.backgroundColor = "#3626A7";
				}
				this.setState({ selectedSections: selectedSections });
			} else {
				if (isMobile)
					this.setState({
						showClickPopover: true,
						clickedDocumentElement: tempElements![Number(newParagraph.id)],
					});
			}
		};

		this.addEditingButtons(newParagraph);
		let paragraphArr = document.getElementsByClassName("fileViewerParagraph");
		//console.log((paragraphArr);

		for (let i = 0; i < paragraphArr.length; i++) {
			const paragraph = paragraphArr[i] as HTMLElement;
			paragraph.id = i.toString();
		}
	});
}

export function handleTTSOptionChange(this: any, option: any, value: any) {
	this.setState({ disablePlayButton: true, disableTTSOptions: true, disableTTSSkipButtons: true });

	if (this.state.elementsTTS && this.state.elementsTTS.length > 0 && this.state.userUuid) {
		if (option === "Rate") {
			settingsServices.setTtsSpeed(this.state.userUuid, value,this.state.bearerToken).then(()=>{
				returnTTS.call(this)
			}).catch((err) => {
				console.error(`[Settings] Error changing ${option} - ${value}`, err);
			});
		} else if (option === "Voice") {
			settingsServices.setVoiceType(this.state.userUuid, value,this.state.bearerToken).then(()=>{
				returnTTS.call(this)
			}).catch((err) => {
				console.error(`[Settings] Error changing ${option} - ${value}`, err);
			});
		}

	} else {
		this.setState({ disablePlayButton: false, disableTTSOptions: false, disableTTSSkipButtons: false });
	}
}
function returnTTS(this:any){
	settingsServices.getUserSettings(this.state.userUuid,this.state.bearerToken)
	.then(async (ttsSettings) => {
		if (
			this.state.elementsTTS !== null &&
			this.state.ttsParagraphPointer !== null &&
			this.state.ttsParagraphPointer >= Number(this.state.startParagraphIndex)
		) {
			if (
				this.state.generatedAudios.filter(
					(el: any) =>
						el.text === this.state.elementsTTS![this.state.ttsParagraphPointer!].text &&
						el.settings.prosodyRate === ttsSettings.speech_rate &&
						el.settings.voiceType === ttsSettings.speech_gender
				).length > 0
			) {
				// AUDIO WAS ALREADY GENERATED
				let audioToPlay = this.state.generatedAudios.find(
					(el: any) =>
						el.text === this.state.elementsTTS![this.state.ttsParagraphPointer!].text &&
						el.settings.prosodyRate === ttsSettings.speech_rate &&
						el.settings.voiceType === ttsSettings.speech_gender
				);
				this.setState({
					audioToPlay: audioToPlay!.audio,
					speechMarks: audioToPlay!.speechMarks,
					disableTTSSkipButtons: false,
					disablePlayButton: false,
					disableTTSOptions: false,
				});
				this.getKaraoke(this.state.documentElements!);
			} else {
				await getTTS(this.state.elementsTTS, this.state.elementsTTS[this.state.ttsParagraphPointer].text, ttsSettings)
					.then((audio) => {
						// console.log(this.state.documentElements,this.state.elementsTTS![this.state.ttsParagraphPointer!].text)

						let tempAudios = this.state.generatedAudios;
						if (this.state.documentElements == null)
							tempAudios.push({
								id: this.state.documentElements == null ? this.state.summaryDocumentElements![0].index : this.state.documentElements![0].index,
								text: this.state.elementsTTS![this.state.ttsParagraphPointer!].text,
								audio: audio.audio,
								speechMarks: audio.speech_marks,
								settings: ttsSettings,
							});
						this.setState({
							audioToPlay: audio.audio,
							speechMarks: audio.speech_marks,
							disablePlayButton: false,
							disableTTSOptions: false,
							disableTTSSkipButtons: false,
							generatedAudios: tempAudios,
						});
						this.getKaraoke(this.state.documentElements!);
					})
					.catch((err) => {
						console.error("[FileViewer] error getting tts:", err.detail);
					});
			}
		}
	})
	.catch((err) => {
		console.error(`[Settings] Error getting TTS settings:`, err);
	});
}

export function handleTTSButtonPress(this: any, direction: "forward" | "backward") {
	if (this.state.elementsTTS && this.state.elementsTTS.length > 0) {
		this.setState({ disableTTSSkipButtons: true, disablePlayButton: true, disableTTSOptions: true, isLoadingTTS: true });
		let currentPointer = this.state.ttsParagraphPointer || 0;

		const newPointer = direction === "backward" ? Number(currentPointer) - 1 : Number(currentPointer) + 1;
		if (newPointer >= 0 && newPointer < this.state.elementsTTS.length) {
			this.setState({ ttsParagraphPointer: newPointer }, () => {
				if (this.state.userUuid) {
					console.log(this.state.elementsTTS)
					settingsServices.getUserSettings(this.state.userUuid,this.state.bearerToken).then((ttsSettings) => {
						if (this.state.elementsTTS && this.state.ttsParagraphPointer !== null) {
							// Additional logic for setting class and calculating top
							if (!isMobile) {
								if (this.state.ttsParagraphPointer <= Number(this.state.lastParagraphIndex)) {
									// (document.getElementById(String(this.state.ttsParagraphPointer)) as HTMLElement).classList.add('paragraphinReading');
									calcolaToptts.call(this)
									// if (this.state.ttsParagraphPointer > Number(this.state.startParagraphIndex))
									// (document.getElementById(String(this.state.ttsParagraphPointer - 1)) as HTMLElement).classList.remove('paragraphinReading');
								} else {
									if (direction === "backward") {
										if (this.state.pageCounter > 1)
											this.PageMinus();
										this.setState({ ttsParagraphPointer: Number(this.state.lastParagraphIndex) });
										calcolaToptts.call(this)
									}

									// (document.getElementById(String(this.state.ttsParagraphPointer)) as HTMLElement).classList.add('paragraphinReading');
									calcolaToptts.call(this)
								}
							} 
							else if (isMobile) {
								if(this.state.ttsParagraphPointer === Number(this.state.lastParagraphIndex)) this.setState({ lastParagraphofPages: true })			
								calcolaToptts.call(this)
							}

							let elementsToSynthesize = this.state.isSummaryOpen ? this.state.summaryDocumentElements! : this.state.documentElements!;

							if (
								this.state.generatedAudios.filter(
									(el: any) =>
										el.text === this.state.elementsTTS![this.state.ttsParagraphPointer!].text &&
										el.settings.prosodyRate === ttsSettings.speech_rate &&
										el.settings.voiceType === ttsSettings.speech_gender
								).length > 0
							) {
								// Existing audio
								let audioToPlay = this.state.generatedAudios.find(
									(el: any) =>
										el.text === this.state.elementsTTS![this.state.ttsParagraphPointer!].text &&
										el.settings.prosodyRate === ttsSettings.speech_rate &&
										el.settings.voiceType === ttsSettings.speech_gender
								);
								this.setState(
									{
										audioToPlay: audioToPlay!.audio,
										speechMarks: audioToPlay!.speechMarks,
										disableTTSSkipButtons: false,
										disablePlayButton: false,
										disableTTSOptions: false,
										isLoadingTTS: false
									},
									() => {
										this.getKaraoke(elementsToSynthesize);
									}
								);
							} else {
								// Generate new audio
								getTTS(this.state.elementsTTS, this.state.elementsTTS[this.state.ttsParagraphPointer].text, ttsSettings)
									.then((response) => {
										let tempAudios = this.state.generatedAudios;
										tempAudios.push({
											id: elementsToSynthesize[this.state.ttsParagraphPointer!].index,
											text: elementsToSynthesize[this.state.ttsParagraphPointer!].text,
											audio: response.audio,
											speechMarks: response.speech_marks,
											settings: ttsSettings,
										});
										this.setState(
											{
												audioToPlay: response.audio,
												speechMarks: response.speech_marks,
												generatedAudios: tempAudios,
												disablePlayButton: false,
												disableTTSOptions: false,
												disableTTSSkipButtons: false,
												isLoadingTTS: false
											},
											() => {
												this.changeExistingSpans();
												this.getKaraoke(elementsToSynthesize);
											}
										);
									})
									.catch((err) => {
										console.error("[FileViewer] error getting tts:", err);
									});
							}
						}
					});
				}
			});
		}
	} else {
		this.setState({
			ttsParagraphPointer: null,
		});
	}
}

export function handleTTSLogic(this: any, ttsSettings: UserSettings) {
	if (
		this.state.generatedAudios.filter(
			(el: any) =>
				el.text === this.state.elementsTTS![this.state.ttsParagraphPointer!].text &&
				el.settings.prosodyRate === ttsSettings.speech_rate &&
				el.settings.voiceType === ttsSettings.speech_gender
		).length > 0
	) {
		// AUDIO WAS ALREADY GENERATED
		let audioToPlay = this.state.generatedAudios.find(
			(el: any) =>
				el.text === this.state.elementsTTS![this.state.ttsParagraphPointer!].text &&
				el.settings.prosodyRate === ttsSettings.speech_rate &&
				el.settings.voiceType === ttsSettings.speech_gender
		);
		this.setState(
			{
				audioToPlay: audioToPlay!.audio,
				speechMarks: audioToPlay!.speechMarks,
				disableTTSSkipButtons: false,
				disablePlayButton: false,
				disableTTSOptions: false,
			},
			() => {
				this.getKaraoke(this.state.documentElements!);
			}
		);
	} else {
		getTTS(this.state.elementsTTS!, this.state.elementsTTS![this.state.ttsParagraphPointer!].text, ttsSettings)
			.then((audio) => {
				let tempAudios = this.state.generatedAudios;
				tempAudios.push({
					id: this.state.documentElements == null ? this.state.summaryDocumentElements![0].index : this.state.documentElements![0].index,
					text: this.state.elementsTTS![this.state.ttsParagraphPointer!].text,
					audio: audio.audio,
					speechMarks: audio.speech_marks,
					settings: ttsSettings,
				});
				this.setState(
					{
						audioToPlay: audio.audio,
						speechMarks: audio.speech_marks,
						disableTTSSkipButtons: false,
						disablePlayButton: false,
						disableTTSOptions: false,
						generatedAudios: tempAudios,
					},
					() => {
						this.changeExistingSpans();
						this.getKaraoke(this.state.documentElements!);
					}
				);
			})
			.catch((err) => {
				console.error("[FileViewer] error getting tts:", err);
			});
	}
}
export function generaRiassunto(this: any) {
	this.setState({ sectionSelectModeSummary: false, sectionSelectMode: false, infoMsg: true });
	let documentParagraphs = document.getElementsByClassName("fileViewerParagraph");
	let totalSize = 0;
	let text = "";
	this.handleChkBoxCreation();
	for (let i = 0; i < documentParagraphs.length; i++) {
		const divSize = ((documentParagraphs[i] as HTMLParagraphElement).innerText.length * 2) / 1024; // calculate size in kb
		totalSize += divSize;
	}
	if (this.state.sectionSelectMode) {
		this.state.selectedSections.forEach((section: any) => {
			text += " " + section;
		});
	}
	//console.log((`Total size: ${totalSize.toFixed(2)} kb`);
	auth.currentUser?.getIdToken().then(async (token) => {
		let bearerToken = token;
		if (this.state.choice) {
			this.setState({ isGeneratingKeywords: true });
		} else this.setState({ isGeneratingSummary: true });
		filesServices
			.getSummary(text, bearerToken, this.state.typeSumOrKey, this.state.document!.uuid, this.state.userUuid)
			.then(async (response) => {
				//console.log((("aaaaaa", response)
				if (response) {
					if (this.state.choice)
						this.setState({ isGeneratingKeywords: false });
					else
						this.setState({ isGeneratingSummary: false });
					areSummaryOrKeywords.call(this);
					sendEvent({
						"user_id": this.state.userUuid,
						"event_type": this.state.typeSumOrKey ? this.state.typeSumOrKey.charAt(0).toUpperCase() + this.state.typeSumOrKey.slice(1) + " created" : '',
						"event_properties": {
							"user_org": this.props.orgData !== null ? this.props.orgData.name : 'Private User',
						},
						"language": i18n.language,
						"platform": isPlatform('ios') ? 'ios' : isPlatform('android') ? 'android' : 'desktop',
						"app_version": appVersion,
						"time": Date.now()
					})
					if (this.state.typeSumOrKey === "summary")
						this.setState({ isSummaryOpen: true, summaryReadyMsg: true });
					else
						this.setState({ isKeyWordsOpen: true, keywordsReadyMsg: true });

					this.setState({ infoMsg: false });
					//console.log((("success")
				} else {
					areSummaryOrKeywords.call(this);
					this.setState({ isKeyWordsOpen: false, isGeneratingKeywords: false, isGeneratingSummary: false, isSummaryOpen: false, summaryReadyMsg: false });
					console.log("error:il summary non è andato a buon fine");
				}
			})
			.catch((code) => {
				//ERROR STATUS CODE 500
				//HANDLE ERROR
				//console.log(("[SUMMARY] error status code ", code);
			});
		this.setState({ selectedSections: [] }); // DO NOT REMOVE!!!
	});
}

export async function generateConfirmationToast(this: any, fromPdf: boolean, docID: string) {
	const toast = await toastController.create({
		message:
			i18n.language === "it"
				? "La tua mappa è pronta!"
				: i18n.language === "en"
					? "Your map is ready!"
					: i18n.language === "es"
						? "Su mapa está listo"
						: i18n.language === "fr"
							? "Votre carte est prête"
							: i18n.language === "ca"
								? "La teva mapa està llesta!"
								: "Ihre Karte ist fertig!",
		duration: 5000,
		position: "bottom",
		color: "success",
		buttons: [
			{
				text:
					i18n.language === "it"
						? "Vai"
						: i18n.language === "en"
							? "Go"
							: i18n.language === "es"
								? "Ir"
								: i18n.language === "fr"
									? "Aller"
									: i18n.language === "ca"
										? "Ves"
										: "Gehen",
				handler: () => {

					setLocalStorageItem(false,this.state.document.uuid)
					if (window.location.pathname === "/maps") {
						this.props.history.push(`/maps/${fromPdf ? docID : this.state.document!.uuid}`);
					} else {
						this.props.history.push({
							pathname: `/maps/${fromPdf ? docID : this.state.document!.uuid}`,
						});
					}
					sendEvent({
						"user_id": this.state.userUuid,
						"event_type": "Map visited",
						"event_properties": {
							"user_org": this.props.organizationName !== null ? this.props.organizationName : "Private User",
							"document_uuid": this.state.document?.uuid,
							"document_name": this.state.document?.name,
						},
						"language": i18n.language,
						"platform": isPlatform("ios") ? "ios" : isPlatform("android") ? "android" : "desktop",
						"app_version": appVersion,
						"time": Date.now(),
					});
				},
			},
			{
				text:
					i18n.language === "it"
						? "Non ora"
						: i18n.language === "en"
							? "Not now"
							: i18n.language === "es"
								? "Ahora no"
								: i18n.language === "fr"
									? "Pas maintenant"
									: i18n.language === "ca"
										? "Ara no"
										: "nicht jetzt",
				handler: () => {
					toast.dismiss();
				},
			},
		],
	});
	await toast.present();
}

export async function generateErrorToast(this: any) {
	const toast = await toastController.create({
		message:
			i18n.language === "it"
				? "Errore durante la creazione!"
				: i18n.language === "en"
					? "Error during creation!"
					: i18n.language === "es"
						? "¡Error durante la creación!"
						: i18n.language === "fr"
							? "Erreur lors de la création!"
							: i18n.language === "ca"
								? "Error durant la creació!"
								: "Fehler bei der Erstellung!",
		duration: 3000,
		position: "bottom",
		color: "warning",
		buttons: [
			{
				text:
					i18n.language === "it"
						? "Chiudi"
						: i18n.language === "en"
							? "Close"
							: i18n.language === "es"
								? "Cerrar"
								: i18n.language === "fr"
									? "fermer"
									: i18n.language === "ca"
										? "Tancar"
										: "Schließen",
				handler: () => {
					let state = false
					localStorage.setItem('mapStateFromFile', JSON.stringify(state));
					toast.dismiss();
				},
			},
		],
	});
	await toast.present();
}

export function startTTS(this: any) {
	if (this.state.userUuid && this.state.showTtsPlayer) {
		this.setState({
			disablePlayButton: true,
			disableTTSOptions: true,
			disableTTSSkipButtons: true,
		});
		let elementsToSynthetize: any = null;
		if (this.state.isSummaryOpen && this.state.summaryDocumentElements && this.state.summaryDocumentElements?.length > 0)
			elementsToSynthetize = this.state.summaryDocumentElements;
		else elementsToSynthetize = this.state.documentElements;
		settingsServices.getUserSettings(this.state.userUuid,this.state.bearerToken).then((ttsSettings) => {
			getTTS(elementsToSynthetize, elementsToSynthetize[this.state.ttsParagraphPointer!].text, ttsSettings)
				.then((response) => {
					this.setState({ speechMarks: response.speech_marks });
					//console.log('CIAONE: ', response.length > 1000)
					//console.log('SPEECH MARKS AAA: ', response)
					let tempAudios = this.state.generatedAudios;
					tempAudios.push({
						id: elementsToSynthetize![0].index,
						text: elementsToSynthetize![this.state.ttsParagraphPointer!].text,
						audio: response.audio,
						speechMarks: response.speech_marks,
						settings: ttsSettings,
					});
					this.setState(
						{
							audioToPlay: response.audio,
							speechMarks: response.speech_marks,
							generatedAudios: tempAudios,
							disablePlayButton: false,
							disableTTSOptions: false,
							disableTTSSkipButtons: false,
						},
						() => {
							this.changeExistingSpans();
							this.getKaraoke(this.state.documentElements!);
						}
					);
				})
				.catch((err) => {
					console.error("[FileViewer] error getting tts:", err);
				});
		});
	}
}
export function calcolaToptts(this:any) {
	if (this.state.ttsParagraphPointer !== null) {
		let paragraphrect = document.getElementById(String(this.state.ttsParagraphPointer))?.getBoundingClientRect();
		let top: string | undefined;

		if (this.state.howMuchScrolled > 0 && paragraphrect !== undefined) {
			top = `${paragraphrect.top - 70 + this.state.howMuchScrolled}` + "px";
			console.log(top)

		} else if (paragraphrect !== undefined) {

			top = `${paragraphrect.top - 70}` + "px";
			console.log(top)

		}
		if (top !== undefined && !isMobile) {
			document.getElementById("ttsbox")!.style.top = top;
		}
		this.state.contentRef.current!.scrollToPoint(0, parseFloat(top!), 1000);
	}
}
export function redeemBook(_this: any, ISBNcode: string, userDocuments: Document[] | null) {
	if (userDocuments && userDocuments.length > 0) {
		console.log("DOCUMENTS PROP: ", userDocuments);
		let correspondingDocument = userDocuments.filter(doc => doc.isbn_code === ISBNcode)
		if (correspondingDocument.length > 0) {
			//BOOK ALREADY REDEEMED
			_this.setState({ showBookAlreadyRedeemedError: true, showConfirmationMessage: false, isLoadingRedeemBook: false, showRedeemBookError: false })
			return
		}
	}
	else {
		console.log("NO DOCUMENTS, THROW ERROR");
	}
	const checkBookCode = httpsCallable(functions, 'checkBookCode-checkBookCode')
	checkBookCode({
		code: ISBNcode,
		org_uuid: _this.props.orgData?.uuid
	})
		.then(res => {
			console.log("res");
			_this.setState({ isLoadingRedeemBook: false, showConfirmationMessage: true, showBookAlreadyRedeemedError: false })

		})
		.catch((err: any) => {
			console.error("ERROR", err)
			_this.setState({ isLoadingRedeemBook: false, showConfirmationMessage: false, showBookAlreadyRedeemedError: false })
		})
	_this.setState({ showRedeemBookError: false, showConfirmationMessage: false, showBookAlreadyRedeemedError: false })
}