import React, { useState, useRef, useEffect, useCallback } from "react";
import {
	IonContent,
	IonPage,
	IonHeader,
	IonToolbar,
	IonButtons,
	IonButton,
	IonIcon,
	IonPopover,
	IonModal,
	IonSelect,
	IonSelectOption,
	useIonToast,
	SelectChangeEventDetail,
	isPlatform,
} from "@ionic/react";
import "./SummaryViewer.css";
import ColorPicker from "../components/colorPicker";
import boldIcon from "../../../assets/icons/oi--bold.svg";
import italicIcon from "../../../assets/icons/fa-solid--italic.svg";
import ttsOn from "../../../assets/icons/text-to-speech.svg";
import playBtnIcon from "../../../assets/icons/playButtonIcon.svg";
import pauseBtnIcon from "../../../assets/icons/pauseButtonIcon.svg";
import forwardBtnIcon from "../../../assets/icons/iconoir--forward-solid.svg";
import backwardBtnIcon from "../../../assets/icons/iconoir--backward-solid.svg";
import dictionaryIcon from "../../../assets/icons/dictionary-book.svg";
import startingPoint from "../../../assets/icons/starting_point_icon.svg";

import { chevronBack, colorFillSharp, playCircle } from "ionicons/icons";
import { SpeechMark } from "../../audiobooks/store/reducers";
import debounce from "lodash/debounce"; // You might need to install lodash
import {
	addOrUpdateItem,
	applyKaraokeEffectUtil,
	backwardUtil,
	checkStatusBar,
	deleteItem,
	extractPlainText,
	forwardUtil,
	generateTTSUtil,
	getGlobalState,
	getItemById,
	getSummaryTextUtil,
	handleTimeUpdate,
	playPauseUtil,
	rerenderWithPlainText,
	resetGlobalState,
	setBatchGlobalState,
	setGlobalState,
	textFormatation,
	updateKaraokeHighlightUtil,
} from "./summaryViewerUtils";
import { isMobile } from "react-device-detect";
import { updateSummary } from "../../sessions/store/utils/summaries";
import { Session } from "../../sessions/store/reducers";
import { auth } from "../../../firebaseConfig";
import SelectionPopover from "../../popover/SelectionPopover";
import ClickPopover from "../../popover/ClickPopover";
import { settingsServices, UserSettings } from "../../settings/store/services";
import { useHistory, useLocation } from "react-router-dom";
import { Trans } from "react-i18next";
import i18n from "../../../i18n";

export interface Block {
	id: string;
	content: string;
	type: string;
	index: number;
	style: {
		bold: boolean;
		italic: boolean;
		classes: string[];
		colors: string[];
	};
}

export interface Paragraph {
	id: string;
	blocks: Block[];
}
interface LocationState {
	resourceId: string;
	session: Session;
}
export interface Page {
	id: string;
	paragraphs: Paragraph[];
}

export interface SummaryViewer {
	pages: Page[];
}
// Define props interface
interface SummaryViewerProps {
	history: {
		location: {
			state: {
				file_id: string;
				file_text: boolean;
				resourceId: string;
				session: Session;
			};
		};
	};
}
interface LocationState {
	file_id: string;
	file_text: boolean;
	resourceId: string;
	session: Session;
}

const SummaryViewer: React.FC<SummaryViewerProps> = (props) => {
	
	const SUMMARY_LOCAL_STROAGE_KEY = "editorData";
  const location = useLocation();
  const pathname = window.location.pathname;
  
  // Extract resourceId from URL or state
  const resourceIdFromUrl = pathname.includes('summaryView-') 
    ? pathname.split('summaryView-')[1] 
    : null;
    
  // Safely access state - might be undefined when accessing directly via URL
  const state = location.state as LocationState;
  
  // Use optional chaining to safely access properties
  const resourceIdFromState = state?.resourceId;
  const sessionFromState = state?.session;
  const fileIdFromState = state?.file_id;
  const fileTextFromState = state?.file_text;
  
  // Use URL resourceId if available, otherwise fall back to state
  const summary_id = resourceIdFromUrl || resourceIdFromState;
  const session = sessionFromState;
  const file_id = fileIdFromState;
  const file_text = fileTextFromState;
	const history = useHistory();

	//STATES
	const [localData, setLocalData] = useState<SummaryViewer | undefined>(undefined);
	const [bearerToken, setBearerToken] = useState<string | undefined>(undefined);
	const [user_id, setUserId] = useState<string | undefined>(undefined);
	const [isSpanStyle, setIsSpanStyle] = useState<{ style: string; active: boolean }>({ style: "", active: false });
	const [ttsSettingsChanged, setTtsSettingsChanged] = useState<boolean>(false);
	const [isJustStyling, setIsJustStyling] = useState<boolean>(false);
	const [activeElement, setActiveElement] = useState<HTMLElement | null>(null);
	const [fileId, setIsFileId] = useState<string>(file_id);
	const [txtColor, setTxtColor] = useState<{ color: string; active: boolean }>({ color: "var(--txt-color)", active: false });
	const [showColorPicker, setShowColorPicker] = useState<boolean>(false);
	const [isKaraoke, setIsKaraoke] = useState<boolean>(true);
	const [customFromBtn, setCustomFromBtn] = useState<boolean>(false);
	const [firstLoad, setFirstLoad] = useState<boolean>(false);
	const [isDictionary, setIsDictionary] = useState<boolean>(true);
	const [isLetterColoring, setIsLetterColoring] = useState<boolean>(true);
	const [alreadyGenerating, setAlreadyGenerating] = useState<boolean>(false);
	const [data, setData] = useState<SummaryViewer | null>(null); // Declare useState outside
	const [raw_file_text, setRawFileText] = useState<string | undefined>(undefined);
	const [isExpanded, setIsExpanded] = useState(false);
	const [speechMarks, setSpeechMarks] = useState<SpeechMark[]>([]);
	const [audioSrc, setAudioSrc] = useState<string>("");
	const [isPlaying, setIsPlaying] = useState(false);
	const [currentWordIndex, setCurrentWordIndex] = useState<number>(0);
	const [currentChunkIndex, setCurrentChunkIndex] = useState<number>(0);
	const [allAudioSrc, setAllAudioSrc] = useState<string[]>([]);
	const [allSpeechMarks, setAllSpeechMarks] = useState<any[]>([]);
	const [customStart, setCustomStart] = useState<SpeechMark[]>([]);
	const [dictionaryIsOpen, setDictionaryIsOpen] = useState(false);
	const [userSettings, setUserSettings] = useState<UserSettings | null>(null);
	const [mobileDictionaryText, setMobileDictionaryText] = useState<string>("");
	const [presentToast] = useIonToast();


	//REFS
	const isUpdatingRef = useRef(false);
	const audioRef = useRef<HTMLAudioElement | null>(null);
	const selectionPopoverRef = useRef<any>(null);
	const showPopoverButtonRef = useRef<any>(null);
	const karaokeTimeoutRef = useRef<number | null>(null);
	const editorRef = useRef<HTMLDivElement>(null);
	const clickPopoverRef = useRef<ClickPopover>(null);
	const pageRef = useRef(null);
	useEffect(() => {
		const fetchData = async () => {
			const data = await getItemById<SummaryViewer>(summary_id, SUMMARY_LOCAL_STROAGE_KEY);
			setLocalData(data.data);
		};

		fetchData();
	}, [summary_id]);
	let lastFocusedElement: HTMLElement | null = null;

	document.addEventListener('focusin', (e) => {
	 if ((e.target as HTMLElement).hasAttribute('contenteditable')) {
	   lastFocusedElement = e.target as HTMLElement;
	 }
	});
	
	document.addEventListener('mousedown', (e:any) => {
	 const popup = document.querySelector('.popup');
	 const isInPopup = popup?.contains(e.target);
	
	 if (isInPopup && lastFocusedElement) {
	   // Prevent losing focus when using color picker
	   setTimeout(() => lastFocusedElement?.focus(), 0);
	 }
	});
	interface FileIndices {
		wordIndex: number;
		chunkIndex: number;
	  }
	  
	  interface StorageData {
		[fileId: string]: FileIndices;
	  }
	  
	  const STORAGE_KEY = 'fileIndices';
	  
	  // Utility function to clean up old storage format
	  const cleanupOldStorage = () => {
		// Get all localStorage keys
		const keys = Object.keys(localStorage);
		
		// Find all old format keys
		const oldFormatKeys = keys.filter(key => 
		  key.startsWith('wordIndex_') || 
		  key.startsWith('chunkIndex_')
		);
	  
		// Create new format data structure
		const newFormatData: StorageData = {};
	  
		// Group old keys by fileId
		oldFormatKeys.forEach(key => {
		  const isWordIndex = key.startsWith('wordIndex_');
		  const fileId = key.replace(isWordIndex ? 'wordIndex_' : 'chunkIndex_', '');
		  
		  if (!newFormatData[fileId]) {
			newFormatData[fileId] = {
			  wordIndex: 0,
			  chunkIndex: 0
			};
		  }
	  
		  const value = localStorage.getItem(key);
		  if (value !== null) {
			if (isWordIndex) {
			  newFormatData[fileId].wordIndex = parseInt(value);
			} else {
			  newFormatData[fileId].chunkIndex = parseInt(value);
			}
		  }
		});
	  
		// Save in new format
		if (Object.keys(newFormatData).length > 0) {
		  localStorage.setItem(STORAGE_KEY, JSON.stringify(newFormatData));
		}
	  
		// Remove old format keys
		oldFormatKeys.forEach(key => localStorage.removeItem(key));
	  };
	  

		// Load indices from storage
		const loadIndices = () => {
		  const storageData = localStorage.getItem(STORAGE_KEY);
		  if (storageData) {
			try {
			  const parsedData: StorageData = JSON.parse(storageData);
			  return parsedData[fileId] || null;
			} catch (error) {
			  console.error('Error parsing storage data:', error);
			  return null;
			}
		  }
		  return null;
		};
	  
		// Save indices to storage
		const saveIndices = (wordIndex: number, chunkIndex: number) => {
		  const storageData = localStorage.getItem(STORAGE_KEY);
		  let data: StorageData = {};
		  
		  if (storageData) {
			try {
			  data = JSON.parse(storageData);
			} catch (error) {
			  console.error('Error parsing storage data:', error);
			}
		  }
	  
		  data[fileId] = {
			wordIndex,
			chunkIndex
		  };
	  
		  localStorage.setItem(STORAGE_KEY, JSON.stringify(data));
		};
	
	useEffect(() => {
		cleanupOldStorage();

		let stopKaraokeAnimation: any = getGlobalState("stopKaraoke");
		if (stopKaraokeAnimation) stopKaraokeAnimation();
		auth.currentUser!.getIdToken().then(async (token) => {
			const userSettings = await settingsServices.getUserSettings(auth.currentUser!.uid, token);
			setUserSettings(userSettings);
			console.log(userSettings);
			setBearerToken(token);
		});
		setUserId(auth.currentUser?.uid);
		setGlobalState("stopKaraoke", null);
		resetGlobalState();

		getSummaryTextUtil(
			summary_id,
			localData,
			setData,
			setUserSettings,
			rerenderWithPlainText,
			summary_id,
			SUMMARY_LOCAL_STROAGE_KEY,
			fileId,
			file_text,
			setRawFileText
		);
		// console.log("GARARARA",fileId)
		// const savedWordIndex = localStorage.getItem(`wordIndex_${fileId}`);
		// const savedChunkIndex = localStorage.getItem(`chunkIndex_${fileId}`);
		
		// if (savedWordIndex !== null) {
		// 	setFirstLoad(true);
		// 	setCurrentWordIndex(parseInt(savedWordIndex));
			
		// 	// Remove highlighting from all words
		// 	document.querySelectorAll(`[id^="word-"]`).forEach((word) => {
		// 	  word.classList.remove("highlight");
		// 	});
		//   }
		  
		//   if (savedChunkIndex !== null) {
		// 	setGlobalState("currentChunkIndexGlobal", Number(savedChunkIndex));
		// 	setCurrentChunkIndex(parseInt(savedChunkIndex));
		//   }
		const savedIndices = loadIndices();
    if (savedIndices) {
      setFirstLoad(true);
      setCurrentWordIndex(savedIndices.wordIndex);
      setCurrentChunkIndex(savedIndices.chunkIndex);
      setGlobalState("currentChunkIndexGlobal", savedIndices.chunkIndex);
	  setAudioSrc(allAudioSrc[savedIndices.chunkIndex])
	  setCustomStart(allSpeechMarks[savedIndices.chunkIndex]);
		setSpeechMarks(allSpeechMarks[savedIndices.chunkIndex]);

      // Remove highlighting from all words
      document.querySelectorAll(`[id^="word-"]`).forEach((word) => {
        word.classList.remove("highlight");
      });
    }
	}, []);
	
	// // Save word index when it changes
	// useEffect(() => {
	// 	localStorage.setItem(`wordIndex_${fileId}`, currentWordIndex.toString());
	// }, [currentWordIndex, fileId]);

	// // Save chunk index when it changes
	// useEffect(() => {
	// 	localStorage.setItem(`chunkIndex_${fileId}`, currentChunkIndex.toString());
	// }, [currentChunkIndex, fileId]);
	// Save indices when they change
	useEffect(() => {
		const currentIndices = loadIndices();
		if (currentIndices) {
		  saveIndices(currentWordIndex, currentIndices.chunkIndex);
		}
	  }, [currentWordIndex]);
	
	  useEffect(() => {
		const currentIndices = loadIndices();
		if (currentIndices) {
		  saveIndices(currentIndices.wordIndex, currentChunkIndex);
		}
	  }, [currentChunkIndex]);

	const clearSelection = () => {
		setDictionaryIsOpen(false);
		selectionPopoverRef.current?.eraseDictionaryMeanings();
		selectionPopoverRef.current?.changeSelectedText(null);
		selectionPopoverRef.current?.setSelection(null, null, false);
		showPopoverButtonRef.current?.showPopoverButton(false);

		// Clear the actual selection
		const selection = window.getSelection();
		if (selection) {
			selection.removeAllRanges();
		}
	};

	const getTextSelection = () => {
		const selection = window.getSelection();
		const selectedText = selection?.toString().trim();

		if (selection && selectedText && selectedText !== "") {
			selectionPopoverRef.current?.changeSelectedText(selectedText);
			selectionPopoverRef.current?.setSelection(selection, selectedText, true);
			setDictionaryIsOpen(true);
		} else {
			clearSelection();
		}
	};

	useEffect(() => {
		if (data && editorRef.current && !isUpdatingRef.current) {
			const content = renderContent();
			if (content) {
				editorRef.current.innerHTML = content;
			}
		}
	}, [data]);

	useEffect(() => {
		if (data && editorRef.current) {
			if (!isKaraoke) {
				generateTTSUtil(
					data,
					setSpeechMarks,
					setAudioSrc,
					setData,
					setAllAudioSrc,
					setAllSpeechMarks,
					summary_id,
					setAlreadyGenerating,
					alreadyGenerating,
					file_text,
					fileId,
					raw_file_text
				);
			} else {
				getSummaryTextUtil(
					summary_id,
					localData,
					setData,
					setUserSettings,
					rerenderWithPlainText,
					summary_id,
					SUMMARY_LOCAL_STROAGE_KEY,
					fileId,
					file_text,
					setRawFileText
				);
				// const plainText = extractPlainText(data);
				// const rerenderedData = rerenderWithPlainText(data, plainText);
				// setData(rerenderedData);
			}
			const content = renderContent();
			if (content) {
				editorRef.current.innerHTML = content;
			}
		}
	}, [isKaraoke]);

	useEffect(() => {
		if (data && editorRef.current) {
			let touchTimeout: NodeJS.Timeout | null = null;

			const handleTouchEnd = (e: TouchEvent) => {
				// Clear any existing timeout
				if (touchTimeout) {
					clearTimeout(touchTimeout);
				}

				// Set a small delay to allow the selection to be made
				touchTimeout = setTimeout(() => {
					getTextSelection();
				}, 150);
			};

			const handleTouchStart = (e: TouchEvent) => {
				// If there's no current selection, we don't need to do anything
				const selection = window.getSelection();
				if (!selection || selection.toString().trim() === "") {
					return;
				}

				// Check if touch is outside the selected area
				const range = selection.getRangeAt(0);
				const rects = range.getClientRects();
				const touch = e.touches[0];

				let touchInSelection = false;
				for (let i = 0; i < rects.length; i++) {
					const rect = rects[i];
					if (touch.clientX >= rect.left && touch.clientX <= rect.right && touch.clientY >= rect.top && touch.clientY <= rect.bottom) {
						touchInSelection = true;
						break;
					}
				}

				if (!touchInSelection) {
					clearSelection();
				}
			};

			const handleSelectionChange = () => {
				// Only handle selection changes on desktop
				if (!("ontouchstart" in window)) {
					getTextSelection();
				}
			};

			if (!isDictionary && !isMobile) {
				// Add both mouse and touch event listeners
				document.addEventListener("selectionchange", handleSelectionChange);
				editorRef.current.addEventListener("touchstart", handleTouchStart);
				editorRef.current.addEventListener("touchend", handleTouchEnd);
			}
			console.log("arara");
			// Process content
			let text_data: string | SummaryViewer;
			if (raw_file_text) text_data = raw_file_text;
			else text_data = data;
			const plainText = extractPlainText(text_data, file_text);
			const rerenderedData = rerenderWithPlainText(data, plainText, file_text);
			setData(rerenderedData);

			const content = renderContent();
			if (content) {
				editorRef.current.innerHTML = content;
			}

			// Cleanup
			return () => {
				document.removeEventListener("selectionchange", handleSelectionChange);
				if (editorRef.current) {
					editorRef.current.removeEventListener("touchstart", handleTouchStart);
					editorRef.current.removeEventListener("touchend", handleTouchEnd);
				}
				if (touchTimeout) {
					clearTimeout(touchTimeout);
				}
			};
		}
	}, [isDictionary]);
	useEffect(() => {
		if (isMobile && data) {
			const stripHtml = (html: string) => {
				const doc = new DOMParser().parseFromString(html, "text/html");
				return doc.body.textContent || "";
			};

			const handleClick = (e: any) => {
				const element = e.target.closest("[data-block-id]");
				if (!element) return;

				const page_id = element.dataset.page;
				const paragraph_id = element.dataset.paragraph;
				const block_id = element.dataset.block;

				if (data && page_id && paragraph_id && block_id) {
					const blockText = data.pages[page_id].paragraphs[paragraph_id].blocks[block_id].content;
					const cleanText = stripHtml(blockText);
					setMobileDictionaryText(cleanText);
				}
			};

			document.addEventListener("click", handleClick);

			return () => {
				document.removeEventListener("click", handleClick);
			};
		}
	}, [data, mobileDictionaryText]);

	useEffect(() => {
		console.log("Dictionary text changed");
		if (mobileDictionaryText && !isDictionary) {
			setDictionaryIsOpen(true);
		}
	}, [mobileDictionaryText]);
	const renderContent = () => {
		if (!data) return null; // Ensure data is not null before proceeding

		if (file_text && raw_file_text && isKaraoke) {
			// Helper function to split text into sensible paragraphs
			const splitIntoBlocks = (paragraph: string) => {
				// Split into sentences but keep the periods
				return paragraph.match(/[^.!?]+[.!?]+/g) || [paragraph];
			};
			const splitIntoParagraphs = (text: string) => {
				// Split by periods but keep them
				const sentences = text.match(/[^.!?]+[.!?]+/g) || [];

				const paragraphs = [];
				let currentParagraph: string[] = [];
				let wordCount = 0;

				sentences.forEach((sentence) => {
					const sentenceWordCount = sentence.trim().split(/\s+/).length;

					if (wordCount + sentenceWordCount > 60) {
						if (currentParagraph.length > 0) {
							paragraphs.push(currentParagraph.join(" "));
							currentParagraph = [];
							wordCount = 0;
						}
					}

					currentParagraph.push(sentence.trim());
					wordCount += sentenceWordCount;
				});

				if (currentParagraph.length > 0) {
					paragraphs.push(currentParagraph.join(" "));
				}

				return paragraphs;
			};

			// Split text into paragraphs
			const paragraphs = splitIntoParagraphs(raw_file_text);

			// Group paragraphs into pages (6 paragraphs per page)
			const PARAGRAPHS_PER_PAGE = 6;
			const pages = [];

			for (let i = 0; i < paragraphs.length; i += PARAGRAPHS_PER_PAGE) {
				pages.push(paragraphs.slice(i, i + PARAGRAPHS_PER_PAGE));
			}

			return pages
				.map((page, pageIndex) => {
					const pageContent = page
						.map((paragraph, paragraphIndex) => {
							// Split paragraph into blocks (sentences)
							const blocks = splitIntoBlocks(paragraph);

							return blocks
								.map((block, blockIndex) => {
									const blockId = `block_${pageIndex}_${paragraphIndex}_${blockIndex}`;
									return `<p
                                    class=""
                                    data-block-id="${blockId}"
                                    data-page="${pageIndex}"
                                    data-paragraph="${paragraphIndex}"
                                    data-block="${blockIndex}"
                                    style="color: var(--txt-color);"
                                >${block.trim()}</p>`;
								})
								.join("");
						})
						.join("");

					return `<div class="page" contentEditable=${
						!isKaraoke || !isDictionary  || file_text ? false : true
					} id="page-${pageIndex}">${pageContent}</div>`;
				})
				.join("");
		} else {
			// Original implementation for non-file_text case
			return data.pages
				.map((page, pageIndex) => {
					const pageContent = page.paragraphs
						.map((paragraph) => {
							return paragraph.blocks
								.map((block) => {
									const style = [];
									if (block.style.bold) style.push("bold");
									if (block.style.italic) style.push("italic");
									if (isKaraoke && block?.style?.classes?.includes("colored")) {
										style.push("colored");
									}
									let tag;
									switch (block.type) {
										case "header":
											tag = "h1";
											break;
										case "sub_header":
											tag = "h2";
											break;
										case "bp":
											tag = "li";
											break;
										default:
											tag = "p";
									}
									const [, page_id, paragraph_id, block_id] = block.id.match(/block_(\d+)_(\d+)_(\d+)/) || [];
									return `<${tag}
										class="${style.join(" ")}"
										data-block-id="${block.id}"
										data-page="${page_id}"
										data-paragraph="${paragraph_id}"
										data-block="${block_id}"
										style="color: ${block?.style?.colors?.[0] ?? "var(--txt-color)"};"
									>${block.content}</${tag}>`;
								})
								.join("");
						})
						.join("");

					return `<div class="page" contentEditable=${!isKaraoke || !isDictionary ? false : true} 
					  onblur="this.focus()"

					id="page-${
						pageIndex + 1
					}">${pageContent}</div>`;
				})
				.join("");
		}
	};
	useEffect(() => {
		if (showColorPicker) {
		  // Store the currently focused element when color picker opens
		  setActiveElement(document.activeElement as HTMLElement);
		} else {
		  // Restore focus when color picker closes
		  activeElement?.focus();
		}
	  }, [showColorPicker]);

	
	const updateDataFromHTML = useCallback(() => {
		console.log("SAVING...");
		if (editorRef.current) {
			if (!isJustStyling) {
				let id: string = "";
				if (summary_id) id = summary_id;
				else id = fileId;
				deleteItem(id, "ttsData");
			} else {
				setTxtColor({ color: "", active: false });
				setIsSpanStyle({ style: "", active: false });
			}
			isUpdatingRef.current = true;
			const newPages: Page[] = Array.from(editorRef.current.children)
				.filter((child) => child.classList.contains("page"))
				.map((pageElement, pageIndex) => {
					const htmlPageElement = pageElement as HTMLElement;
					const blocks: Block[] = Array.from(htmlPageElement.children).map((blockElement, blockIndex) => {
						const htmlBlockElement = blockElement as HTMLElement;

						// Function to get all classes from element and its children
						const getClasses = (element: HTMLElement): string[] => {
							const classes = new Set<string>();

							// Add element's own classes
							element.classList.forEach((className) => classes.add(className));

							// Add classes from styled children
							const styledElements = element.getElementsByClassName("styled");
							Array.from(styledElements).forEach((styledEl) => {
								if (styledEl instanceof HTMLElement) {
									styledEl.classList.forEach((className) => classes.add(className));
								}
							});

							return Array.from(classes);
						};

						// Function to get all inline styles
						const getInlineStyles = (element: HTMLElement): Record<string, string> => {
							const styles: Record<string, string> = {};

							// Get element's own inline styles
							if (element.style.fontWeight) styles.fontWeight = element.style.fontWeight;
							if (element.style.fontStyle) styles.fontStyle = element.style.fontStyle;
							if (element.style.color && element.style.color !== "var(--txt-color)" && element.style.color !== "" && element.style.color !== "inherit") {
								styles.color = element.style.color;
							}

							// Get inline styles from styled children
							const styledElements = element.getElementsByClassName("styled");
							Array.from(styledElements).forEach((styledEl) => {
								if (styledEl instanceof HTMLElement) {
									if (styledEl.style.fontWeight) styles.fontWeight = styledEl.style.fontWeight;
									if (styledEl.style.fontStyle) styles.fontStyle = styledEl.style.fontStyle;
									if (
										styledEl.style.color &&
										styledEl.style.color !== "var(--txt-color)" &&
										styledEl.style.color !== "" &&
										styledEl.style.color !== "inherit"
									) {
										styles.color = styledEl.style.color;
									}
								}
							});

							return styles;
						};

						// Get style information
						const classes = getClasses(htmlBlockElement);
						const inlineStyles = getInlineStyles(htmlBlockElement);

						// Determine style properties from classes and inline styles
						const styleInfo = {
							bold: classes.includes("bold") || inlineStyles.fontWeight === "700",
							italic: classes.includes("italic") || inlineStyles.fontStyle === "italic",
							colors: inlineStyles.color ? [inlineStyles.color] : [],
							classes: classes, // Save all classes
							inlineStyles: inlineStyles, // Save all inline styles
						};

						return {
							id: htmlBlockElement.dataset.blockId || `block_${pageIndex}_${blockIndex}`,
							content: htmlBlockElement.innerHTML || "",
							index: blockIndex,
							type:
								htmlBlockElement.tagName.toLowerCase() === "h1"
									? "header"
									: htmlBlockElement.tagName.toLowerCase() === "h2"
									? "sub_header"
									: htmlBlockElement.tagName.toLowerCase() === "li"
									? "bp"
									: "text",
							style: styleInfo,
						};
					});
					const paragraphs: Paragraph[] = [
						{
							id: `paragraph_${pageIndex}_0`,
							blocks: blocks,
						},
					];

					return {
						id: htmlPageElement.id || `page_${pageIndex}`,
						paragraphs: paragraphs,
					};
				});
			setIsJustStyling(false);
			setData((prevData) => {
				const updatedData: SummaryViewer = {
					...prevData,
					pages: newPages,
				};
				if (fileId) addOrUpdateItem(fileId, updatedData, SUMMARY_LOCAL_STROAGE_KEY);
				else addOrUpdateItem(summary_id, updatedData, SUMMARY_LOCAL_STROAGE_KEY);

				let updatedSummaryContent = JSON.stringify(updatedData);
				auth.currentUser
					?.getIdToken()
					.then((token) => updateSummary(token, summary_id, props.history.location.state.session.id, updatedSummaryContent));
				return updatedData;
			});

			isUpdatingRef.current = false;
		}
	}, [isJustStyling]);

	const debouncedUpdateDataFromHTML = useCallback(debounce(updateDataFromHTML, 1000), [updateDataFromHTML]);

	const handleContentChange = useCallback(() => {
		debouncedUpdateDataFromHTML();
	}, [debouncedUpdateDataFromHTML]);

	const applyStyle = () => {
		if (isSpanStyle.active || txtColor.active) {
			textFormatation(isSpanStyle, txtColor,showColorPicker,activeElement);
			handleContentChange();
		}
	};
	useEffect(() => {
		if (txtColor.color) {
			applyStyle();
		}
	}, [txtColor]);

	useEffect(() => {
		applyStyle();
	}, [isSpanStyle]);

	const handleColorConfirm = useCallback(
		(color: string) => {
			setTxtColor({ color: color, active: true });
			// setIsSpanStyle((prevState) => ({ ...prevState, style: "color", active: true }));
			setIsJustStyling(true);
		},

		[]
	);

	const applyKaraokeEffect = (startIndex: number) => {
		const currentTime = audioRef.current?.currentTime || 0;
		let stop: any = null;
		stop = applyKaraokeEffectUtil(speechMarks, audioRef, setCurrentWordIndex);
		setGlobalState("stopKaraoke", stop);
	};

	const playPause = () => {
		setGlobalState("backwardsAudio", false);
		setGlobalState("playingNextChunk", false);
		let id: string = "";
		if (fileId) id = fileId;
		else id = summary_id;
		if (ttsSettingsChanged && data) {
			setGlobalState("ttsCreating", true);
			generateTTSUtil(
				data,
				setSpeechMarks,
				setAudioSrc,
				setData,
				setAllAudioSrc,
				setAllSpeechMarks,
				summary_id,
				setAlreadyGenerating,
				alreadyGenerating,
				file_text,
				fileId,
				raw_file_text
			);
			setTtsSettingsChanged(false);
		}
		else{
			console.log("Starting playback sequence:", {
				currentIndex: currentWordIndex,
				chunkIndex: currentChunkIndex
			});
	
			// First set all the state
			setBatchGlobalState({
				currentChunkIndexPlaying: currentChunkIndex,
				currentChunkIndexGlobal: currentChunkIndex,
				forwardBackwardsChunkIndex: currentChunkIndex,
				playingWordsIndex: currentWordIndex - 1,
			});
	
			// Then set the audio source and speech marks
			setAudioSrc(allAudioSrc[currentChunkIndex]);
			setSpeechMarks(allSpeechMarks[currentChunkIndex]);
	
			// Wait for the audio source to be set before playing
			setTimeout(() => {
				setCurrentWordIndex(currentWordIndex - 1);
				setCurrentChunkIndex(currentChunkIndex);
				
				console.log("Playing with state:", {
					audioSrc: allAudioSrc[currentChunkIndex],
					speechMarks: allSpeechMarks[currentChunkIndex]?.length
				});
	
				// Finally call playPauseUtil
				playPauseUtil(
					audioRef,
					isPlaying,
					currentWordIndex,
					currentChunkIndex,
					setIsPlaying,
					applyKaraokeEffect,
					karaokeTimeoutRef,
					setSpeechMarks,
					setAudioSrc,
					setAllAudioSrc,
					setAllSpeechMarks,
					setData,
					localData!,
					speechMarks,
					setCurrentWordIndex,
					id,
					file_text,
					raw_file_text!
				);
			}, 0);
		
		}
		console.log("DO NOTHING?")

	};

	const forward = () => {
		forwardUtil(audioRef, updateKaraokeHighlight, setAudioSrc, setCurrentChunkIndex, setIsPlaying, setSpeechMarks, setCurrentWordIndex);
	};

	const backward = () => {
		backwardUtil(audioRef, updateKaraokeHighlight, setAudioSrc, setCurrentChunkIndex, setIsPlaying, setSpeechMarks, setCurrentWordIndex);
	};

	const updateKaraokeHighlight = () => {
		updateKaraokeHighlightUtil(
			audioRef,
			isPlaying,
			setIsPlaying,
			applyKaraokeEffect,
			karaokeTimeoutRef,
			setSpeechMarks,
			setAudioSrc,
			setAllAudioSrc,
			setAllSpeechMarks,
			setData,
			localData!,
			speechMarks,
			setCurrentWordIndex,
			summary_id,
			file_text,
			raw_file_text!,
			fileId
		);
	};

	useEffect(() => {
		if (audioSrc) {
			const audioElement = new Audio(audioSrc);
			audioRef.current = audioElement;
			audioElement.addEventListener("ended", () => {
				// setIsPlaying(false);
				setCurrentWordIndex(currentWordIndex);
				if (karaokeTimeoutRef.current) {
					clearTimeout(karaokeTimeoutRef.current);
				}
			});

			return () => {
				audioElement.pause();
				audioElement.removeEventListener("ended", () => {});
				setFirstLoad(false)
				if (karaokeTimeoutRef.current) {
					clearTimeout(karaokeTimeoutRef.current);
				}
			};
		}
	}, [audioSrc]);

	const handleSpanClick = (event: any) => {
		const [_, chunkIndex, wordIndex] = event.target.id.split("-");
		const chunkNum = parseInt(chunkIndex, 10);
		const wordNum = parseInt(wordIndex, 10);

		const word = event.target.textContent || "";
		console.log(`Key: ${event.target.id}, Chunk Index: ${chunkNum}, Word Index: ${wordNum}, Word: ${word}`);
		console.log(allAudioSrc,allSpeechMarks)
		if (allAudioSrc[chunkNum] && allSpeechMarks[chunkNum]) {
			console.log("aaaa")
			setCurrentWordIndex(wordNum - 1);
			setCurrentChunkIndex(chunkNum);
			setAudioSrc(allAudioSrc[chunkNum]);
			setSpeechMarks(allSpeechMarks[chunkNum]);
			setCustomStart(allSpeechMarks[chunkNum]);

			setBatchGlobalState({
				currentChunkIndexPlaying: chunkNum,
				currentChunkIndexGlobal: chunkNum,
				forwardBackwardsChunkIndex: chunkNum,
				playingWordsIndex: wordNum - 1,
			});
			document.querySelectorAll('span[id^="word-"]').forEach((span) => {
				span.removeEventListener("click", handleSpanClick);
			});
		}
	};

	useEffect(() => {
		console.log("arara",currentWordIndex)
		if (audioRef.current) {
			const startTime = customStart[currentWordIndex];
			console.log(startTime)
			document.querySelectorAll('[id^="word-"]').forEach((word) => {
				word.classList.remove("highlight");
			});
			if (startTime) {
				console.log(
					"Setting start time:",
					startTime.time,
					getGlobalState("chunks").length >= getGlobalState("processedChunks").length,
					audioRef.current.duration - 10
				);
				// console.log(getGlobalState("chunks"),getGlobalState("processedChunks"))
				if (startTime.time && getGlobalState("currentChunkIndexPlaying") > getGlobalState("processedChunks").length) {
					// console.log("Setting start time:", startTime.time);
					audioRef.current.currentTime = startTime.time / 1000 - 10;
				} else {
					audioRef.current.currentTime = startTime.time / 1000;
				}
				
				let id: string = "";
				if (fileId) id = fileId;
				else id = summary_id;
				console.log(id);
				if(customFromBtn){
					audioRef.current.addEventListener("timeupdate", () =>
						handleTimeUpdate(
							audioRef,
							isPlaying,
							currentWordIndex,
							setIsPlaying,
							applyKaraokeEffect,
							karaokeTimeoutRef,
							setSpeechMarks,
							setAudioSrc,
							setAllAudioSrc,
							setAllSpeechMarks,
							setData,
							localData!,
							speechMarks,
							setCurrentWordIndex,
							id,
							file_text,
							raw_file_text!
						)
					);
					let stopKaraokeAnimation: any = applyKaraokeEffectUtil(speechMarks, audioRef, setCurrentWordIndex);
					setGlobalState("stopKaraoke", stopKaraokeAnimation);
					setCustomFromBtn(false)
				}
				audioRef.current.play();
				setIsPlaying(true);
			}
		}
	}, [customStart]);

	useEffect(() => {
		const addListeners = () => {
			document.querySelectorAll('span[id^="word-"]').forEach((span) => {
				span.addEventListener("click", handleSpanClick);
			});
		};

		addListeners();

		return () => {
			document.querySelectorAll('span[id^="word-"]').forEach((span) => {
				span.removeEventListener("click", handleSpanClick);
			});
		};
	}, []);

	const handleStartingPoint = () => {
		setIsPlaying(false);
		let stopKaraokeAnimation: any = getGlobalState("stopKaraoke");
		if (stopKaraokeAnimation) {
			stopKaraokeAnimation();
		}
		if (audioRef.current) {
			audioRef.current.pause();
		}

		// Re-add event listeners
		document.querySelectorAll('span[id^="word-"]').forEach((span) => {
			span.addEventListener("click", handleSpanClick);
		});

		// Reset other states as needed
		setCurrentWordIndex(0);
		setCurrentChunkIndex(0);
		// setCustomStart([]);
	};
	const speeds = [
		{ value: 0.5, label: "0.5x" },
		{ value: 0.75, label: "0.75x" },
		{ value: 1, label: "1x" },
		{ value: 1.25, label: "1.25x" },
		{ value: 1.5, label: "1.5x" },
		{ value: 2, label: "2x" },
	];

	const genders = [
		{ value: "F", label: "Female" },
		{ value: "M", label: "Male" },
	];

	const handleSpeedChange = (value: number) => {
		audioRef.current?.pause();
		setIsPlaying(false);
		resetGlobalState(["ttsCreating"]);
		document.querySelectorAll('[id^="word-"]').forEach((word) => {
			word.classList.remove("highlight");
		});
		audioRef.current?.removeEventListener("timeupdate", () => {});
		const numericValue = Number(value); // Convert to number

		if (user_id && bearerToken && data)
			settingsServices
				.setTtsSpeed(user_id, numericValue, bearerToken)
				.then((response) => {
					setUserSettings(response);
					console.log(response.speech_rate);
					let id: string = "";
					if (summary_id) id = summary_id;
					else id = fileId;
					setTtsSettingsChanged(true);
					deleteItem(id, "ttsData").then((response) => {
						if (response.success) {
							console.log("Deleted tts from local storage with success");
						}
					});
				})
				.catch((err) => {
					console.error(`[Settings] Error changing Speed - ${value}`, err);
				});
	};

	const handeleVoiceTypeChange = (value: string) => {
		audioRef.current?.pause();
		resetGlobalState(["ttsCreating"]);
		setIsPlaying(false);
		document.querySelectorAll('[id^="word-"]').forEach((word) => {
			word.classList.remove("highlight");
		});
		audioRef.current?.removeEventListener("timeupdate", () => {});
		let type: string;
		console.log(value);
		if (value === "F") type = "female";
		else type = "male";
		if (user_id && bearerToken && data)
			settingsServices
				.setVoiceType(user_id, type, bearerToken)
				.then((response) => {
					setUserSettings(response);
					console.log(response.speech_gender);
					let id: string = "";
					if (summary_id) id = summary_id;
					else id = fileId;
					setTtsSettingsChanged(true);
					deleteItem(id, "ttsData").then((response) => {
						if (response.success) {
							console.log("Deleted tts from local storage with success");
						}
					});
				})
				.catch((err) => {
					console.error(`[Settings] Error changing Voice Type - ${value}`, err);
				});
	};
	const showToastOnce = (message:string, daysInterval = 7) => {
		const storageKey = `lastToastShown_${message}`; // Unique key for each message
		const lastShown = localStorage.getItem(storageKey);
		const now = new Date().getTime();
		
		// Check if enough time has passed since last shown
		if (!lastShown || (now - parseInt(lastShown)) > (daysInterval * 24 * 60 * 60 * 1000)) {
		  presentToast({
			message: i18n.t(message),
			duration: 10000,
			position: "top",
			color: "primary",
			buttons: [
				{
				  text: '✕',
				  role: 'cancel',
				  handler: () => {
					// Optional: You could update the timestamp here if you want closing
					// to reset the interval timer
					localStorage.setItem(storageKey, now.toString());
				  }
				}
			  ],
		  });
		  
		  // Update the last shown timestamp
		  localStorage.setItem(storageKey, now.toString());
		  return true; // Toast was shown
		}
		
		return false; // Toast was not shown (too soon)
	  };


	// Use useState and useEffect to handle the async function
	const [statusBarHeight, setStatusBarHeight] = useState("");

	useEffect(() => {
	const getHeight = async () => {
		const height = await checkStatusBar();
		setStatusBarHeight(height);
	};
	getHeight();
	}, []);

	return (
		<IonPage className="summaryViewerContainer">
			<SelectionPopover
				ref={selectionPopoverRef}
				history={history}
				customImageUrl={null}
				uploadImageClicked={(text: string | null) => {
					// Implement your image upload logic here
					console.log("Upload image for text:", text);
				}}
				deleteCustomImage={(url: string, text: string) => {
					// Implement your delete image logic here
					console.log("Delete image:", url, text);
				}}
				removeDeletedDrawing={(url: string, text: string) => {
					// Implement your remove drawing logic here
					console.log("Remove drawing:", url, text);
				}}
			/>
			<IonHeader className="summaryViewerHeader">
				<IonToolbar className="summaryViewerToolbar" style={{ maxWidth:isPlatform("capacitor")?"100%": ""  }}>
					<IonButtons className="summaryViewerToolbarBtns" slot="start" style={{ marginBottom: checkStatusBar() }}>
						<IonButton
							className="summaryViewerToolbarBtn"
							disabled={!isKaraoke}
							onClick={() => {
								history.goBack();
							}}
						>
							<IonIcon icon={chevronBack} />
						</IonButton>
						<IonButton
							className="summaryViewerToolbarBtn"
							hidden={file_text}
							disabled={!isKaraoke}
							onClick={() => {
								if(!isSpanStyle.active)
									showToastOnce("Seleziona il testo o una parola e poi clicca di nuovo la 'B'")
								setIsSpanStyle((prevState) => ({ ...prevState, style: "bold", active: !prevState.active }));
								setIsJustStyling(true);
								setShowColorPicker(false);
								setIsLetterColoring(false);
							}}
						>
							<IonIcon icon={boldIcon} />
						</IonButton>
						<IonButton
							className="summaryViewerToolbarBtn"
							hidden={file_text}
							disabled={!isKaraoke}
							onClick={() => {
								showToastOnce("Seleziona il testo o una parola e poi clicca di nuovo la 'I'")
								setIsSpanStyle((prevState) => ({ ...prevState, style: "italic", active: true }));
								setIsJustStyling(true);
							}}
						>
							<IonIcon icon={italicIcon} />
						</IonButton>
						{/* <IonButton
							className="summaryViewerToolbarBtn"
							hidden={file_text}
							disabled={!isKaraoke}
							onClick={() => {
								showToastOnce("Seleziona il testo o una parola e poi clicca di nuovo l'icona")
								setIsSpanStyle((prevState) => ({ ...prevState, style: "bullet", active: true }));
								setIsJustStyling(true);
							}}
						>
							<IonIcon icon={playCircle} />
						</IonButton> */}
						<IonButton
							className="summaryViewerToolbarBtn"
							hidden={file_text}
							disabled={!isKaraoke}
							onClick={() => {
								showToastOnce("Seleziona il testo o una parola e poi clicca 'Conferma colore'")
								setShowColorPicker((prevState) => !prevState);
								setIsLetterColoring((prevState) => !prevState);
							}}
						>
							<IonIcon icon={colorFillSharp} />
						</IonButton>
						
						<IonButton
							className="summaryViewerToolbarBtn"
							disabled={getGlobalState("ttsCreating")}
							onClick={() => {
								audioRef.current?.pause();
								resetGlobalState(["ttsCreating","playingWordsIndex"]);
								setIsPlaying(false);
								setGlobalState("karaokeApplied", getGlobalState("karaokeApplied"));
								setIsKaraoke((prevState) => !prevState);
								setIsExpanded((prevState) => !prevState);
								setShowColorPicker(false);
								setIsLetterColoring(false);
								setIsDictionary(true);
								setCurrentWordIndex(currentWordIndex);
								setFirstLoad(false)
							}}
						>
							<IonIcon style={{ color: isKaraoke ? "currentColor" : "green" }} icon={ttsOn} />
						</IonButton>
						<IonButton
							className="summaryViewerToolbarBtn"
							disabled={!isKaraoke}
							onClick={() => {
								showToastOnce("Seleziona il testo o una parola e attendi il caricameto del dizionario e immagini")
								setIsKaraoke(true);
								setIsDictionary((prevState) => !prevState);
								setShowColorPicker(false);
								setIsLetterColoring(false);
							}}
						>
							<IonIcon style={{ color: isDictionary ? "currentColor" : "green" }} icon={dictionaryIcon} />
						</IonButton>
					</IonButtons>
				</IonToolbar>
				{!isKaraoke && (
					<div className="ttsButtons">
						{!getGlobalState("ttsCreating") ? (
							<>
								<div className="controls-wrapper">
									<div className="button-controls">
										<audio ref={audioRef} src={audioSrc} />
										<button className="controlls" disabled={!isPlaying} onClick={backward}>
											<IonIcon icon={backwardBtnIcon}></IonIcon>
										</button>
										<button className="controlls" onClick={playPause}>
											<IonIcon icon={isPlaying ? playBtnIcon : pauseBtnIcon}></IonIcon>
										</button>
										<button
											className="controlls"
											disabled={
												!isPlaying ||
												(getGlobalState("processedChunks").length - 1 <= getGlobalState("currentChunkIndexPlaying") && audioRef.current!.currentTime > 10)
											}
											onClick={forward}
										>
											<IonIcon icon={forwardBtnIcon}></IonIcon>
										</button>
										<button
											onClick={() => {
												showToastOnce("Clicca sulla parola da dove vuoi partire")
												setCustomFromBtn(true)
												handleStartingPoint();
											}}
											className="starting-point-btn"
										>
											<span className="text"><Trans>PUNTO DI PARTENZA</Trans></span>
											<IonIcon className="icon" icon={startingPoint}></IonIcon>
										</button>
									</div>
									<div className="select-controls">
										<IonSelect
											interface="popover"
											value={userSettings!.speech_rate ?? 1}
											onIonChange={(e: CustomEvent<SelectChangeEventDetail<number>>) => handleSpeedChange(e.detail.value)}
											className="speed-select"
										>
											{speeds.map((speed) => (
												<IonSelectOption key={speed.value} value={speed.value}>
													{speed.label}
												</IonSelectOption>
											))}
										</IonSelect>

										<IonSelect
											interface="popover"
											value={
												userSettings!.speech_gender?.toLowerCase() === "female"
													? "F"
													: userSettings!.speech_gender?.toLowerCase() === "male"
													? "M"
													: userSettings!.speech_gender ?? "F"
											}
											className="quality-select"
											onIonChange={(e: CustomEvent<SelectChangeEventDetail<string>>) => handeleVoiceTypeChange(e.detail.value)}
										>
											{genders.map((gender) => (
												<IonSelectOption key={gender.value} value={gender.value}>
													{gender.label}
												</IonSelectOption>
											))}
										</IonSelect>
									</div>
								</div>
							</>
						) : (
							"TTS is Loading" /* to be localized*/
						)}
					</div>
				)}
				<ClickPopover
					ref={clickPopoverRef}
					isOpen={isMobile && dictionaryIsOpen}
					keyWordStatus={false}
					documentElement={null}
					textContent={mobileDictionaryText}
					userSettings={userSettings}
					history={props.history}
					uploadImageClicked={(text: string | null) => {
						// document.getElementById("viewerImageUpload")?.click();
						// this.setState({ selectedText: text });
					}}
					deleteCustomImage={(url: string, text: string) => {
						// this.deleteCustomImage(url, text);
					}}
					removeDeletedDrawing={(url, text) => {
						// this.deleteDrawing(url, text);
					}}
					onDidDismiss={() => {
						setDictionaryIsOpen(false);
						// this.setState({
						// 	showClickPopover: false,
						// 	clickedDocumentElement: null,
						// });
					}}
				/>
			</IonHeader>
			<IonContent className="summaryViewerContent" >
				<div className={`summaryViewerContentContainer${isExpanded ? "-expanded" : ""}`} style={{marginTop: statusBarHeight}} ref={editorRef} onInput={handleContentChange} />
			</IonContent>
			{showColorPicker && <ColorPicker onColorConfirm={handleColorConfirm} activeElement={activeElement}			/>}
		</IonPage>
	);
};

export default SummaryViewer;
